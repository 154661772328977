import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeCompanyProp, getCompanySuccess } from  '../store/company/actions';
import TlgrmSettingsForm from './TlgrmSettingsForm/TlgrmSettingsForm';
import {getCompanyInfoRequest} from '../api';
import {Dispatch} from 'redux';
import * as Types from '../Interfaces';


interface IProps extends IStateProps, IDispatchProps{
}

class ShowTlgrmSettings extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
  }

  componentDidMount =()=>{
    getCompanyInfoRequest(this.props.user.base)
            .then((result) =>{
              this.props.onGetCompanySuccess(result);
            })
  }

  onChange = (e) => {
    let field = [];
    field[e.target.name] = e.target.value;
    this.props.onChangeCompanyProp(field);
  }

  onCheckboxChange = (e) => {
    let field = [];
    field[e.target.name] = e.target.checked;
    this.props.onChangeCompanyProp(field);
  }
 
  render() {
    return (
              <div className="tableWrapper">
                     <TlgrmSettingsForm companyData={ this.props.company } onCheckboxChange={ this.onCheckboxChange }
                      onChange={ this.onChange } 
                     
                      />  
                       
              </div>
      );
  }
};

interface IStateProps {
  // tlgrmData: Types.TlgrmData;
  company: Types.Company;
  user: Types.User;
}

const mapStateToProps = (state:any): IStateProps => {
  return {
    company: state.company,
    user: state.auth.user,
  }
};


interface IDispatchProps {
  onChangeCompanyProp: typeof changeCompanyProp;
  onGetCompanySuccess: typeof getCompanySuccess;
}
const mapDispatchToProps = (dispatch: Dispatch):IDispatchProps => {
  return {
    onChangeCompanyProp: field => dispatch(changeCompanyProp(field)),
    onGetCompanySuccess: items => dispatch(getCompanySuccess(items))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowTlgrmSettings);