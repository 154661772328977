import * as Types from '../../Interfaces';

export const FOLDERS_SUCCESS = 'FOLDERS_SUCCESS';
export const SELECT_FOLDER = 'SELECT_FOLDER';
export const DELETE_SELECTED_FOLDER = 'DELETE_SELECTED_FOLDER';
export const DESELECT_FOLDER = 'DESELECT_FOLDER';

export interface IGetFoldersAction {
    type: typeof FOLDERS_SUCCESS,
    payload: Types.File[];
}

export interface ISelectFolderAction {
    type: typeof SELECT_FOLDER,
    payload: Types.File
}

export interface IDeselectFolderAction {
    type: typeof DESELECT_FOLDER
}

export interface IDeleteSelectedFolderAction {
    type: typeof DELETE_SELECTED_FOLDER,
}

export type FoldersActionsTypes =
    | IGetFoldersAction
    | ISelectFolderAction
    | IDeselectFolderAction
    | IDeleteSelectedFolderAction

export type IFoldersState = Types.File[];