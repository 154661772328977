import * as Types from '../../Interfaces';

export const GROUP_LIST_SUCCESS = 'GROUP_LIST_SUCCESS';
export const ORDER_UP_GROUP = 'ORDER_UP_GROUP';
export const ORDER_DN_GROUP = 'ORDER_DN_GROUP';
export const CHANGE_NEW_GROUP_LINK_PROP = 'CHANGE_NEW_GROUP_LINK_PROP';
export const CHANGE_SELECTED_GROUP_LINK_PROP = 'CHANGE_SELECTED_GROUP_LINK_PROP';
export const CHANGE_SELECTED_GROUP_PROP = 'CHANGE_SELECTED_GROUP_PROP';
export const CHANGE_NEW_GROUP_PROP = 'CHANGE_NEW_GROUP_PROP';
export const UPDATE_GROUPS_BEFORE_SAVE = 'UPDATE_GROUPS_BEFORE_SAVE';
export const INSERT_GROUP_BEFORE_SAVE = 'INSERT_GROUP_BEFORE_SAVE';
export const DELETE_GROUP = 'DELETE_GROUP';

export interface IGetGroupListAction {
    type: typeof GROUP_LIST_SUCCESS,
    payload: Array<Types.Group>
}

export interface IOrderUpGroupAction {
    type: typeof ORDER_UP_GROUP,
    payload: number
}

export interface IOrderDnGroupAction {
    type: typeof ORDER_DN_GROUP,
    payload: number
}

export interface IChangeNewGroupLinkPropAction {
    type: typeof CHANGE_NEW_GROUP_LINK_PROP,
    payload: { newGroup: Types.Group, link: string }
}

export interface IChangeSelectedGroupLinkPropAction {
    type: typeof CHANGE_SELECTED_GROUP_LINK_PROP,
    payload: { selectedGroup: Types.Group, link: string }
}

export interface IChangeSelectedGroupPropAction {
    type: typeof CHANGE_SELECTED_GROUP_PROP,
    payload: { selectedGroup: Types.Group, prop: any }
}

export interface IChangeNewGroupPropAction {
    type: typeof CHANGE_NEW_GROUP_PROP,
    payload: { newGroup: Types.Group, prop: any }
}

export interface IUpdateGroupsBeforeSaveAction {
    type: typeof UPDATE_GROUPS_BEFORE_SAVE,
    payload: Types.Group
}

export interface IInsertGroupsBeforeSaveAction {
    type: typeof INSERT_GROUP_BEFORE_SAVE,
    payload: Types.Group
}

export interface IDeleteGroupAction {
    type: typeof DELETE_GROUP,
    payload: Types.Group
}

export type GroupsActionsTypes =
    | IGetGroupListAction
    | IOrderUpGroupAction
    | IOrderDnGroupAction
    | IChangeNewGroupLinkPropAction
    | IChangeSelectedGroupLinkPropAction
    | IChangeSelectedGroupPropAction
    | IChangeNewGroupPropAction
    | IUpdateGroupsBeforeSaveAction
    | IInsertGroupsBeforeSaveAction
    | IDeleteGroupAction

export type IGroupsState = Types.Group[]


