import React from 'react';
import { Link } from 'react-router-dom';

const UserAgreement = () => {
  return (
    <div>
      <div>
        <div className="agreement">
          <h1>Пользовательское соглашение</h1>
          <p>Настоящее пользовательское соглашение (далее - «Соглашение») регулирует порядок работы настоящего сайта (далее - «Сайт»), определяет условия использования
            материалов и сервисов Сайта. Администратор Сайта указывает информацию о себе, а также, контактные данные для обратной связи на Сайте. Настоящее Соглашение
            является публичной офертой в соответствии с действующим законодательством Российской Федерации. Совершение конклюдентных действий физическими, либо юридическими
            лицами (далее - «Пользователь»), направленных на использование Сайта считается безусловным принятием (акцептом) данного Соглашения. Настоящим Пользователь
            подтверждает, что акцепт Соглашения равносилен подписанию и заключению Соглашения на условиях, изложенных в настоящем Соглашении.</p>
          <ol>
            <h3>Условия использования сайта </h3>
            <li>Использование любых материалов и сервисов Сайта регулируется нормами действующего законодательства Российской Федерации. Материалы и сервисы Сайта предназначены
              исключительно для использования в законных целях.</li>
            <li>Пользователь вправе по своему усмотрению знакомиться с материалами сайта и сервисами Сайта, заказывать и приобретать товары и/или услуги предлагаемые на
              Сайте.</li>
            <li>Пользователь соглашается использовать Сайт, не нарушая имущественных и/или неимущественных прав третьих лиц, а равно запретов и ограничений, установле­­­­­­­­­­нных
              применимым правом, включая без ограничения: авторские и смежные права, права на товарные знаки, знаки обслуживания и наименования мест происхождения товаров,
              права на промышленные образцы, права использовать изображения людей, а также любых действий, которые приводят или могут привести к нарушению нормальной
              работы Сайта и сервисов Сайта, в частности используя программы для вмешательства или попытки вмешательства в процесс нормального функционирования Сайта.</li>
            <li>Использование материалов Сайта без согласия правообладателя не допускается. Для правомерного использования материалов Сайта необходимо согласие Администратора
              сайта или правообладателя материалов.</li>
            <li>При наличии технической возможности Пользователь вправе оставлять комментарии и иные записи на Сайте. Пользователь гарантирует, что комментарии или иные
              записи не нарушают применимого законодательства, не содержат материалов незаконного, непристойного, клеветнического, дискредитирующего, угрожающего, порнографического,
              враждебного характера, а также содержащих домогательства и признаки расовой или этнической дискриминации, призывающих к совершению действий, которые могут
              быть квалифицированы как уголовные преступления, равно как и считаться недопустимыми по иным причинам, материалов, пропагандирующих культ насилия и жестокости,
              материалов, содержащих нецензурную брань. Администрация Сайта не обязуется проводить предварительную модерацию материалов и записей на Сайте Пользователями.
              За комментариии записи на Сайте оставленные Пользователем, Пользователь несет ответственность самостоятельно и в полном объеме.</li>
            <li>Администратор Сайта не несет ответственности за посещение и использование Пользователем внешних ресурсов, ссылки на которые могут содержаться на Сайте, Пользователь
              переходит по ссылкам содержащимся на Сайте на внешние ресурсы, по своему усмотрению на свой страх и риск.</li>
            <li>Пользователь согласен с тем, что Администрация Сайта не несет ответственности перед Пользователем в связи с любыми возможными или возникшими потерями или
              убытками, связанными с любым содержанием Сайта, товарами или услугами, доступными на Сайте или полученными через внешние сайты или ресурсы, либо иные контакты
              Пользователя, в которые он вступил, используя размещенную на Сайте информацию или ссылки навнешние ресурсы.</li>
            <li>Предоставление в любой форме (регистрация на Сайте, осуществление заказов, подписка на рекламныерассылки итд.) своих персональных данных Администрации сайта,
              Пользователь выражает согласие на обработку персональных данных Администрацией сайта в соответствии с Федеральным законом “О персональных данных” от 27.07.2006№152-ФЗ.
              Обработка персональных данных Пользователя регулируется действующим законодательством Российской Федерации и Политикой конфиденциальности Сайта.</li>
            <li>Администратор Сайта вправе в любое время в одностороннем порядке, без каких либо уведомлений Пользователя, изменять содержимое Сайта, а также изменять условия
              настоящего Соглашения. Такие изменения вступают в силу с момента размещения новой версии Соглашения на данной странице. Актуальная версия Соглашения всегда
              расположена на данной странице. Для избежания споров по поводу изменения Соглашения Администратор Сайта рекомендует периодически ознакамливаться с содержимым
              Соглашения расположенного на данной странице. При несогласии Пользователя с внесенными изменениями он обязан отказаться от использования Сайта.</li>
            <li>Администратор Сайта вправе в одностороннем порядке ограничивать доступ Пользователя на Сайт, если будет обоснованно считать, что Пользователь ведет неправомерную
              деятельность. Кроме того, администратор вправе удалять материалы Пользователей по требованию уполномоченных органов государственной власти или заинтересованных
              лиц в случае, если данные материалы нарушают применимое законодательство или права третьих лиц.</li>
            <li>Администратор Сайта и Пользователь несут ответственность за неисполнение или ненадлежащее исполнение своих обязательств в соответствии с Соглашением и действующим
              законодательством Российской Федерации. Администратор Сайта не несет ответственность за технические перебои в работе Сайта, вместе с тем Администратор
              Сайта обязуется принимать все разумные меры для предотвращения таких перебоев.</li>
            <li>Все возможные споры, вытекающие из настоящего Соглашения или связанные с ним, подлежат разрешению в соответствии с действующим законодательством Российской
              Федерации.</li>
            <li>Все данные, размещенные или размещаемые на настоящем Сайте, находятся на оборудовании находящемся на территории Российской Федерации.</li>
            <li>Администратор вправе прекращать работу Сайта, а равно частично или полностью прекращать доступ к сайту до завершения необходимого технического обслуживания
              и (или) модернизации Сайта.</li>
            <li>Совершая действия по принятию настоящего Соглашения (оферты), Пользователь гарантирует, что ознакомлен, соглашается, полностью и безоговорочно принимает
              все условия Соглашения в целом, обязуется их соблюдать. </li>
          </ol>
        </div>
        <div className="center" style={{marginBottom: '5rem'}}>
          <p><Link to="/register">Назад, к регистрации</Link></p>
          
        </div>
      </div>
    </div>
    );
}


export default UserAgreement;
