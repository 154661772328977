import React from 'react';
import './LoginForm.css'

const LoginForm = ({onSubmitForm}) => {
   const onSubmit = (e) => {
     console.log(e)
        onSubmitForm(e);
    }
    return (
        <div className="loginWrapper">
          <div className="login">
            <div className="heading">
              <h2>Администрирование</h2>
              <form onSubmit={ onSubmit }>
                <div className="input-group input-group-lg">
                  <span className="input-group-addon"><i className="fa fa-user"></i></span>
                  <input type="text" className="form-control" placeholder="Логин" name="login" required></input>
                </div>
                <div className="input-group input-group-lg">
                  <span className="input-group-addon"><i className="fa fa-lock"></i></span>
                  <input type="password" className="form-control" placeholder="Пароль" name="password" required></input>
                </div>
                <div className="input-group input-group-lg">
                  <button type="submit" className="float" value="submit-true"><div className="insider">Войти</div></button>
                </div>
              </form>
            </div>
          </div>
        </div>
    )
};

export default LoginForm;