import * as UnionTypes from './types';
import { Union } from '../../Interfaces';

const initialState: UnionTypes.INewUnionState = {}

export function newUnionReducer(state = initialState, action: UnionTypes.NewUnionActionsTypes): UnionTypes.INewUnionState {
  switch (action.type) {

    case UnionTypes.DROP_NEW_UNION:
      return initialState;

    case UnionTypes.ADD_UNION:
      let newU = Object.assign({}, state);
      newU.parent = Number(action.payload.parent);
      newU.index = Number(action.payload.index);
      newU.title = 'Новый пункт меню';
      newU.subtitle = '';
      newU.file = '';
      newU.link = '';
      newU.image = '';
      newU.duration = 2;
      newU.order = Number(action.payload.order);
      return newU;

    case UnionTypes.CHANGE_NEW_UNION_PROP:
       // сначала сливаем группу и редактируемое свойство в единый объект
       let changed: Union = {
        ...action.payload.newUnion,
        ...action.payload.prop
      }

      // затем возвращаем новый стейт, содержащий данную группу
      return changed;


    case UnionTypes.CHANGE_NEW_UNION_LINK_PROP:
       // сначала сливаем группу и редактируемое свойство в единый объект
       let changedSelectedUnionWithLinkProp: Union = {...action.payload.newUnion, link: action.payload.link}
       // затем возвращаем новый стейт, содержащий данную группу
       return changedSelectedUnionWithLinkProp;

    default:
      return state;

  }
}