// import Action from 'redux';

import * as Types from '../../Interfaces';

export const UNION_LIST_SUCCESS = 'UNION_LIST_SUCCESS';
export const ORDER_UP_UNION = 'ORDER_UP_UNION';
export const ORDER_DN_UNION = 'ORDER_DN_UNION';
export const CHANGE_NEW_UNION_LINK_PROP = 'CHANGE_NEW_UNION_LINK_PROP';
export const CHANGE_SELECTED_UNION_LINK_PROP = 'CHANGE_SELECTED_UNION_LINK_PROP';
export const CHANGE_SELECTED_UNION_PROP = 'CHANGE_SELECTED_UNION_PROP';
export const CHANGE_NEW_UNION_PROP = 'CHANGE_NEW_UNION_PROP';
export const UPDATE_UNIONS_BEFORE_SAVE = 'UPDATE_UNIONS_BEFORE_SAVE';
export const INSERT_UNION_BEFORE_SAVE = 'INSERT_UNION_BEFORE_SAVE';
export const DELETE_UNION = 'DELETE_UNION';
export const DELETE_ALL_GROUP_UNIONS = 'DELETE_ALL_GROUP_UNIONS';



export interface IGetUnionListAction {
    type: typeof UNION_LIST_SUCCESS,
    payload: Array<Types.Union>
}

export interface IOrderUpUnionAction {
    type: typeof ORDER_UP_UNION,
    payload: number
}

export interface IOrderDnUnionAction {
    type: typeof ORDER_DN_UNION,
    payload: number
}

export interface IChangeNewUnionLinkPropAction {
    type: typeof CHANGE_NEW_UNION_LINK_PROP,
    payload: { newUnion: Types.Union, link: string }
}

export interface IChangeSelectedUnionLinkPropAction {
    type: typeof CHANGE_SELECTED_UNION_LINK_PROP,
    payload: { selectedUnion: Types.Union, link: string }
}

export interface IChangeSelectedUnionPropAction {
    type: typeof CHANGE_SELECTED_UNION_PROP,
    payload: { selectedUnion: Types.Union, prop: any }
}

export interface IChangeNewUnionPropAction {
    type: typeof CHANGE_NEW_UNION_PROP,
    payload: { newUnion: Types.Union, prop: any }
}

export interface IUpdateUnionsBeforeSaveAction {
    type: typeof UPDATE_UNIONS_BEFORE_SAVE,
    payload: Types.Union
}

export interface IInsertUnionBeforeSaveAction {
    type: typeof INSERT_UNION_BEFORE_SAVE,
    payload: Types.Union
}

export interface IDeleteUnionAction {
    type: typeof DELETE_UNION,
    payload: Types.Union
}

export interface IDeleteAllGroupUnionsAction {
    type: typeof DELETE_ALL_GROUP_UNIONS,
    payload: number;
}

export type UnionsActionsTypes =
    | IGetUnionListAction
    | IOrderUpUnionAction
    | IOrderDnUnionAction
    | IChangeNewUnionLinkPropAction
    | IChangeSelectedUnionLinkPropAction
    | IChangeSelectedUnionPropAction
    | IChangeNewUnionPropAction
    | IUpdateUnionsBeforeSaveAction
    | IInsertUnionBeforeSaveAction
    | IDeleteUnionAction
    | IDeleteAllGroupUnionsAction

export type IUnionsState = Types.Union[]
