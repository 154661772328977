// import Action from 'redux';

import {SettingsForm} from '../../Interfaces';

export const SETTINGS_SUCCESS = 'SETTINGS_SUCCESS';
export const CHANGE_SETTINGS_PROP = 'CHANGE_SETTINGS_PROP';

export interface IGetSettingsAction {
    type: typeof SETTINGS_SUCCESS,
    payload:  SettingsForm
}

export interface IChangeSettingsPropAction {
    type: typeof CHANGE_SETTINGS_PROP,
    payload: any 
}

export type SettingsActionsTypes =
    | IGetSettingsAction
    | IChangeSettingsPropAction

export type ISettingsState  = SettingsForm
