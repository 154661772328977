import {
    Company,
    User,
    File,
    Union,
    Group,
    SettingsForm,
    FolderType,
    DeleteFetchResult,
    UploadFetchResult,
    NewFolderFetchResult,
    SaveDataFetchResult,
    EmailConfirmationFetchResult
} from './Interfaces';

const jwt = require('jsonwebtoken');
// const bcrypt = require('bcryptjs'); const serverAddr =
// 'https://contentboard.site'; //для запуска на production-сервере
// непосредственно  const serverAddr = 'http://localhost:9000'; //для запуска
// локально и подключения а API dev-сервера
const serverAddr = process.env.REACT_APP_API_URI; // для локальной разработки (API сервер запущен локально в докере)
// REACT_APP_API_URI=http://localhost:9000 npm start - для локальной разработки
// если переменная не поднимается автоматически const basePrefix = 'vimpel';
// изменяется в зависимости от того кто залогинился. Панельное ПО отгружается с
// прописанным префиксом.
const apiVer = '/api/v1';

/**
 * @return {getCompanyInfo}
 */
export function getCompanyInfoRequest(basePrefix : string) {
    console.log('Console: getCompanyInfo loading..' + basePrefix)
    const uri = serverAddr + apiVer + '/company/' + basePrefix;
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('authorization', `${localStorage.getItem('token')}`);
    const reqConfig = {
        method: 'GET',
        headers: myHeaders
    };
    return fetch(uri, reqConfig).then(response => {
        // console.log('getCompanyInfoRequest: ', response)
        return response.json()
    }).then((json : Company) => {
        // console.log('Company: ', json)
        return json
    }).catch(err => {
        console.error('Ошибка:', err);
    });
};

/**
 * @return result
 */
export function sendTlgrmInfo(tlgrmEnabled, tlgrmToken : string, tlgrmChannel : string, text : string) {
    if (!tlgrmEnabled) 
        return;
    console.log('Console: sending tlgrm text..')
    let reqConfig = {
        method: 'POST'
    };
    return fetch(`https://api.telegram.org/bot${tlgrmToken}/sendMessage?chat_id=${tlgrmChannel}&parse_mode=Markdown&text=${text}`, reqConfig).then((response) => {
        return response.json()
    }).then((json) => {
        return json;
    }).then((result) => {
        return result;
    }).catch(error => {
        console.log('Error loading tlgrm', error)
    })
};

/**
 * @return {result}
 */
export function postFile(formData, folder?: string, basePrefix?: string) : Promise < UploadFetchResult > {
    console.log(folder)
    let addr;
    if (!folder || folder === 'undefined') 
        addr = serverAddr + apiVer + '/files/uploadpdf/' + basePrefix;
    else 
        addr = serverAddr + apiVer + '/files/uploadpdf/' + folder + '/' + basePrefix
    console.log(addr)
    const myHeaders = new Headers();
    myHeaders.append('authorization', `${localStorage.getItem('token')}`);
    let reqConfig = {
        method: 'POST',
        headers: myHeaders,
        body: formData
    };
    return fetch(addr, reqConfig).then((response) => {
        console.log(response)
        if (!response.ok) {
            // if error in response we want to dispath it in state
            return Promise.reject(response);
        }
        // response ok
        return response.json()
    }).catch((error) => {
        console.log(error);
    });
};

/**
 * @return {result}
 */
export function postImage(formData, folder : string, basePrefix : string) : Promise < UploadFetchResult > {
    // console.log(folder)
    let addr;
    if (!folder || folder === 'undefined') 
        addr = serverAddr + apiVer + '/images/uploadimage/' + basePrefix;
    else 
        addr = serverAddr + apiVer + '/images/uploadimage/' + folder + '/' + basePrefix
    console.log(addr)
    const myHeaders = new Headers();
    myHeaders.append('authorization', `${localStorage.getItem('token')}`);
    let reqConfig = {
        method: 'POST',
        headers: myHeaders,
        body: formData
    };
    return fetch(addr, reqConfig).then((response) => {
        console.log(response)
        if (!response.ok) {
            // if error in response we want to dispath it in state
            return Promise.reject(response);
        }
        // response ok
        return response.json()
    }).catch((error) => {
        console.log(error);
    });
};

/**
 * @return {result}
 */
export async function postBgImage(formData : any, basePrefix : string) : Promise < UploadFetchResult > {

    const addr = serverAddr + apiVer + '/images/uploadbgimage/' + basePrefix;
    const myHeaders = new Headers();
    myHeaders.append('authorization', `${localStorage.getItem('token')}`);

    let reqConfig = {
        method: 'POST',
        headers: myHeaders,
        body: formData
    };

    return fetch(addr, reqConfig).then((response) => {
        console.log(response)
        if (!response.ok) {
            // if error in response we want to dispath it in state
            return Promise.reject(response);
        }
        // response ok
        return response.json()
    }).catch((error) => {
        console.log(error);
    });
};

/**
 * @return {settings}
 */
export async function postSettings(settings: SettingsForm, basePrefix : string) : Promise < SaveDataFetchResult > {
    let json = JSON.stringify(settings, null, 2);
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('authorization', `${localStorage.getItem('token')}`);
    let reqConfig = {
        method: 'POST',
        headers: myHeaders,
        body: json
    };
    try {
        let response = await fetch(serverAddr + apiVer + '/settings/' + basePrefix, reqConfig);
        if (!response.ok) {
            // if error in response we want to dispath it in state
            return Promise.reject(response)
        }
        // response ok
        return await response.json();
    } catch (err) {
        return Promise.reject({
            error: 'Ошибка сети: ' + err
        })
    }
};

/**
 * @return {company}
 */
export async function postCompany(company : Company, basePrefix : string) : Promise < SaveDataFetchResult > {
    let json = JSON.stringify(company, null, 2);
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('authorization', `${localStorage.getItem('token')}`);
    let reqConfig = {
        method: 'PUT',
        headers: myHeaders,
        body: json
    };
    try {
        let response = await fetch(serverAddr + apiVer + '/company/' + basePrefix, reqConfig);
        if (!response.ok) {
            // if error in response we want to dispath it in state
            return Promise.reject(response)
        }
        // response ok
        return await response.json();
    } catch (err) {
        return Promise.reject({
            error: 'Ошибка сети: ' + err
        })
    }
};
/**
 * @return {groups}
 */
export async function postGroups(groups : Group[], basePrefix : string) : Promise < SaveDataFetchResult > {
    let json = JSON.stringify(groups, null, 2);
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('authorization', `${localStorage.getItem('token')}`);
    let reqConfig = {
        method: 'POST',
        headers: myHeaders,
        body: json
    };
    try {
        let response = await fetch(serverAddr + apiVer + '/groups/' + basePrefix, reqConfig);
        if (!response.ok) {
            // if error in response we want to dispath it in state
            return Promise.reject(response)
        }
        // response ok
        return await response.json();
    } catch (err) {
        return Promise.reject({
            error: 'Ошибка сети: ' + err
        })
    }
};
/**
 * @return {unions}
 */
export async function postUnions(unions : Union[], basePrefix : string) : Promise < SaveDataFetchResult > {
    let json = JSON.stringify(unions, null, 2);
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('authorization', `${localStorage.getItem('token')}`);
    let reqConfig = {
        method: 'POST',
        headers: myHeaders,
        body: json
    };
    try {
        let response = await fetch(serverAddr + apiVer + '/unions/' + basePrefix, reqConfig);

        if (!response.ok) {
            // if error in response we want to dispath it in state
            return Promise.reject(response)
        }
        // response ok
        return await response.json();
    } catch (err) {
        return Promise.reject({
            error: 'Ошибка сети: ' + err
        })
    }

};
/**
 * @return {settings}
 */
export function fetchSettings(basePrefix : string) {
    console.log('Console: settings loading..')
    return fetch(serverAddr + apiVer + '/settings/' + basePrefix).then((response) => {
        return response.json()
    }).then((json) => {
        return json.settings;
    }).then((result) => {
        return result;
    }).catch(error => {
        console.log('Error loading settings', error)
    })
};

/**
 * @return {invites}
 */
export function fetchInvites() {
    console.log('Console: invites loading..')
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('authorization', `${localStorage.getItem('token')}`);
    let reqConfig = {
        method: 'GET',
        headers: myHeaders
    };

    return fetch(serverAddr + apiVer + '/invites/', reqConfig).then((response) => {
        console.log(response)
        return response.json()
    }).then((json) => {
        return json;
    }).catch(error => {
        console.log('Error loading invites', error)
    })
};

/**
 * @return {accounts}
 */
export function fetchAccounts() {
    console.log('Console: accounts loading..')
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('authorization', `${localStorage.getItem('token')}`);
    let reqConfig = {
        method: 'GET',
        headers: myHeaders
    };

    return fetch(serverAddr + apiVer + '/accounts/', reqConfig).then((response) => {
        return response.json()
    }).then((json) => {
        return json;
    }).catch(error => {
        console.log('Error loading invites', error)
    })
};

/**
 * @return {groups}
 */
export function fetchGroups(basePrefix) {
    console.log('Console: groups loading..')
    return fetch(serverAddr + apiVer + '/groups/' + basePrefix).then((response) => {
        return response.json()
    }).then((json) => {
        return json.groups;
    }).then((result) => {
        return result;
    }).catch(error => {
        console.log('Error loading groups', error)
    })
};

/**
 * @return {unions}
 */
export function fetchUnions(basePrefix) {
    console.log('Console: unions loading..')
    return fetch(serverAddr + apiVer + '/unions/' + basePrefix).then((response) => {
        return response.json()
    }).then((json) => {
        return json.unions;
    }).then((result) => {
        return result;
    }).catch(error => {
        console.log('Error loading unions', error)
    })
};

/**
 * @return {files}
 */
export function fetchFiles(folder : string, basePrefix : string) {
    console.log('Console: file names loading... folder is ', folder)
    let addr : string;
    if (!folder || folder === 'undefined') 
        addr = `${serverAddr}${apiVer}/files/nofolder/${basePrefix}`
    else 
        addr = `${serverAddr}${apiVer}/files/${folder}/${basePrefix}`

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('authorization', `${localStorage.getItem('token')}`);
    const reqConfig = {
        method: 'GET',
        headers: myHeaders
    };
    return fetch(addr, reqConfig).then(response => {
        return response.json()
    })
        .then(data => {
        return data.docs
    })
        .catch(err => {
            if (err) {
                console.log(err);
                return Error(err)
            }
        });
};

/**
 * @return {folders}
 */
export function fetchFoldersFor(folderType, basePrefix) {
    console.log(`Console: folders loading for ${folderType} ...`)
    let addr = serverAddr + apiVer + `/files/foldersfor/${folderType}/` + basePrefix;
    // addr = htts://contentboard.site/api/v1/foldersfor/images/vimpel
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('authorization', `${localStorage.getItem('token')}`);
    const reqConfig = {
        method: 'GET',
        headers: myHeaders
    };
    return fetch(addr, reqConfig).then(response => {
        console.log('response', response)
        return response.json()
    }).then(data => {
        console.log('data: ', data)
        return data.docs
    }).catch(err => {
        console.log(err);
        throw(err)
    });
};

/**
 * @return {images names}
 */
export function fetchImages(folder : string, basePrefix : string) {
    console.log('Console: images loading for folder:', folder)
    let addr : string;
    if (!folder || folder === 'undefined') 
        addr = `${serverAddr}${apiVer}/images/nofolder/${basePrefix}`;
    else 
        addr = `${serverAddr}${apiVer}/images/${folder}/${basePrefix}`

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('authorization', `${localStorage.getItem('token')}`);
    const reqConfig = {
        method: 'GET',
        headers: myHeaders
    };
    return fetch(addr, reqConfig).then(response => {
        return response.json()
    })
        .then(data => {
        return data.docs
    })
        .catch(err => {
            console.log(err);
        });
};

/**
 * @return {getfilenames}
 */
export function fetchBgImages(basePrefix) {
    console.log('Console: bgImages loading..')
    const uri = serverAddr + apiVer + '/bgimages/' + basePrefix;
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('authorization', `${localStorage.getItem('token')}`);
    const reqConfig = {
        method: 'GET',
        headers: myHeaders
    };
    return fetch(uri, reqConfig).then(response => {
        return response.json()
    })
        .then(data => {
        return data.docs
    })
        .catch(resp => {
            console.error(resp);
        });
};

export function calculatePDFWidth(width) {
    switch (width) {
        case 2560: //2k
            return width - 420;

        case 1920: //fhd
            return width - 220

        case 1280: //hd 5:4
            return width - 150

        case 1366:
            return width - 150

        case 400:
            return width - 20

        case 360:
            return width - 10

        default:
            return width - 220
    }
}

/**
 * @param {string} message
 */
export function log(message) {
    console.log('Console: ' + message);
}

export function isNumber(n) {
    return !isNaN(+ n) && isFinite(n);
}

const secretHash = process.env.SECRET;

/**
 * Функция проверяет токен, переданный в каждом клиентском запросе
 * Если токен выпущен издателем и не просрочен
 * @param {string} token
 */
export function checkToken_old(token) {
    if (token !== undefined) {
        // нужно вернуть true or false
        return (jwt.verify(token, secretHash, (err, decoded) => {
            if (err) {
                if (err.name === 'JsonWebTokenError') 
                    return false
                else {
                    // если ошибка не знакомая
                    console.log(err);
                    return false;
                }
            }
            // if no err - result = true, but first we must chek expiration
            let now = Math.floor(Date.now() / 1000);
            if ((now - decoded.iat) > decoded.expiresIn) {
                //token expired
                return false;
            }
            return true;
        })) // end verify
    }
    // if there is no token
    return false;
};

/**
 * Функция проверяет имеющийся токен
 * @param {string} token
 */

export function checkToken(token) {
    if (token !== undefined && token !== '') {
        // нужно вернуть true or false
        const decoded = jwt.decode(token);

        var current_time = Math.round(+ new Date() / 1000);
        if (decoded && decoded.exp > current_time) {
            return true
        }
        // token invalid or exp time has expired
        return false;
    }
    // if there is no token
    return false;
};

export function getUserInfo() : User {
    const token = localStorage.getItem('token');
    return jwt
        .decode(token)
        .user || null;
}

export function sendAuthRequest(creds) {
    let reqConfig = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        // cache: 'no-cache', credentials: 'same-origin',
        body: `email=${creds.email}&password=${creds.password}`
    };

    return fetch(serverAddr + apiVer + '/authentication', reqConfig).then(response => {
        if (!response.ok) {
            // if error in response we want to dispath it in state
            return Promise.reject(response);
        }
        // response ok
        return response.json();
    }).catch((error) => {
        console.log('Request failed with error: ', error);
    });
}

export function sendRegisterRequest(creds) {
    let reqConfig = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: `email=${creds.email}&password=${creds.password}&invite=${creds.invite}`
    };

    // console.log(serverAddr + apiVer + '/registration'); console.log(creds)
    return fetch(serverAddr + apiVer + '/registration', reqConfig).then(response => {
        if (!response.ok) {
            // if error in response we want to dispath it in state
            throw new Error('some err');
        }
        // response ok
        return response.json();
    }).catch((error) => {
        console.log('Request failed with error: ', error);
    });
}

export function sendDeleteAccountRequest(_id) {
    let json = JSON.stringify({
        _id: _id
    }, null, 2)
    const myHeaders = new Headers();

    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('authorization', `${localStorage.getItem('token')}`);
    let reqConfig = {
        method: 'DELETE',
        headers: myHeaders,
        body: json
    };

    // console.log(serverAddr + apiVer + '/accounts');
    return fetch(serverAddr + apiVer + '/accounts', reqConfig).then(response => {
        if (!response.ok) {
            if (response.status === 403) 
                window.alert('Отказ доступа')
            return response.json();
        } else 
            return response.json()
            // window.alert('Аккаунт ' + response.doc._id + 'удален')
        // response ok return response.json();
    })
        .then(json => {
        return json;
    })
        .catch((error) => {
            console.log(error);
        });
}

export async function sendConfirmationRequest(code: string): Promise<EmailConfirmationFetchResult> {
    let json = JSON.stringify({
        code: code
    }, null, 2)
    const myHeaders = new Headers();

    myHeaders.append('Content-Type', 'application/json');
    let reqConfig = {
        method: 'POST',
        headers: myHeaders,
        body: json
    };

    console.log(serverAddr + apiVer + '/confirmation/' + code);
    return fetch(serverAddr + apiVer + '/confirmation', reqConfig).then(response => {
        if (!response.ok) {
            window.alert('Отказ')
            return response.json();
        } else 
            return response.json()
    })
        .then(json => {
        return json;
    })
        .catch((error) => {
            console.log(error);
        });
}

/**
 * Запрос администратора создает новый инвайт
 * @param {string} inviteOwner
 */
export function sendInviteRequest(inviteOwner) {
    let json = JSON.stringify({
        inviteOwner: inviteOwner
    }, null, 2)
    var myHeaders = new Headers();

    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('authorization', `${localStorage.getItem('token')}`);
    let reqConfig = {
        method: 'POST',
        headers: myHeaders,
        body: json
    };

    return fetch(serverAddr + apiVer + '/invites', reqConfig).then(response => {
        if (!response.ok) {
            // if error in response we want to dispath it in state
            return Promise.reject(response);
        }
        // response ok
        return response.json();
    }).catch((error) => {
        console.log('Request failed with error: ', error);
    });
}

export function sendDeleteInviteRequest(_id) {
    let json = JSON.stringify({
        _id: _id
    }, null, 2)
    const myHeaders = new Headers();

    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('authorization', `${localStorage.getItem('token')}`);
    let reqConfig = {
        method: 'DELETE',
        headers: myHeaders,
        body: json
    };

    console.log(serverAddr + apiVer + '/invites');
    return fetch(serverAddr + apiVer + '/invites', reqConfig).then(response => {
        if (!response.ok) {
            if (response.status === 403) 
                window.alert('Отказ доступа')
            return response.json();
        } else 
            return response.json()
            // window.alert('Аккаунт ' + response.doc._id + 'удален')
        // response ok return response.json();
    })
        .then(json => {
        return json;
    })
        .catch((error) => {
            console.log(error);
        });
}

/**
 * Запрос пользователя создает новый каталог
 * @param {string} folder
 */
export function sendNewFolderRequest(folder : string, basePrefix : string) : Promise < NewFolderFetchResult > {
    var myHeaders = new Headers();

    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('authorization', `${localStorage.getItem('token')}`);
    let reqConfig = {
        method: 'POST',
        headers: myHeaders
    };

    // console.log(serverAddr + apiVer + '/invites');
    return fetch(serverAddr + apiVer + '/files/newfolder/' + folder + '/' + basePrefix, reqConfig).then(response => {
        if (!response.ok) {
            // if error in response we want to dispath it in state
            return Promise.reject(response);
        }
        // response ok
        return response.json();
    }).catch((error) => {
        console.log('Request failed with error: ', error);
    });
}

/**
 * Запрос пользователя удаляет файлы
 * @param {string} folder
 */
export async function sendDeleteFilesRequest(folder : string, basePrefix : string, files : Array < File >, folderType : FolderType) : Promise < DeleteFetchResult > {
    const json = JSON.stringify(files);
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('authorization', `${localStorage.getItem('token')}`);

    const reqConfig = {
        method: 'POST',
        headers: myHeaders,
        body: json
    };

    console.log('sendDeleteFilesRequest, file is:', json);
    return fetch(serverAddr + apiVer + '/files/remove/' + folder + '/' + basePrefix + '/' + folderType.toString(), reqConfig).then(response => {
        if (!response.ok) {
            // if error in response we want to dispath it in state
            return Promise.reject(response);
        }
        // response ok
        return response.json();
    }).catch((error) => {
        console.log('Request failed with error: ', error);
    });
}

/**
 * Запрос пользователя удаляет каталог
 * @param {string} folder
 */
export async function sendDeleteFolderRequest(folder : string, basePrefix : string) : Promise < DeleteFetchResult > {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('authorization', `${localStorage.getItem('token')}`);

    const reqConfig = {
        method: 'POST',
        headers: myHeaders
    };

    console.log('sendDeleteFolderRequest: ', folder);
    return fetch(serverAddr + apiVer + '/files/removefolder/' + folder + '/' + basePrefix, reqConfig).then(response => {
        if (!response.ok) {
            // if error in response we want to dispath it in state
            return Promise.reject(response);
        }
        // response ok
        return response.json();
    }).catch((error) => {
        console.log('Request failed with error: ', error);
    });
}

export function removeToken() {
    localStorage.removeItem('token');
}

export function genId() {
    return Math.floor(Math.abs(Math.random() * (100 - 99999999)) + 90);
}

export const convertIdTo = (id, arr) => {
    return arr.filter(i => {
        return i.index === id
    })[0]
}

export const getShortFileName = (fileName) => {
    let short = fileName;
    let part2 = short.slice(-15);
    return short.substring(0, 15) + ' . . . ' + part2;
}

export const getShortFileName2 = (fileName) => {
    let short = fileName;
    return short.substring(0, 37) + '..';
}