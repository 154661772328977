import React from "react";
import './Folder.css';

import { FolderClosedIcon, FolderOpenIcon } from "../SvgIcons/SvgIconsSet";

interface IFolderProps {
	folderName?: string;
    selected: boolean;
    onClick: ()=>void;
}

const renderIcon = (selected: boolean) => {
	switch (selected) {
		case false:
			return <FolderClosedIcon className="folderIcon" />;
		case true:
			return <FolderOpenIcon className="folderIcon" />;
	}
};

const Folder: React.FC<IFolderProps> = ({ folderName, selected, onClick }) => {
	return (
		<div className="folderWrapper" onClick={ onClick }>
			<div className="folder">
                { renderIcon(selected) }
				<span>{folderName}</span>
			</div>
		</div>
	);
};

export default Folder;
