import { ActionCreator  } from 'redux';
import * as CompanyTypes from './types';
import * as Types from '../../Interfaces';

export const getCompanySuccess: ActionCreator<CompanyTypes.IGetCompanyAction> = (items: Types.Company) => {
  return {
    type: CompanyTypes.COMPANY_SUCCESS,
    payload: items
  }
}

export const changeCompanyProp: ActionCreator<CompanyTypes.IChangeCompanyPropAction> =(prop: any) =>{
  return {
    type: CompanyTypes.CHANGE_COMPANY_PROP,
    payload: prop
  }
};
 