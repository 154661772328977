import * as Types from '../../Interfaces';
export const SELECT_UNION = 'SELECT_UNION';
export const CHANGE_SELECTED_UNION_PROP = 'CHANGE_SELECTED_UNION_PROP';
export const CHANGE_SELECTED_UNION_LINK_PROP = 'CHANGE_SELECTED_UNION_LINK_PROP';

export interface ISelectUnionAction {
    type: typeof SELECT_UNION,
    payload:  Types.Union;
} 

export interface IChangeSelectedUnionPropAction {
    type: typeof CHANGE_SELECTED_UNION_PROP,
    payload:  { selectedUnion: Types.Union, prop: any };
} 

export interface IChangeSelectedUnionLinkPropAction {
    type: typeof CHANGE_SELECTED_UNION_LINK_PROP,
    payload: { selectedUnion: Types.Union, link: string }
} 

export type SelectedUnionActionsTypes =
    | ISelectUnionAction
    | IChangeSelectedUnionPropAction
    | IChangeSelectedUnionLinkPropAction

export type ISelectedUnionState = Types.Union

