import React from 'react';
import './Switcher.css'

const Switcher = ({name, checked, onCheckboxChange}) => {
  const onChange = (e) => {
    onCheckboxChange(e);
  }

  return (
    <div className="switcher">
      <input type="checkbox" id={ name } name={ name } checked={ checked } onChange={ onChange } />
      <label className="toggle" htmlFor={ name }>Toggle</label>
    </div>
  )
};

Switcher.propTypes = {
  //props: PropTypes.func.isRequired,

}

export default Switcher