import { IImagesState, ImagesActionsTypes } from './types';
import * as ImageTypes from './types';
import { Image } from '../../Interfaces';

const initialState: IImagesState = []

export function imagesReducer(state = initialState, action: ImagesActionsTypes): IImagesState {
  switch (action.type) {
    case ImageTypes.IMAGES_SUCCESS:
      return [].concat(action.payload)
      .map((item: Image) => ({ fileName: item?.toString(), selected: false })); 

    case ImageTypes.SELECT_IMAGE_FILE:
      const files = state.map( (file: Image) => ({
        ...file,
        selected: file.selected ? (file.fileName === action.payload.fileName ? !file.selected : file.selected)
          :
          file.fileName === action.payload.fileName
      }))
      return Array.from(Object.keys(files), k => files[k]) ;

      case ImageTypes.DELETE_SELECTED_IMAGES:
        const filesToDelete = state.filter((file: Image) => {
          return file.selected === false
        });
        return Array.from(Object.keys(filesToDelete), k => filesToDelete[k]);
  

    default:
      return state;

  }
}