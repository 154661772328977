import React, { Component } from 'react';
import RegisterForm from './RegisterForm/RegisterForm';
import { Fade } from 'react-reveal';
import { sendRegisterRequest } from '../api';

interface IRegisterProps {
  // user: any;
  history: any;
  preventDefault(): void;
  onClick(): void;
}

interface IRegisterState {
  success?: boolean;
  message?: string;
  error?: any;
  needRemember: boolean;
}

class ShowRegister extends Component<IRegisterProps, IRegisterState> {
  constructor(props: IRegisterProps) {
    super(props);
    // localStorage.setItem('token', null);
    this.handleClick = this.handleClick.bind(this);
    this.state={needRemember: false};
  }

  handleRepeatRegister = () =>{
    // e.preventDefault();
    this.setState({error: false, success: false})

    this.props.history.push('/register');
  }

  handleClick = (e: Event) => {
    e.preventDefault();

    const email = e.target[0].value;
    const password = e.target[1].value;
    const invite = e.target[2].value;
    const creds = {
      email: email.trim(),
      password: password.trim(),
      invite: invite.trim()
    };
    sendRegisterRequest(creds)
    .then(json=>{
      console.log('блок then')
        if (json){
            // response ok, but it's not mean that user credential ok and ever user exists
            if (json.success) {
                // if creds is valid set token in local storage
               this.setState({success:true})
            // dispatch(successToken);
            } else
            {
                this.setState({success:false, message: json.message})
                if (json.needRemember)
                    this.setState({needRemember: true})
              }
          }else this.setState({error: true})
    })
     
  }

  render() {
    return (
        <Fade><div className="wrapper">{!this.state.success && !this.state.error && !this.state.message &&
          <RegisterForm onSubmitForm={ this.handleClick } />
        }

        {this.state.error&&
        <div>
          <h2>Ошибка при выполнении запроса</h2>
          <p>Сожалеем, но по какой-то причине произошла ошибка при регистрации нового пользователя</p>
          <p>Просим Вас сообщить об этом вашему менеджеру и повторить процедуру позднее</p>
          <hr/>
          <p onClick={this.handleRepeatRegister}><span>На страницу регистрации</span></p>
        </div>
        }

        {!this.state.success && this.state.needRemember &&
        <div>
          <h2>{this.state.message}</h2>
          <p>Если вы забыли пароль нажмите здесь</p>
          </div>
        }

        
         {this.state.success && !this.state.error &&
          <div>
            <h2>Запрос на регистрацию выполнен успешно</h2>
            <p>На указанную Вами электронную почту выслано письмо с подтверждением регистрации.</p>
            <p>Для завершения активации необходимо перейти по ссылке в письме. Ссылка будет действительна в течении 24х часов.</p>
          </div>
          }

      
        {!this.state.success && !this.state.needRemember && !this.state.error &&
        //rest errors
          <div>
            <h2>{this.state.message}</h2>
          </div>
        }
        
        </div>
        </Fade>
      );
  }
}
 
export default ShowRegister;