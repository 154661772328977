import {IBgImagesState, BgImagesActionsTypes} from './types';
import * as BgImageTypes from './types';
import { BgImage } from '../../Interfaces';

const initialState : BgImageTypes.IBgImagesState = [];

export function bgImagesReducer(state = initialState, action : BgImagesActionsTypes) : IBgImagesState {
  switch(action.type) {
    case BgImageTypes.BG_IMAGES_SUCCESS:
      return [].concat(action.payload)
      .map((item: BgImage) => ({ fileName: item.toString(), selected: false })); 

    case BgImageTypes.SELECT_BGIMAGE_FILE:
      const bgImages = state.map((file: BgImage) => ({
        ...file,
        selected: file.selected
          ? (file.fileName === action.payload.fileName
            ? !file.selected
            : file.selected)
          : file.fileName === action.payload.fileName
      }));
      return Array.from(Object.keys(bgImages), k => bgImages[k])

    default:
      return state;

  }
}