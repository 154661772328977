import React from "react";
import "./DrivePath.css";
import * as Types from "../../../Interfaces";
import { ArrorRightIcon, CloudIcon } from "../SvgIcons/SvgIconsSet";

interface IProps {
	folderType: Types.FolderType;
	selectedFolder?: Types.File;
	onChangeFolderType(e: React.ChangeEvent<HTMLSelectElement>): void;
}

const DrivePath: React.FC<IProps> = ({
	folderType,
	selectedFolder,
	onChangeFolderType
}) => {
	return (
		<div className="drivePath">
			<span className="caption">
				<CloudIcon />
			</span>
			<ArrorRightIcon />
			<select
				className="caption"
				value={folderType}
				onChange={onChangeFolderType}
			>
				<option value="pdf">Документы</option>
				<option value="images">Изображения</option>
				<option value="video">Видео</option>
				<option value="bg">Фоны</option>
			</select>
			<ArrorRightIcon />
			<span className="caption">
				{selectedFolder?.fileName || "Корневая папка"}
			</span>
		</div>
	);
};

export default DrivePath;
