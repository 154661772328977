import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { sendTlgrmInfo, fetchFiles, fetchImages, postImage, fetchUnions, fetchGroups, fetchSettings, removeToken, getUserInfo, postUnions, postGroups, postFile, sendNewFolderRequest, genId, postSettings, postCompany} from './api';
import { fireAction } from './store/lastAction/actions';
import { getSettings } from './store/settings/actions';
import { userSuccessActionA, userLogoutAction } from './store/auth/actions'
import { getGroups, orderUpGroupActionA, orderDnGroupActionA, deleteGroupAction, insertGroupBeforeSaveActionA, changeNewGroupPropActionA, changeSelectedGroupPropActionA, changeNewGroupLinkPropActionA, changeSelectedGroupLinkPropActionA,  } from './store/groups/actions';
import { dropNewUnionAction, addUnionActionA  } from './store/newUnion/actions';
import { selectUnionAction } from './store/selectedUnion/actions';
import { getUnions, insertUnionBeforeSaveActionA, orderUpUnionActionA, orderDnUnionActionA, deleteUnionAction, changeNewUnionPropActionA, changeSelectedUnionPropActionA, changeNewUnionLinkPropActionA, changeSelectedUnionLinkPropActionA, deleteAllGroupUnionsActionA } from './store/unions/actions';
import { selectGroupAction, selectGroupThenSelectChild } from './store/selectedGroup/actions';
import { addGroupActionA, dropNewGroupAction } from './store/newGroup/actions';
import { getFiles } from './store/files/actions';
import { getRelatedImages } from './store/images/actions';
// import { Fade } from 'react-reveal';
import ShowTlgrmSettings from './components/ShowTlgrmSettings';
import SideMenu from './components/SideMenu/SideMenu';
import SideBar from './components/SideBar/SideBar';
import Header from './components/Header/Header';
import PalleteSeparator from './components/Elements/PalleteSeparator/PalleteSeparator';
import CommandPallete from './components/Elements/CommandPallete/CommandPallete';
import PalleteButton from './components/Elements/PalleteButton/PalleteButton';
import PalleteDropDown from './components/Elements/PalleteDropDown/PalleteDropDown';
import AddGroupComponent from './components/AddGroupComponent';
import AddUnionComponent from './components/AddUnionComponent';
import ShowSelectedGroup from './components/ShowSelectedGroup';
import ShowSelectedUnion from './components/ShowSelectedUnion';
import AddElementIcon from '@material-ui/icons/NoteAddOutlined';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import DelIcon from '@material-ui/icons/HighlightOffOutlined';
import SaveIcon from '@material-ui/icons/SaveOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ShowSettings from './components/ShowSettings';
import UploadStatus from './components/Elements/UploadStatus/UploadStatus';
import {AddGroupIcon} from './components/Elements/SvgIcons/SvgIconsSet';
import { Fade } from 'react-reveal';
import { Button } from '@material-ui/core';
import * as Types from './Interfaces';

export interface StandardComponentProps {
  data?: string,
  comment: string
}

const ChangesHistory =({data, comment}: StandardComponentProps) =>{
  return (
    <Fragment>
      <span>{data}г.</span>
      <p>{comment}</p>
    </Fragment>
  );
}

const ShowGreetingsComponent = () => {
  return (
    <div>
      <p>Начните работу с выбора группы или cоздания <AddGroupIcon fontSize="small"/> новой группы</p>
      <hr />
      <h3>Список изменений:</h3>
      <ChangesHistory data="21.02.2020" comment="Добавлен интерфейс управления загруженными файлами и каталогами" />
      <ChangesHistory data="02.01.2020" comment="Добавлена возможность интеграции с Telegram" />
      <ChangesHistory data="30.12.2019" comment="Исправлен баг, проявляющийся при упорядочивании вновь созданных элементов в новой группе при незазафиксированном состоянии" />
      <ChangesHistory data="27.12.2019" comment="Изменен код сервера. Названия файлов изображений при загрузке теперь автоматически переименовываются, пробелы заменяются на символы подчеркивания." />
      <ChangesHistory data="27.12.2019" comment="Добавлена возможность упорядочивания элементов." />
      <ChangesHistory data="26.12.2019" comment="Доработан адаптивный интерфейс для использования личного кабинета на мобильных устройствах." />
    </div>
  )
}

export interface IDelInfo {
  title?: string
}
const ShowDelInfo = ({title}:IDelInfo) => {
  const handlePageReload =()=>{
    window.location.reload();
  }
  return (
    <div>
      <h3>{title}</h3>
      <p>Для фиксации изменений сохраните состояние.</p>
      <br />
      <span>Для отмены </span><Button style={ { margin: '10px' } } component="label" variant="outlined" color="primary" onClick={handlePageReload}>перезагрузите страницу</Button>
    </div>
  )
}




interface IProps extends IDispatchProps, IStateProps{
  // onUserLogout: any,
  // onActionFired: any,
  // props: any,
  history: any,
  // groups: Array<Group>,
  // unions: Array<Union>,
  // settings: ISettingsForm,
  // newGroup: Group,
  // selectedGroup: Group,
  // newUnion: Union,
  // selectedUnion: Union, 
  user: Types.User,
  actionType: any,
  // auth: any,
  lastAction:  any,
  // company:  Company,
}

interface IState {
  version: string;
  remoteVersion: string;
  name: string;
  updateStatus: null,
  uploadStatus: string,
  uploadStatusCode: string,
  file?: any;
  changes: boolean;
  // elementToUpload?: ReactHTMLElement<HTMLInputElement>;
  elementToUpload?: string;
}

class Admin extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      version: '',
      remoteVersion: '',
      name: '',
      updateStatus: null,
      changes: false,
      uploadStatus: '',
      uploadStatusCode: 'prepare',
      file: null,
      elementToUpload: undefined
    };
  }

  componentDidMount = function() {
    //before init get user base from token to make defered api calls
    this.props.onUserSuccess(getUserInfo())
      .then(() => {
          fetchSettings(this.props.user.base).then(result => this.props.onGetSettings(result));
          fetchUnions(this.props.user.base).then(result => this.props.onGetUnionList(result));
          fetchGroups(this.props.user.base).then(result => this.props.onGetGroupList(result));
      })
  };

  componentDidUpdate = function(prevProps) {
    if (this.props.selectedGroup.title !== prevProps.selectedGroup.title) {
      this.setState({
        changes: true
      })
    }
  }

  onExit = () => {
    removeToken();
    this.props.onUserLogout();
    this.props.history.push('/')
  } 

  handleSubItemClick = ( item: Types.Union ) => {
    this.props.onActionFired({
      actionType: 'show',
      actionObject: 'union',
      actionId: item.index
    });
    this.props.onSelectGroupThenSelectChild(this.props.groups, item.parent);
    this.props.onSelectUnion(item);
    // если выбранный элемент не является дочерним для группы
    if (item.parent !== this.props.newGroup.index){
      this.props.onDropNewGroup();
    }
    this.setState({file: null})
  }

  handleMainItemClick = (item: any) => {
    this.props.onActionFired({
      actionType: 'show',
      actionObject: 'group',
      actionId: item.index
    });
    this.props.onSelectGroup(item);
    this.props.onSelectUnion(null); //need deselect
    this.props.onDropNewGroup();
    this.setState({file: null})
  }

  handleAddGroupClick = () => {
    this.props.onAddGroup( genId(), Object.keys(this.props.groups).length + 1 ).then(()=>{
      this.props.onInsertGroupBeforeSave(this.props.newGroup).then(()=>{
        this.props.onSelectGroup(this.props.newGroup);
        this.props.onSelectUnion(null); // need deselect
        this.props.onActionFired({
          actionType: 'add',
          actionObject: 'group',
        });
        this.setState({file: null})
        //scroll to bottom
     })
    });
  }

  getGroupLastUnion =(index: number)=>{
    const groupUnions = this.props.unions.filter(item =>{ return item.parent === index });
    if (groupUnions.length > 0){
    let max = groupUnions.reduce((prev, current)=>{
      return (prev.order! > current.order! ? prev : current)
    })
    return max.order! + 1;
  }else
    return 1;
  }

  handleAddUnionClick = () => {
    if (!this.props.selectedGroup.hasOwnProperty('index'))
      return;
  
    let base = this.props.user.base;
    let folder = 'nofolder';
    if (this.props.selectedGroup !== null || this.props.selectedGroup !== undefined)
      folder = this.props.selectedGroup.folder;

    this.props.onAddUnion(genId(), this.props.selectedGroup.index, this.getGroupLastUnion(this.props.selectedGroup.index) ).then(()=>{
      fetchFiles(folder || 'nofolder', base).then(result => this.props.onGetFilesNames(result));
      fetchImages(folder || 'nofolder', base).then(result => this.props.onGetRelatedImages(result));

      this.props.onInsertUnionBeforeSave(this.props.newUnion).then(()=>{
        // this.props.onSelectUnion(null);
        this.props.onSelectUnion(this.props.newUnion);
        this.props.onActionFired({
          actionType: 'add',
          actionObject: 'union'
        });
        this.setState({file: null})
      });
    });
  }

  handleSettingsClick = (item) => {
    this.props.onActionFired({
      actionType: this.props.actionType,
      actionObject: 'settings',
      actionId: item.index
    });
    this.setState({file: null})
  }

  handleTlgrmSettingsClick = (item) => {
    this.props.onActionFired({
      actionType: this.props.actionType,
      actionObject: 'tlgrmSettings',
      actionId: item.index
    });
  }

  handleDriveOperateClick = (item) => {
    this.props.onActionFired({
      actionType: this.props.actionType,
      actionObject: 'driveOperate',
      actionId: item.index
    });
  }

  handleSaveClick = () => {
    if (this.props.lastAction.actionObject === 'tlgrmSettings'){
      console.log('сохраняем настройки компании')
      postCompany(this.props.company, this.props.user.base)
      .then((result)=>{
        if ( result.success === true)
          window.alert('Настройки сохранены')
        else window.alert(result.error);
      })
      .catch(error=>{window.alert(error.error)})
    }else
    if (this.props.lastAction.actionObject === 'settings'){
      console.log('сохраняем настройки')
      postSettings(this.props.settings, this.props.user.base)
      .then((result)=>{
        if ( result.success === true)
          window.alert('Настройки сохранены')
        else window.alert(result.error);
      })
      .catch(error=>{window.alert( error.error)})
    }else

    postUnions(this.props.unions, this.props.user.base)
      .then((result) => {
        if (result.success === true){
          // получили положительный ответ
          this.props.onDropNewUnion();
          postGroups(this.props.groups, this.props.user.base)
            .then((result) => {
              if ( result.success === true){
                // получили положительный ответ
                this.props.onDropNewGroup();
                this.setState({file: null})
                window.alert('Сохранено')
              }
          })
        } 
        // ответ сервера получен, но запрос завершился ошибкой
         else window.alert(result.error);
      })
      // сработал catch{} блок в api
      .catch(error => {
        window.alert(error.error)
      })
  }

  handleDeleteClick =() =>{
    if (!this.props.selectedGroup.hasOwnProperty('index'))
      return;
    
    this.setState({file: null})
    
    // удаляем union и ставим selected = null
    if (this.props.selectedUnion.hasOwnProperty('index')){
      this.props.onActionFired({
        actionType: 'del',
        actionObject: 'union',
        actionId: this.props.selectedUnion.index
      });

      this.props.onDeleteUnion(this.props.selectedUnion);
      this.props.onSelectUnion(null);
      return;
    }

     // удаляем все юнионы в группе и ставим selected = null
    if (this.props.selectedGroup.hasOwnProperty('index')){
      this.props.onDeleteAllGroupUnions(this.props.selectedGroup.index).then( ()=>{
        this.props.onDeleteGroup(this.props.selectedGroup);
        this.props.onActionFired({
          actionType: 'del',
          actionObject: 'group',
          actionId: this.props.selectedGroup.index
        });
        this.props.onSelectGroup(null);
      });
      return;
    }
  }

  handleNewFolder =(folder: string)=>{
    sendNewFolderRequest(folder, this.props.user.base)
    .then((response) => {
          window.alert(response.message);
    })
    .catch(err=>{
        window.alert(err.message)
    })
    
};

// refactor candidate

onUpdateNewGroupLink = (folder, file)=> {
  let folde = folder === ''? folder : folder+ '/';
  const link = 'https://contentboard.site/d/' + this.props.user.base + '/pdf/'+ folde + file;
  this.props.onChangeNewGroupLinkProp(this.props.newGroup, link)
}

onUpdateSelectedGroupLink = (folder, file)=> {
  let folde = folder === ''? folder : folder+ '/';
  const link = 'https://contentboard.site/d/' + this.props.user.base + '/pdf/'+ folde + file;
  this.props.onChangeSelectedGroupLinkProp(this.props.selectedGroup, link)
}

onUpdateSelectedUnionLink = (folder, file)=> {
  let folde = folder === ''? folder : folder+ '/';
  const link = 'https://contentboard.site/d/' + this.props.user.base + '/pdf/'+ folde + file;
  this.props.onChangeSelectedUnionLinkProp(this.props.selectedUnion, link)
}

onUpdateNewUnionLink = (folder, file)=> {
  let folde = folder === ''? folder : folder+ '/';
  const link = 'https://contentboard.site/d/' + this.props.user.base + '/pdf/'+ folde + file;
  this.props.onChangeNewUnionLinkProp(this.props.newUnion, link)
}

  // move to admin
  handleDocumentUpload = (e: React.ChangeEvent<HTMLInputElement>) =>{
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    // console.log(e.target.files[0])
    // добавили 
    this.setState(
      {
        file : e.target.files[0],
        elementToUpload: e.target.id,
        uploadStatus: 'Файл готов к загрузке',
        uploadStatusCode : 'prepare'
      })
      let folder = 'nofolder';
      const base = this.props.user.base;
      if (this.props.newGroup.hasOwnProperty('index')){
        if (this.props.newGroup.folder && this.props.newGroup.folder !== '')
          folder = this.props.newGroup.folder;
      }else

      if (this.props.selectedGroup.hasOwnProperty('index')){
        if (this.props.selectedGroup.folder && this.props.selectedGroup.folder.trim())
          folder = this.props.selectedGroup.folder
      }

    postFile(formData, folder, base)
       .then((response)=>{
        if (response.success === true) {
          this.setState({uploadStatus: 'Файл загружен в программу', uploadStatusCode: 'success'})
          let field = [];
          let fileName = this.state.file.name.replace(/ /g,"_"); // удалим пробелы
          field['file'] = fileName;

          // в зав-ти от того какой компонент вызвал событие передаем подходящему редюсеру
          switch (this.state.elementToUpload){
            case 'selectedUnion':
                this.props.onChangeSelectedUnionProp(this.props.selectedUnion, field)
                .then(()=>{
                  this.onUpdateSelectedUnionLink(folder.replace(/nofolder/g,""), fileName);
                  sendTlgrmInfo(this.props.company.tlgrmEnabled, this.props.company.tlgrmToken, this.props.company.tlgrmChannel, `Обновлен элемент ${this.props.selectedUnion.title}  %0A%0A[${this.props.selectedUnion.title}](${this.props.selectedUnion.link})`);
                  fetchFiles(this.props.selectedGroup.folder || 'nofolder', this.props.user.base).then(result => this.props.onGetFilesNames(result));
                })
                break;
            case 'selectedGroup':
                this.props.onChangeSelectedGroupProp(this.props.selectedGroup, field)
                .then(()=>{
                  this.onUpdateSelectedGroupLink(folder.replace(/nofolder/g,""), fileName);
                  sendTlgrmInfo(this.props.company.tlgrmEnabled, this.props.company.tlgrmToken, this.props.company.tlgrmChannel, `Обновлен элемент ${this.props.selectedUnion.title}  %0A%0A[${this.props.selectedUnion.title}](${this.props.selectedUnion.link})`);
                  fetchFiles(this.props.selectedGroup.folder || 'nofolder', this.props.user.base).then(result => this.props.onGetFilesNames(result));
                })
                break;
            case 'newUnion':
                this.props.onChangeNewUnionProp(this.props.newUnion, field)
                .then(()=>{
                  this.onUpdateNewUnionLink(folder.replace(/nofolder/g,""), fileName)
                  fetchFiles(this.props.selectedGroup.folder || 'nofolder', this.props.user.base).then(result => this.props.onGetFilesNames(result));
                }) 
                break;
            case 'newGroup':
                
                this.props.onChangeNewGroupProp(this.props.newGroup, field)
                .then(()=>{
                  this.onUpdateNewGroupLink(folder.replace(/nofolder/g,""), fileName);
                  sendTlgrmInfo(this.props.company.tlgrmEnabled, this.props.company.tlgrmToken, this.props.company.tlgrmChannel, `Обновлен элемент ${this.props.selectedUnion.title}  %0A%0A[${this.props.selectedUnion.title}](${this.props.selectedUnion.link})`);
                  fetchFiles(this.props.newGroup.folder || 'nofolder', this.props.user.base).then(result => this.props.onGetFilesNames(result));
                })
                break;
          }
        }
      })
      .catch(error => {
        console.log(error.message);
          this.setState({uploadStatus: 'Ошибка загрузки файла', uploadStatusCode: 'fail'})
      });
  }

  // move to admin
  handleImageUpload = (e) =>{
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    // добавили 
    this.setState(
      {
        file : e.target.files[0],
        elementToUpload: e.target.id,
        uploadStatus: 'Изображение готово к загрузке'
      })
      // console.log(e.target.id)
      let folder = 'nofolder';
      const base = this.props.user.base;
      if (this.props.newGroup.hasOwnProperty('index')){
        if (this.props.newGroup.folder !== '')
          folder = this.props.newGroup.folder;
      }else

      if (this.props.selectedGroup.hasOwnProperty('index')){
        if (this.props.selectedGroup.folder !=='')
          folder = this.props.selectedGroup.folder
      }

      postImage(formData, folder, base)
       .then((response)=>{
        if (response.success === true) {
          this.setState({uploadStatus: 'Изображение загружено в программу', uploadStatusCode :'success'})
          let field = [];
          field['image'] = this.state.file.name.replace(/ /g,"_");
          
          switch (this.state.elementToUpload){
            case 'selectedUnion':
                this.props.onChangeSelectedUnionProp(this.props.selectedUnion, field)
                .then(()=>{
                  fetchImages(this.props.selectedGroup.folder || 'nofolder', this.props.user.base).then(result => this.props.onGetRelatedImages(result));
                })
                
                break;
            case 'selectedGroup':
                fetchImages(this.props.selectedGroup.folder || 'nofolder', this.props.user.base).then(result => this.props.onGetRelatedImages(result));
                this.props.onChangeSelectedGroupProp(this.props.selectedGroup, field);
                break;
            case 'newUnion':
                this.props.onChangeNewUnionProp(this.props.newUnion, field)
                .then(()=>{
                  fetchImages(this.props.selectedGroup.folder || 'nofolder', this.props.user.base).then(result => this.props.onGetRelatedImages(result));
                }) 
                break;
            case 'newGroup':
                fetchImages(this.props.newGroup.folder || 'nofolder', this.props.user.base).then(result => this.props.onGetRelatedImages(result));
                this.props.onChangeNewGroupProp(this.props.newGroup, field);
                break;
          }
        }
      })
      .catch(error => {
        console.log(error.message);
          this.setState({uploadStatus: 'Ошибка загрузки файла', uploadStatusCode : 'fail'})
      });
  }

  handleUpClick = () =>{
    if (!this.props.selectedGroup.hasOwnProperty('index'))
      return;
    let selectedIndex = this.props.newGroup.hasOwnProperty('index') ? (this.props.selectedUnion.hasOwnProperty('index')? this.props.selectedUnion.index: this.props.newGroup.index) : (this.props.selectedUnion.hasOwnProperty('index')? this.props.selectedUnion.index : this.props.selectedGroup.index);

    //двигаем union
    if (this.props.selectedUnion.hasOwnProperty('index') ){
      this.props.onOrderUpUnion(selectedIndex)
      .then(()=>{
        let changedProp = Array<number>();
        changedProp['order'] = this.props.unions.filter((item)=>(item.index === selectedIndex))[0].order;
        this.props.onChangeSelectedUnionProp(this.props.selectedUnion, changedProp)
      }); 
    }
    else
    //двигаем group
    if (this.props.selectedGroup.hasOwnProperty('index') || this.props.newGroup.hasOwnProperty('index')){
     
      this.props.onOrderUpGroup(selectedIndex)
      .then(()=>{
        let changedProp = Array<number>();
        changedProp['order'] = this.props.groups.filter((item)=>(item.index === selectedIndex))[0].order;
        this.props.onChangeSelectedGroupProp(this.props.selectedGroup, changedProp)
      }); 
    }
  }

  handleDownClick = () =>{
    if (!this.props.selectedGroup.hasOwnProperty('index'))
    return;

    let selectedIndex = this.props.newGroup.hasOwnProperty('index') ? (this.props.selectedUnion.hasOwnProperty('index')? this.props.selectedUnion.index: this.props.newGroup.index) : (this.props.selectedUnion.hasOwnProperty('index')? this.props.selectedUnion.index : this.props.selectedGroup.index);
    //двигаем union
    if (this.props.selectedUnion.hasOwnProperty('index')){
       this.props.onOrderDownUnion(selectedIndex)
       .then(()=>{
        let changedProp = Array<number>();
        changedProp['order'] = this.props.unions.filter((item)=>(item.index === selectedIndex))[0].order;
        this.props.onChangeSelectedUnionProp(this.props.selectedUnion, changedProp)
      });
    }
    else
    //двигаем group
    if (this.props.selectedGroup.hasOwnProperty('index') || this.props.newGroup.hasOwnProperty('index')){
      console.log(selectedIndex)
      this.props.onOrderDownGroup(selectedIndex) 
      .then(()=>{
        let changedProp = Array<number>();
        changedProp['order'] = this.props.groups.filter((item)=>(item.index === selectedIndex))[0].order;
        this.props.onChangeSelectedGroupProp(this.props.selectedGroup, changedProp)
      });
    }
  }

  resetUploadStatus = () =>{
    this.setState({file: null, uploadStatus: '', uploadStatusCode: 'prepare'})
  }

  renderCondition(lastAction, props) {
    switch (lastAction.actionObject) {
      case 'group':
        if (lastAction.actionType === 'show')
          return <ShowSelectedGroup onDocumentUpload={this.handleDocumentUpload} onImageUpload={this.handleImageUpload} onNewFolder={this.handleNewFolder} onUpdateLink={this.onUpdateSelectedGroupLink}/>;
        if (lastAction.actionType === 'add')
          return <AddGroupComponent onDocumentUpload={this.handleDocumentUpload} onImageUpload={this.handleImageUpload} onNewFolder={this.handleNewFolder} onUpdateLink={this.onUpdateNewGroupLink}/>
        if (lastAction.actionType === 'del')
          return <ShowDelInfo title={`Группа `+ lastAction.actionId + ` была удалена`} />
       
        break;
      case 'union':
        if (lastAction.actionType === 'show')
          return <ShowSelectedUnion onDocumentUpload={this.handleDocumentUpload} onImageUpload={this.handleImageUpload} onUpdateLink={this.onUpdateSelectedUnionLink}/>;
        if (lastAction.actionType === 'add')
          return <AddUnionComponent onDocumentUpload={this.handleDocumentUpload} onImageUpload={this.handleImageUpload} onUpdateLink={this.onUpdateNewUnionLink}/>
        if (lastAction.actionType === 'del')
          return <ShowDelInfo title={`Элемент `+ lastAction.actionId + ` был удален`} />
       
        break;
      case 'settings':
        return <ShowSettings />;

      case 'tlgrmSettings':
        return <ShowTlgrmSettings />
      case 'driveOperate':
       // driveOperate is Route managed with router via Link in PalleteButton component
      break;
      // case 'files':
      //   return <ShowFilesComponent />;
      // default:
      //   return <ShowStatusComponent />;
      default:
        return <ShowGreetingsComponent />
    }
  }

  render() {
    let email;
    if(this.props.auth && this.props.auth.user)
      email = this.props.auth.user.email;
    return (
      <Fragment>
        <UploadStatus file={this.state.file} statusText={this.state.uploadStatus} statusCode={this.state.uploadStatusCode} resetUploadStatus={this.resetUploadStatus} />
        <div className="Top">
          <Header title={ 'ContentBoard' + this.state.name + ' ' + this.state.version } stepBack={false} email={ email } onExit={ this.onExit } />
        </div>
        <div className="Container">
          <div className="Left">
            <CommandPallete>
              <PalleteButton onClick={ this.handleAddGroupClick } className="palleteButton" dataHint="Добавить группу">
                <AddGroupIcon />
              </PalleteButton>
              <PalleteButton onClick={ this.handleAddUnionClick } dataHint="Добавить элемент"  
                className={ !this.props.selectedGroup.hasOwnProperty('index') ? 'palleteButton disabled' : 'palleteButton' }>
                <AddElementIcon />
              </PalleteButton>
              <PalleteSeparator />
              <PalleteButton onClick={ this.handleDownClick } className={this.props.selectedUnion.hasOwnProperty('index') || this.props.newUnion.hasOwnProperty('index') ||this.props.selectedGroup.hasOwnProperty('index') || this.props.newGroup.hasOwnProperty('index') ? 'palleteButton' : 'palleteButton disabled'} dataHint="Переместить вниз" >
                <ArrowDropDown/>
              </PalleteButton>
              <PalleteButton onClick={ this.handleUpClick } className={(this.props.selectedUnion.hasOwnProperty('index') || this.props.newUnion.hasOwnProperty('index') ||this.props.selectedGroup.hasOwnProperty('index') || this.props.newGroup.hasOwnProperty('index'))? 'palleteButton': 'palleteButton disabled'} dataHint="Переместить вверх" >
                <ArrowDropUp />
              </PalleteButton>
              <PalleteSeparator />
              <PalleteButton onClick={ this.handleDeleteClick } dataHint="Удалить выбранный элемент или группу" className={ !this.props.selectedGroup.hasOwnProperty('index') ? 'palleteButton disabled' : 'palleteButton' }>
                <DelIcon />
              </PalleteButton>
              <PalleteButton onClick={ this.handleSaveClick } dataHint="Сохранить состояние" className="palleteButton">
                <SaveIcon />
              </PalleteButton>
              <PalleteSeparator />
              <PalleteDropDown icon={ <SettingsIcon />} dataHint="Показать настройки" className="palleteDropDown">
                <PalleteButton onClick={ this.handleSettingsClick } caption="Основные" className="palleteButton" />
                <PalleteButton onClick={ this.handleTlgrmSettingsClick } caption="Telegram" className="palleteButton" />
                <PalleteButton onClick={ this.handleDriveOperateClick } linkTo="/drive" caption="Управление файлами" className="palleteButton" />
              </PalleteDropDown>
            </CommandPallete>
            <SideBar>
              <SideMenu title="Структура меню" mainList={ this.props.groups } subList={ this.props.unions } expandable={ true } handleSubItemClick={ this.handleSubItemClick }
                handleMainItemClick={ this.handleMainItemClick } mainItemSelectedIndex={ this.props.selectedGroup.index } subItemSelectedIndex={ this.props.selectedUnion.index } />
            </SideBar>
          </div>
          <div className="Middle">
            <Fade> 
              <div className="main">
                <div className="vContainer">
                  { this.renderCondition(this.props.lastAction, this.props) }
                </div>
              </div>
           </Fade> 
          </div>
            
        </div>
      </Fragment>
      );
  }
}

interface IStateProps {
    user: Types.User;
    unions: Array<Types.Union>;
    groups: Array<Types.Group>;
    settings: Types.SettingsForm;
    auth: Types.Auth;
    lastAction: Types.LastAction;
    selectedGroup: Types.Group;
    selectedUnion: Types.Union;
    newGroup: Types.Group;
    newUnion: Types.Union;
    company: Types.Company;
}


const mapStateToProps = (state: IStateProps) => ({
    user: state.auth.user,
    unions: state.unions,
    groups: state.groups,
    settings: state.settings,
    auth: state.auth,
    lastAction: state.lastAction,
    selectedGroup: state.selectedGroup,
    selectedUnion: state.selectedUnion,
    newGroup: state.newGroup,
    newUnion: state.newUnion,
    company: state.company
});

interface IDispatchProps  {
  onGetUnionList: (items: Types.Union[]) => void;
  onGetGroupList: (items: Types.Group[]) => void;
  onSelectGroup: (group: Types.Group) => void; 
  onAddGroup: (id: number, order: number) =>  Promise<void>;
  onAddUnion: (id: number, parent: number, order: number) =>  Promise<void>;
  onSelectGroupThenSelectChild: (groups:Types.Group[], index: number) => void;
  onInsertGroupBeforeSave: (newGroup: Types.Group) => Promise<void>;
  onSelectUnion: (union: Types.Union) => void;
  onGetSettings: (items: Types.SettingsForm) => void;
  onUserSuccess: (user: Types.User) => void;
  onUserLogout: () => void;
  onActionFired: typeof fireAction;
  onDeleteUnion: (union: Types.Union) => void;
  onDeleteGroup: (group: Types.Group) => void;
  onDeleteAllGroupUnions: (parent: number) => Promise<void>;
  onDropNewGroup: () => void;
  onDropNewUnion: () => void;
  onGetFilesNames: (items: Types.File[]) => void;
  onGetRelatedImages: (items: Types.Image[]) => void;
  onInsertUnionBeforeSave: (newUnion: Types.Union) =>  Promise<void>;
  // onChangeProp: (selected, field, elementType: any) => void;
  // onChangeLinkProp: (selected, link, elementType: any) => void;
  onChangeSelectedUnionProp: (selected: Types.Union, field: any) => Promise<void>;
  onChangeNewGroupProp: (selected: Types.Group, field: any) => Promise<void>;
  onChangeNewUnionProp: (selected: Types.Union, field: any) => Promise<void>;
  onChangeSelectedGroupProp: (selected: Types.Group, field: Array<any>) => Promise<void>;
  onChangeSelectedUnionLinkProp: (union: Types.Union, link: any) => void;
  onChangeNewUnionLinkProp: (union: Types.Union, link: any) => void;
  onChangeSelectedGroupLinkProp: (group:Types.Group, link: any) => void;
  onChangeNewGroupLinkProp: (group:Types.Group, link: any) => void;
  onOrderDownGroup: (selectedIndex: number) => Promise<void>;
  onOrderUpGroup: (selectedIndex: number) => Promise<number>;
  onOrderDownUnion: (selectedIndex: number) => Promise<void>;
  onOrderUpUnion: (selectedIndex: number) => Promise<void>;
};

const mapDispatchToProps = (dispatch: Function): IDispatchProps => {
  return {
    onGetUnionList: (items: any) => dispatch(getUnions(items)),
    onGetGroupList: (items: any) => dispatch(getGroups(items)),
    onSelectGroup: (group) => dispatch(selectGroupAction(group)),
    onAddGroup: (id, order)=>dispatch(addGroupActionA(id, order)),
    onAddUnion: (id, parent, order) => dispatch(addUnionActionA(id,  parent,  order)),
    onSelectGroupThenSelectChild: (groups, index) => dispatch(selectGroupThenSelectChild(groups, index)),
    onInsertGroupBeforeSave: (newGroup) => dispatch(insertGroupBeforeSaveActionA(newGroup)),
    onSelectUnion: union => dispatch(selectUnionAction(union)),
    onGetSettings: (items) => dispatch(getSettings(items)),
    onUserSuccess: user => dispatch(userSuccessActionA(user)),
    onUserLogout: () => dispatch(userLogoutAction()),
    onActionFired: (act, history) => dispatch(fireAction(act, history)),
    onDeleteUnion: union => dispatch(deleteUnionAction(union)),
    onDeleteGroup: group => dispatch(deleteGroupAction(group)),
    onDeleteAllGroupUnions: parent => dispatch(deleteAllGroupUnionsActionA(parent)),
    onDropNewUnion:()=> dispatch(dropNewUnionAction()),
    onDropNewGroup:()=> dispatch(dropNewGroupAction()),
    onGetFilesNames: items => dispatch(getFiles(items)),
    onGetRelatedImages: items => dispatch(getRelatedImages(items)),
    onInsertUnionBeforeSave: newUnion=>dispatch(insertUnionBeforeSaveActionA(newUnion)),
    // onChangeProp: (selected, field, elementType) => dispatch(changeProp(selected, field, elementType)),
    // onChangeLinkProp: (selected, link, elementType)=> dispatch(changeLinkProp(selected, link, elementType)),
    onChangeSelectedUnionProp: (selected, field) => dispatch(changeSelectedUnionPropActionA(selected, field)),
    onChangeNewGroupProp: (selected, field) => dispatch(changeNewGroupPropActionA(selected, field)),
    onChangeNewUnionProp: (selected, field) => dispatch(changeNewUnionPropActionA(selected, field)),
    onChangeSelectedGroupProp: (selected, field) => dispatch(changeSelectedGroupPropActionA(selected, field)),
    onChangeSelectedUnionLinkProp: (union, link)=> dispatch(changeSelectedUnionLinkPropActionA(union, link)),
    onChangeNewUnionLinkProp: (union, link)=> dispatch(changeNewUnionLinkPropActionA(union, link)),
    onChangeSelectedGroupLinkProp: (group, link)=> dispatch(changeSelectedGroupLinkPropActionA(group, link)),
    onChangeNewGroupLinkProp: (group, link)=> dispatch(changeNewGroupLinkPropActionA(group, link)),
    onOrderDownGroup: selectedIndex => dispatch(orderDnGroupActionA(selectedIndex)),
    onOrderUpGroup: selectedIndex => dispatch(orderUpGroupActionA(selectedIndex)),
    onOrderDownUnion: selectedIndex => dispatch(orderDnUnionActionA(selectedIndex)),
    onOrderUpUnion: selectedIndex => dispatch(orderUpUnionActionA(selectedIndex)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);