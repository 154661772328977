import { ActionCreator, AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import * as SettingsTypes from './types';
import * as Types from '../../Interfaces'


type ThunkResult<R> = ThunkAction<R, SettingsTypes.ISettingsState, undefined, AnyAction>;

export const getSettings: ActionCreator<SettingsTypes.IGetSettingsAction> = (settings: Types.SettingsForm) => {
  return {
    type: SettingsTypes.SETTINGS_SUCCESS,
    payload: settings
  }
}

export function changeSettingsPropActionA (prop: any) : ThunkResult<Promise<SettingsTypes.ISettingsState>> {
  return async (dispatch, getState) => {
    dispatch({
      type: SettingsTypes.CHANGE_SETTINGS_PROP,
      payload: prop
    });
    return Promise.resolve(getState())
  }
}