import React, { Component } from "react";
import { connect } from "react-redux";
import { getBgImagesAction } from "../store/bgImages/actions";
import { changeSettingsPropActionA } from "../store/settings/actions";
import { fetchBgImages, postBgImage } from "../api";
import SettingsForm from "./SettingsForm/SettingsForm";
import * as Types from "../Interfaces";

interface IProps extends IStateProps, IDispatchProps {
	// onBgImageUpload(e: any): void;
}

interface IState {
	uploadStatus: string;
	uploadStatusCode: string;
	file: any;
}

class ShowSettings extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.onCheckboxChange = this.onCheckboxChange.bind(this);
	}

	componentDidMount = () => {
		if (this.props.bgImages.length === 0) {
			fetchBgImages(this.props.user.base).then(result =>
				this.props.onGetBgImages(result)
			);
		}
	};

	onChange = e => {
		let field = [];
		field[e.target.name] = e.target.value;
		this.props.onChangeSettingsProp(field);
	};

	onChangeImage = (
		e: React.ChangeEvent<HTMLSelectElement>,
		child: React.ReactNode
	): void => {
		let field = [];
		field[e.target.name] = e.target.value;
		this.props.onChangeSettingsProp(field);
	};

	onCheckboxChange = e => {
		let field = [];
		field[e.target.name] = e.target.checked;
		this.props.onChangeSettingsProp(field);
	};

	handleBgImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		const formData = new FormData();
		formData.append("file", e.target.files[0]);

		this.setState(
			{
				file: e.target.files[0],
				uploadStatus: "Файл готов к загрузке",
				uploadStatusCode: "prepare"
			},
			() => {
				postBgImage(formData, this.props.user.base)
					.then(response => {
						if (response.success === true) {
							this.setState({
								uploadStatus: "Файл загружен в программу",
								uploadStatusCode: "success"
							});
							let field = [];
							field["backgroundImage"] = this.state.file.name.replace(
								/ /g,
								"_"
							);
							this.props.onChangeSettingsProp(field).then(() => {
								fetchBgImages(this.props.user.base).then(result =>
									this.props.onGetBgImages(result)
								);
							});
						}
					})
					.catch(error => {
						console.log(error.message);
						this.setState({
							uploadStatus: "Ошибка при загрузке файла",
							uploadStatusCode: "fail"
						});
					});
			}
		);
	};

	render() {
		return (
			<div className="tableWrapper">
				<SettingsForm
					settings={this.props.settings}
					uploadStatus={this.state?.uploadStatus}
					uploadStatusCode={this.state?.uploadStatusCode}
					file={this.state?.file}
					bgImages={this.props.bgImages}
					onChange={this.onChange}
					onChangeImage={this.onChangeImage}
					onCheckboxChange={this.onCheckboxChange}
					onBgImageUpload={this.handleBgImageUpload}
				/>
			</div>
		);
	}
}

interface IStateProps {
	settings: Types.SettingsForm;
	bgImages: Array<Types.BgImage>;
	user: Types.User;
}

const mapStateToProps = (state: any): IStateProps => {
	return {
		settings: state.settings,
		bgImages: state.bgImages,
		user: state.auth.user
	};
};

interface IDispatchProps {
	onChangeSettingsProp: (field: any) => Promise<void>;
	onGetBgImages: typeof getBgImagesAction;
}

const mapDispatchToProps = (dispatch: Function): IDispatchProps => {
	return {
		onChangeSettingsProp: (field: any) =>
			dispatch(changeSettingsPropActionA(field)),
		onGetBgImages: items => dispatch(getBgImagesAction(items))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowSettings);
