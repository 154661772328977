import {log} from '../../api';
import * as AccountsTypes from './types';

const initialState : AccountsTypes.IAccountsState = [];

export function accountsReducer(state = initialState, action : AccountsTypes.AccountsActionsTypes) : AccountsTypes.IAccountsState {
  switch(action.type) {

    case AccountsTypes.ACCOUNTS_LIST_SUCCESS:
      // make new object from state and payload. No mutations here
      let newStateObject = Object.assign({}, state, action.payload);
      log('accounts loading done');

      // convert the shape of new state to array, so i can iterate it later in
      // component.1
      return Array.from(Object.keys(newStateObject), k => newStateObject[k]);

    case AccountsTypes.DELETE_ACCOUNT:
      const filtered = [...state];
      return filtered.filter(item => {
        return item._id !== action.payload
      })

    default:
      return state;
  }
}