import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { userSuccessActionA, userLogoutAction } from "../store/auth/actions";
import {
	getRelatedImages,
	selectImageFileAction,
	deleteSelectedImagesAction
} from "../store/images/actions";
import {
	getBgImagesAction,
	selectBgImageFileAction,
	deleteSelectedBgImagesAction
} from "../store/bgImages/actions";
import {
	getFiles,
	selectPDFFileAction,
	deleteSelectedPDFsAction
} from "../store/files/actions";
import {
	selectFolderAction,
  deselectFolderAction,
  deleteSelectedFolderAction
} from "../store/folders/actions";
import { getFolders } from "../store/folders/actions";
import {
	fetchFiles,
	fetchImages,
	fetchBgImages,
	getUserInfo,
	removeToken,
	fetchFoldersFor,
  sendDeleteFilesRequest,
  sendDeleteFolderRequest
} from "../api";
import UploadStatus from "./Elements/UploadStatus/UploadStatus";
import { FileCabinetIcon } from "./Elements/SvgIcons/SvgIconsSet";
import DriveOperate from "./DriveOperate/DriveOperate";
import Header from "./Header/Header";
import * as Types from "../Interfaces";

interface IDriveProps extends IDispatchProps, IStateProps {
	history: any;
	resetUploadStatus(): void;
}

interface IDriveState {
	uploadStatus?: string;
	file?: any;
	folderType?: Types.FolderType;
}

class ShowDriveComponent extends Component<IDriveProps, IDriveState> {
	constructor(props: IDriveProps) {
		super(props);
		this.state = {
			uploadStatus: "",
			file: null,
			folderType: Types.FolderType.pdf
		};
	}

	folderTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		this.props.onDeselectFolder();
		this.setState({ folderType: e.target.value as Types.FolderType }, () => {
			switch (this.state.folderType) {
				case Types.FolderType.pdf:
					fetchFiles("nofolder", this.props.user.base).then(result =>
						this.props.onGetFilesNames(result)
					);
					break;
				case Types.FolderType.image:
					fetchImages("nofolder", this.props.user.base).then(result =>
						this.props.onGetRelatedImages(result)
					);
					break;
				case Types.FolderType.bg:
					fetchBgImages(this.props.user.base).then(result =>
						this.props.onGetBgImages(result)
					);
					break;
			}
		});
	};

	componentDidMount = () => {
		//before init get user base from token to make defered api calls
		this.props.onUserSuccess(getUserInfo()).then(() => {
			fetchFoldersFor(
				this.state.folderType,
				this.props.user.base
			).then(result => this.props.onGetFolders(result));
			fetchFiles("nofolder", this.props.user.base).then(result =>
				this.props.onGetFilesNames(result)
			);
			// проверить - возможно следующу строку нужно удалить т.к. двойной запрос на
			// fetchImages( 'nofolder', this.props.user.base).then(result => this.props.onGetRelatedImages(result));
		});
	};

	onExit = () => {
		removeToken();
		this.props.onUserLogout();
		this.props.history.push("/");
	};

	handleSelectElement = (file: Types.File) => {
		switch (this.state.folderType) {
			case Types.FolderType.pdf:
				this.props.onSelectPDFFile(file);
				break;
			case Types.FolderType.image:
				this.props.onSelectImageFile(file);
				break;
			// case Types.FolderType.video:
			//   elementsToDelete = this.props.video.filter((item) => item.selected);
			// break;
			case Types.FolderType.bg:
				this.props.onSelectBgImageFile(file);
				break;
		}
	};

	handleSelectFolder = (folder: Types.File) => {
		this.props.onSelectFolder(folder);
		switch (this.state.folderType) {
			case Types.FolderType.pdf:
				fetchFiles(folder.fileName, this.props.user.base).then(result =>
					this.props.onGetFilesNames(result)
				);
				break;
			case Types.FolderType.image:
				fetchImages(folder.fileName, this.props.user.base).then(result =>
					this.props.onGetRelatedImages(result)
				);
				break;
			// case Types.FolderType.video:
			//   elementsToDelete = this.props.video.filter((item) => item.selected);
			// break;
			case Types.FolderType.bg:
				fetchImages("nofolder", this.props.user.base).then(result =>
					this.props.onGetRelatedImages(result)
				);
				break;
		}
	};

	deleteWholeFolder = () => {
		if (
			window.confirm(
				`Это действие удалит все изображения, видео и pdf-документы из каталога '${this.props.selectedFolder.fileName}'.\nПодтвердите удаление каталога.`
			)
		) {
      sendDeleteFolderRequest(
        this.props.selectedFolder.fileName,
        this.props.user.base,        
      ).then(result => {
        if (result.success) {
          alert("Удалено каталогов: " + result.deleted?.length);
          this.props.onDeleteSelectedFolder();
         
        } else alert("Ошибка при удалении файлов. " + result.error);
      });
		}
	};

	deleteSelectedFiles = (elementsToDelete: Types.File[]) => {
		
		if (elementsToDelete.length !== 0) {
			if (
				window.confirm(
					`Действительно удалить выбранные элементы (${elementsToDelete.length})? Файлы и каталоги удаляются без помещения в корзину.`
				)
			) {
				sendDeleteFilesRequest(
					this.props.selectedFolder?.fileName || "nofolder",
					this.props.user.base,
					elementsToDelete,
					this.state.folderType
				).then(result => {
					if (result.success) {
						alert("Удалено файлов: " + result.deleted?.length);
						if (this.state.folderType === Types.FolderType.pdf)
							this.props.onDeleteSelectedPDFs();
						if (this.state.folderType === Types.FolderType.image)
							this.props.onDeleteSelectedImages();
						if (this.state.folderType === Types.FolderType.bg)
							this.props.onDeleteSelectedBgImages();
						// if (this.state.folderType === Types.FolderType.video)
						// this.props.onDeleteSelectedVideos();
					} else alert("Ошибка при удалении файлов. " + result.error);
				});
			}
		}
	};

	handleDeleteElement = () => {
		let elementsToDelete: Types.File[] = [];

		switch (this.state.folderType) {
			case Types.FolderType.pdf:
				elementsToDelete = this.props.files.filter(item => item.selected);
				if (elementsToDelete.length === 0 && this.props.selectedFolder)
					this.deleteWholeFolder();
				else this.deleteSelectedFiles(elementsToDelete);
				break;

			case Types.FolderType.image:
				elementsToDelete = this.props.images.filter(item => item.selected);
				if (elementsToDelete.length === 0 && this.props.selectedFolder)
					this.deleteWholeFolder();
				else this.deleteSelectedFiles(elementsToDelete);
				break;

			case Types.FolderType.bg:
				elementsToDelete = this.props.bgImages.filter(item => item.selected);
				if (elementsToDelete.length === 0 && this.props.selectedFolder)
					this.deleteWholeFolder();
				else this.deleteSelectedFiles(elementsToDelete);
				break;
		}
	};

	render() {
		return (
			<Fragment>
				<div className="Top">
					{this.props.user && (
						<Header
							title={"Управление файлами"}
							Icon={FileCabinetIcon}
							stepBack={true}
							history={this.props.history}
							onExit={this.onExit}
						/>
					)}
				</div>
				<DriveOperate
					files={this.props.files}
					images={this.props.images}
					folders={this.props.folders}
					folderType={this.state.folderType}
					folderTypeChange={this.folderTypeChange}
					onSelect={this.handleSelectElement}
					onDelete={this.handleDeleteElement}
					onSelectFolder={this.handleSelectFolder}
					selectedFolder={this.props.selectedFolder}
				/>

				<UploadStatus file={this.state.file} statusText={this.state.uploadStatus} statusCode="prepare"/>
			</Fragment>
		);
	}
}

interface IStateProps {
	folders: Types.File[];
	files: Types.File[];
	images: Types.File[];
	bgImages: Types.File[];
	auth: Types.Auth;
	user: Types.User;
	selectedFolder: Types.File;
}

const mapStateToProps = (state: IStateProps, ownProps) => {
	return {
		folders: state.folders,
		files: state.files,
		images: state.images,
		bgImages: state.bgImages,
		user: state.auth.user,
		selectedFolder: state.folders.filter(folder => folder.selected)[0]
	};
};

interface IDispatchProps {
	onUserSuccess: (user: Types.User) => Promise<void>;
	onUserLogout: () => void;
	onGetFilesNames: (items: Array<Types.File>) => void;
	onGetRelatedImages: (items: Array<Types.File>) => void;
	onGetBgImages: (items: Array<Types.File>) => void;
	onGetFolders: (items: Array<Types.File>) => void;
	onSelectPDFFile: (file: Types.File) => void;
	onSelectImageFile: (image: Types.File) => void;
	onSelectBgImageFile: (bgImage: Types.File) => void;
	onSelectFolder: (folder: Types.File) => void;
	onDeselectFolder: () => void;
	onDeleteSelectedPDFs: () => void;
	onDeleteSelectedImages: () => void;
	onDeleteSelectedBgImages: () => void;
  // onDeleteSelectedVideos: () => void;
  onDeleteSelectedFolder: () => void;
}

const mapDispatchToProps = (dispatch: Function): IDispatchProps => {
	return {
		onUserSuccess: user => dispatch(userSuccessActionA(user)),
		onUserLogout: () => dispatch(userLogoutAction()),
		onGetFilesNames: items => dispatch(getFiles(items)),
		onGetRelatedImages: items => dispatch(getRelatedImages(items)),
		onGetBgImages: items => dispatch(getBgImagesAction(items)),
		onGetFolders: items => dispatch(getFolders(items)),
		onSelectPDFFile: (file: Types.File) => dispatch(selectPDFFileAction(file)),
		onSelectImageFile: (file: Types.File) =>
			dispatch(selectImageFileAction(file)),
		onSelectBgImageFile: (bgImage: Types.File) =>
			dispatch(selectBgImageFileAction(bgImage)),
		onSelectFolder: (folder: Types.File) =>
			dispatch(selectFolderAction(folder)),
		onDeselectFolder: () => dispatch(deselectFolderAction()),
		onDeleteSelectedPDFs: () => dispatch(deleteSelectedPDFsAction()),
		onDeleteSelectedImages: () => dispatch(deleteSelectedImagesAction()),
		onDeleteSelectedBgImages: () => dispatch(deleteSelectedBgImagesAction()),
    // onDeleteSelectedVideos:()=> dispatch(deleteSelectedVideosAction()),
    onDeleteSelectedFolder: () => dispatch(deleteSelectedFolderAction())
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ShowDriveComponent)
);
