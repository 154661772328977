import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Folder from '@material-ui/icons/CloudUploadOutlined';
import Switcher from '../Elements/Switcher/Switcher';
import UploadStatus from '../Elements/UploadStatus/UploadStatus';
import { TextField } from 'material-ui';
import {ImageSelect, ImageSelectI} from '../Elements/ImageSelect/ImageSelect';

import * as Types from '../../Interfaces';

const BGSelect = ImageSelect as ImageSelectI<Array<Types.BgImage>>

interface IProps {
  onChange(e: Event): void;
  onCheckboxChange(e: Event): void;
  onBgImageUpload(e: React.ChangeEvent<HTMLInputElement>): void;
  onChangeImage(e: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode): void;
  uploadStatus: string,
  uploadStatusCode: string,
  file?: any;
  settings: Types.SettingsForm;
  bgImages: Array<Types.BgImage>;
}

const SettingsForm:React.FC<IProps> = (props) => {

    return (
    <Fragment>
      <div className="contentWrapper">
      <div className="set-holder">
          <p className="rowhead">Главный экран</p>
          <div className="caption">Стратегия отображения <span className="caption">[целое число]</span></div>
          <div><TextField fullWidth className="textField" type="number" name="mainScreenStrategy"  value={ props.settings.mainScreenStrategy } onChange={ props.onChange } /></div>
        </div>

        <div className="set-holder">
          <p className="rowhead">Таймаут бездействия программы (мин)</p>
          <div className="caption">Таймаут для возврата из подменю <span className="caption">[целое число]</span></div>
          <div><TextField fullWidth className="textField" type="number" name="groupsTimeout"  value={ props.settings.groupsTimeout } onChange={ props.onChange } /></div>
        </div>
        <div className="set-holder">
          <div className="caption">Таймаут для возврата из документа <span className="caption">[целое число]</span></div>
          <div> <TextField fullWidth className="textField" type="number" name="documentsTimeout"  value={ props.settings.documentsTimeout } onChange={ props.onChange } /></div>
        </div>
        <div className="set-holder">
          <p className="rowhead">Фон</p>
          <div className="caption">Цвет фона <span className="caption">[#hex, rgb(x,x,x), rgba(x,x,x,0-1) где x=0-255]</span></div>
          <div><TextField fullWidth className="textField" type="text" name="backgroundColor" value={ props.settings.backgroundColor } onChange={ props.onChange } /></div>
        </div>
        <div className="set-holder">
          <div className="caption">Фоновое изображение <span className="caption">[файл в формате jpg, png]</span>
            <div className="hor-set-holder">
            <BGSelect images={props.bgImages} name="backgroundImage" value={ props.settings.backgroundImage } onChangeSelectedImage={  props.onChangeImage } />
            <Button style={{margin: '10px'}} variant="contained" component="label" size="small">
              <Folder />
                  <input id="fileInput" type="file" accept="image/*, application/images" style={{display: "none"}} onChange={props.onBgImageUpload} /> 
                </Button>
                </div>
                <UploadStatus file={props.file} statusText={props.uploadStatus} statusCode={props.uploadStatusCode}/>
                </div>
        </div>
        
        <div className="set-holder">
          <p className="rowhead">Кнопки</p>
          <div className="caption">Ширина кнопки <span className="caption">[px, %, em, rem]</span></div>
          <div><TextField fullWidth className="textField" type="text" name="tileWidth" value={ props.settings.tileWidth } onChange={ props.onChange } /></div>
        </div>
        <div className="set-holder">
          <div className="caption">Высота кнопки <span className="caption">[px, %, em, rem]</span></div>
          <div><TextField fullWidth className="textField" type="text" name="tileHeight" value={ props.settings.tileHeight } onChange={ props.onChange } /></div>
        </div>
        <div className="set-holder">
          <div className="caption">Фоновый цвет кнопки <span className="caption">[#hex, rgb(x,x,x), rgba(x,x,x,0-1) где x=0-1]</span></div>
          <div><TextField fullWidth className="textField" type="text" name="tileBackColor" value={ props.settings.tileBackColor } onChange={ props.onChange } /></div>
        </div>
        <div className="set-holder">
          <div className="caption">Цвет текста кнопки <span className="caption">[#hex, rgb(x,x,x), rgba(x,x,x,0-1) где x=0-1]</span></div>
          <div><TextField fullWidth className="textField" type="text" name="tileTextColor" value={ props.settings.tileTextColor } onChange={ props.onChange } /></div>
        </div>
        <div className="set-holder">
          <div className="caption">Размер текста кнопки <span className="caption">[px, pt, %, em, rem]</span></div>
          <div><TextField fullWidth className="textField" type="text" name="tileTextSize" value={ props.settings.tileTextSize } onChange={ props.onChange } /></div>
        </div>
        <div className="set-holder">
          <div className="caption">Радиус границ кнопки <span className="caption">[px, pt, %, em, rem]</span></div>
          <div><TextField fullWidth className="textField" type="text" name="tileBorderRadius" value={ props.settings.tileBorderRadius } onChange={ props.onChange } /></div>
        </div>
        <div className="set-holder">
          <div className="caption">Стиль границ кнопки <span className="caption">[none, dotted, dashed, solid]</span></div>
          <div><TextField fullWidth className="textField" type="text" name="tileBorderStyle" value={ props.settings.tileBorderStyle } onChange={ props.onChange } /></div>
        </div>
        <div className="set-holder">
          <div className="caption">Цвет границ кнопки<span className="caption">[#hex, rgb(x,x,x), rgba(x,x,x,0-1) где x=0-255]</span></div>
          <div><TextField fullWidth className="textField" type="text" name="tileBorderColor" value={ props.settings.tileBorderColor } onChange={ props.onChange } /></div>
        </div>
        <div className="set-holder">
          <div className="caption">Отступы внутри кнопки (padding) <span className="caption">[пара значений (верт и гор)]</span></div>
          <div><TextField fullWidth className="textField" type="text" name="tilePadding" value={ props.settings.tilePadding } onChange={ props.onChange } /></div>
        </div>
        <div className="set-holder">
          <div className="caption">Отступы снаружи кнопки (margin) <span className="caption">[пара значений (верт и гор)]</span></div>
          <div><TextField fullWidth className="textField" type="text" name="tileMargin" value={ props.settings.tileMargin } onChange={ props.onChange } /></div>
        </div>
        <div className="set-holder">
          <div className="caption">Кнопка отбрасывает тень <span className="caption">[да, нет]</span></div>
          <div><Switcher name="tileShadow" checked={ props.settings.tileShadow } onCheckboxChange={ props.onCheckboxChange } /></div>
        </div>
        <div className="set-holder">
        <p className="rowhead">Бегущая строка</p>
          <div className="caption">Текст бегущей строки</div>
          <div><TextField fullWidth className="textField" type="text"  max="1024" name="tickerText" value={ props.settings.tickerText } onChange={ props.onChange } /></div>
        </div>
        <div className="set-holder">
          <div className="caption">Размер бегущей строки <span className="hint caption">[целое число]</span></div>
          <div><TextField fullWidth className="textField" type="number" name="tickerSize" value={ props.settings.tickerSize } onChange={ props.onChange }/></div>
        </div>
        <div className="set-holder">
        <p className="rowhead">Шапка (статичное изображение)</p>
          
          <div className="set-holder">
          <div className="hor-set-holder">
            <p className="caption hint">Вкл/Выкл | Файл шапки-изображения | Высота шапки (пикс)</p>
          </div>
          <p className="caption">Шапка главного меню</p>
          <div className="hor-set-holder">
            <Switcher name="showMainMenuHeader" checked={ props.settings.showMainMenuHeader } onCheckboxChange={ props.onCheckboxChange } />
            <ImageSelect images={props.bgImages}  name="mainMenuHeaderImage" value={ props.settings.mainMenuHeaderImage } onChangeSelectedImage={ props.onChangeImage } />
            <TextField fullWidth className="textField" type="number" name="mainMenuBackgroundHeight" value={ props.settings.mainMenuBackgroundHeight } onChange={ props.onChange } />
          </div>
          <p className="caption">Шапка внутри меню</p>
          <div className="hor-set-holder">
            <Switcher name="showSubMenuHeader" checked={ props.settings.showSubMenuHeader } onCheckboxChange={ props.onCheckboxChange } />
            <ImageSelect images={props.bgImages} name="subMenuHeaderImage" value={ props.settings.subMenuHeaderImage } onChangeSelectedImage={ props.onChangeImage } />
            <TextField fullWidth className="textField" type="number" name="subMenuBackgroundHeight" value={ props.settings.subMenuBackgroundHeight } onChange={ props.onChange } />
          </div>
          <p className="caption">Шапка внутри документа</p>
          <div className="hor-set-holder">
            <Switcher name="showDocumentHeader" checked={ props.settings.showDocumentHeader } onCheckboxChange={ props.onCheckboxChange } />
            <BGSelect images={props.bgImages} name="documentHeaderImage" value={props.settings.documentHeaderImage } onChangeSelectedImage={ props.onChangeImage } />
            <TextField fullWidth className="textField" type="number" name="documentBackgroundHeight" value={ props.settings.documentBackgroundHeight } onChange={ props.onChange } width="20px;"/>
          </div>
          </div>
        </div>
      </div>
     </Fragment>
      );
              }

export default SettingsForm