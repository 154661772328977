import * as GroupTypes from './types';
import { Group } from '../../Interfaces';

const initialState: GroupTypes.INewGroupState = {}

export function newGroupReducer(state = initialState, action: GroupTypes.NewGroupActionsTypes): GroupTypes.INewGroupState {
  switch (action.type) {

    case GroupTypes.DROP_NEW_GROUP:
      return initialState;

    case GroupTypes.ADD_GROUP:
      let newG = Object.assign({}, state);
      newG.index = Number(action.payload.index);
      newG.direct = false;
      newG.title = 'Новая группа';
      newG.subtitle = '';
      newG.folder = '';
      newG.image = '';
      newG.duration = 1;
      newG.link = '';
      newG.file = '';
      newG.order = action.payload.order;
      return newG;

    case GroupTypes.CHANGE_NEW_GROUP_PROP:
        // сначала сливаем группу и редактируемое свойство в единый объект
        let changed: Group = {
          ...action.payload.newGroup,
          ...action.payload.prop
        }
        // затем возвращаем новый стейт, содержащий данную группу
        return changed;


    case GroupTypes.CHANGE_NEW_GROUP_LINK_PROP:
      // сначала сливаем группу и редактируемое свойство в единый объект
      let newGroupLinkProp = {...action.payload.newGroup, link: action.payload.link};

      // затем возвращаем новый стейт, содержащий данную группу
      return  Object.assign({}, state, newGroupLinkProp);

    default:
      return state;

  }
}