import { ActionCreator, AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import * as GroupTypes from './types';

export const dropNewGroupAction: ActionCreator<GroupTypes.IDropNewGroupAction> = () => {
  return {
    type: GroupTypes.DROP_NEW_GROUP
  }
}

type ThunkResult<R> = ThunkAction<R, GroupTypes.INewGroupState, undefined, AnyAction>;

export function addGroupActionA(index: number, order: number): ThunkResult<Promise<GroupTypes.INewGroupState>> {
  return async (dispatch, getState) => {
    dispatch({
      type: GroupTypes.ADD_GROUP,
      payload: {index, order}
    });
    return Promise.resolve(getState())
  };
}
 
 