import * as Types from '../../Interfaces';

export const INVITES_SUCCESS = 'INVITES_SUCCESS';
export const DELETE_INVITE = 'DELETE_INVITE';

export interface IGetInvitesAction {
    type: typeof INVITES_SUCCESS,
    payload: Types.Invite[];
}

export interface IDeleteInviteAction {
    type: typeof DELETE_INVITE,
    payload: {_id: string};
}

export type InvitesActionsTypes =
    | IGetInvitesAction
    | IDeleteInviteAction

export type IInvitesState = Types.Invite[];

