
import React from 'react';
import { NativeSelect } from '@material-ui/core';
import {Image, BgImage} from '../../../Interfaces';

interface IImageSelectProps<T>{
    images: T;
    value: string;
    name: string;
    onChangeSelectedImage(event: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode): void;
  }

export type ImageSelectI<T = Image[] | BgImage[]> = React.FC<IImageSelectProps<T>>

export const ImageSelect: ImageSelectI = (props) =>{
    return(
        <NativeSelect fullWidth style={ { minWidth: '150px', alignSelf: 'stretch' } } value={props.value} name={props.name} onChange={ props.onChangeSelectedImage}>
        <option value="" /> 
          { props.images &&                      
            props.images.map((img, i:number) => {
                return <option value={ img.fileName } key={ i } data-name={ img.fileName }>
                       { img.fileName }
                       </option>
            }) }
        </NativeSelect>
    );
}

// export default ImageSelect;
//  value={ element && element.image } 