import { ActionCreator } from 'redux';
import * as FileTypes from './types';
import * as Types from '../../Interfaces';

export const getFiles: ActionCreator<FileTypes.IGetFilesAction> = (files: Types.File[]) => {
  return {
    type: FileTypes.FILES_SUCCESS,
    payload: files
  }
}
 
export const selectPDFFileAction: ActionCreator<FileTypes.ISelectPDFFileAction> = (file: Types.File) => {
  return {
    type: FileTypes.SELECT_PDF_FILE,
    payload: file
  }
}

export const deleteSelectedPDFsAction: ActionCreator<FileTypes.IDeleteSelectedPDFsAction>= () => {
  return {
    type:  FileTypes.DELETE_SELECTED_PDFS,
  }
};
