import { IFoldersState,FoldersActionsTypes } from './types';
import * as FoldersTypes from './types';

const initialState: IFoldersState = [];

export function foldersReducer(state = initialState, action: FoldersActionsTypes): IFoldersState {
  switch (action.type) {
    case FoldersTypes.FOLDERS_SUCCESS:
      return [].concat(action.payload)
      .map((item: File) => ({ fileName: item.toString(), selected: false }));

    case FoldersTypes.DESELECT_FOLDER:
      return [...state].map(
        (folder) => {
          return {...folder, selected: false}
        });

    case FoldersTypes.SELECT_FOLDER:
      let folders =  [...state].map(
        (folder) => {
          return {...folder, selected: false}
        });
      folders = folders.map(folder => ({
        ...folder,
        selected: folder.selected ? (folder.fileName === action.payload.fileName ? !folder.selected : folder.selected)
          :
          folder.fileName === action.payload.fileName
      }))
      return Array.from(Object.keys(folders), k => folders[k]) 

      case FoldersTypes.DELETE_SELECTED_FOLDER:
         // вернем те папки из стэйта, которые не выбраны
      const foldersToDelete = state.filter(file => {
        return file.selected === false
      } )
      return Array.from(Object.keys(foldersToDelete), k => foldersToDelete[k]) ;

    default:
      return state;
  }
}