import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addUnionActionA } from '../store/newUnion/actions';
import { selectUnionAction } from '../store/selectedUnion/actions';
import { getUnions, changeNewUnionPropActionA, insertUnionBeforeSaveActionA } from '../store/unions/actions';
import { getGroups } from '../store/groups/actions';
import { getFiles } from '../store/files/actions';
import { getRelatedImages } from '../store/images/actions';
import { fetchFiles, fetchImages } from '../api';
import { Fade } from 'react-reveal';
import AppBar from './Elements/AppBar/AppBar';
import { TextField } from 'material-ui';
import Button from '@material-ui/core/Button';
import {ImageSelect} from './Elements/ImageSelect/ImageSelect';
import DocumentSelect from './Elements/DocumentSelect/DocumentSelect';
import { LinkIcon, HeadingIcon, SubHeadingIcon, PictureIcon, PDFIcon } from './Elements/SvgIcons/SvgIconsSet';
import AddElementIcon from '@material-ui/icons/NoteAddOutlined';
import imageIcon from '../img/picture.svg';
import pdfIcon from '../img/pdf.svg';

import {File, Image} from '../Interfaces';

interface IAddUnionProps {
  newUnion?: any;
  selectedGroup?: any;
  files: Array<File>;
  images: Array<Image>;
  onChangeNewUnionProp: (selected, field: any) => Promise<void>;
  onUpdateLink: (selectedGroup: any, value: string) => void;
  onDocumentUpload: () => void;
  onImageUpload: () => void;
  // onNewFolder: (newFolder: string) => void;
}


class AddUnion extends Component<IAddUnionProps>{

  componentDidMount = function() {
    let folder = 'nofolder';
    if (this.props.newGroup.hasOwnProperty('index')){
      console.log('new group....', this.props.newGroup.folder)
       folder = this.props.newGroup.folder;
    }
    else if (this.props.selectedGroup.hasOwnProperty('index')){
      // только в случае остутсвия новой группы
      folder = this.props.selectedGroup.folder;
    }
    
    let base = this.props.user.base;

    fetchFiles(folder || 'nofolder', base).then(result => this.props.onGetFilesNames(result));
    fetchImages(folder || 'nofolder', base).then(result => this.props.onGetRelatedImages(result));
  };

  onChange = (event: Event) => {
    const target = event.currentTarget as HTMLInputElement;
    this.commonChangeNewUnionProp(target.name, target.value);
  }

  commonChangeNewUnionProp = (name: string, value: string) =>{
    let field = [];
    if(name === 'folder'){
      field[name] = value.replace(/ /g,"_");
    }
    else
      field[name] = value;
      this.props.onChangeNewUnionProp(this.props.newUnion, field)
      .then(() =>{
        if (name === 'file')
          this.props.onUpdateLink(this.props.selectedGroup.folder, value)
      })
  }

  changeSelectedDocumentHandler =(event: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode) =>{
    const target = event.currentTarget as HTMLSelectElement;
    this.commonChangeNewUnionProp(target.name, target.value);
  }

  changeSelectedImageHandler =(event: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode) =>{
    const target = event.currentTarget as HTMLSelectElement;
    this.commonChangeNewUnionProp(target.name, target.value);
  }

  styles = {
    title: {
        cursor: 'pointer',
        textTransform: 'uppercase'
    },
};
  
  render() {
    const union = this.props.newUnion;
    return (
          <Fade> 
            <div className="contentWrapper">
            <style>
              {
                `
                .hidden {
                  display: none;
              }
                `
              }
            </style>
            <AppBar className="ElementAppBar" >
              <AddElementIcon/><span>Новый пункт '{ this.props.newUnion.index }'</span>
            </AppBar>
            <div className="rowContainer">
                  <span className="caption disabled">Порядковый номер</span>
                   <TextField fullWidth disabled className="textField" name="order" value={ union ? union.order : '' }  />
                </div>
            <div className="rowContainer">
              <span className="caption"><HeadingIcon/>Заголовок пункта меню</span>
              <TextField fullWidth className="textField" type="text"  name="title" value={ union ? union.title : '' } onChange={ this.onChange } />
            </div>
            <div className="rowContainer">
              <span className="caption"><SubHeadingIcon/>Подзаголовок пункта меню</span>
              <TextField fullWidth className="textField" type="text" floatingLabelText="Добавьте подзаголовок" name="subtitle" value={ union ? union.subtitle : '' } onChange={ this.onChange } />
            </div>
            <div className="rowContainer">
            <span className="caption"><PictureIcon/>Изображение пункта</span>
            <div className="hor-set-holder">
            <ImageSelect images={this.props.images} value={union.image} name="image" onChangeSelectedImage={this.changeSelectedImageHandler}/>
              <Button style={{margin: '10px'}}  component="label"  variant="outlined" color="primary" >
              <img src={ imageIcon } className="imgInButton" alt="" width="24px"/>
              <span>Загрузить</span>
                  <input id="newUnion" type="file" accept="image/*, application/images" style={{display: "none"}} onChange={this.props.onImageUpload} /> 
                </Button>
                </div>
              </div>
            
            <AppBar className="ElementAppBar">
						<span>
							Контент
						</span>
					</AppBar>
					<div className="content">
            <div className="rowContainer">
              <span className="caption"><PDFIcon/>Целевой документ:</span>
                <div className="hor-set-holder">
                <DocumentSelect files={this.props.files} value={union.file} name="file" onChangeSelectedDocument={this.changeSelectedDocumentHandler} />
                  <Button style={{margin: '10px'}} component="label" variant="outlined" color="primary" >
                  <img src={ pdfIcon } className="imgInButton" alt="pdf" width="24px"/>
                  <span>Загрузить</span>
                  <input id="newUnion" type="file" accept=".pdf, application/pdf" style={{display: "none"}} onChange={this.props.onDocumentUpload} /> 
                  </Button>
                </div>
            </div>
            <div className="rowContainer">
             <span className="caption"><LinkIcon />Ссылка на целевой документ в облаке (для QR-кода)</span>
              <TextField fullWidth className="textField" type="text" name="link" value={ union ? union.link : '' }  onChange={ this.onChange }
            />
            </div>
          </div>
        </div>
          </Fade>
      );
  }
}
;

const mapStateToProps = (state, ownProps) => {
  return {
    unions: state.unions,
    groups: state.groups,
    newUnion: state.newUnion,
    user: state.auth.user,
    images: state.images,
    selectedGroup: state.selectedGroup,
    newGroup: state.newGroup,
    files: state.files
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onAddUnion: (index, parent, order)=> dispatch(addUnionActionA(Number(index), Number(parent), Number(order))),
    onGetUnionList: items => dispatch(getUnions(items)),
    onGetGroupList: items => dispatch(getGroups(items)),
    onChangeNewUnionProp: (selected, field) => dispatch(changeNewUnionPropActionA(selected, field)),
    onGetFilesNames: items => dispatch(getFiles(items)),
    onGetRelatedImages: items => dispatch(getRelatedImages(items)),
    onInsertUnionBeforeSave: newUnion=>dispatch(insertUnionBeforeSaveActionA(newUnion)),
    onSelectUnion: (union) =>dispatch(selectUnionAction(union))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUnion);