import { ISettingsState,SettingsActionsTypes } from './types';
import * as SettingsTypes from './types';

const initialState = {
    "mainScreenStrategy":1,
    "groupsTimeout": 2,
    "documentsTimeout": 2,
    "showMainMenuHeader": true,
    "showSubMenuHeader": true,
    "showDocumentHeader": true,
    "mainMenuHeaderImage": "Основная_заставка.jpg",
    "subMenuHeaderImage": "Основная_заставка.jpg",
    "documentHeaderImage": "Основная_заставка.jpg",
    "mainMenuBackgroundHeight": "750",
    "subMenuBackgroundHeight": "750",
    "documentBackgroundHeight": "750",
    "backgroundColor": "rgb(255,255,255)",
    "backgroundImage": "",
    "tileHeight": "160px",
    "tileWidth": "95%",
    "tileBackColor": "#2798e8",
    "tileTextColor": "#fff",
    "tileTextSize": "2rem",
    "tileBorderRadius": "8px",
    "tileBorderStyle": "solid",
    "tileBorderColor": "#fff",
    "tilePadding": "1rem 0rem",
    "tileMargin": "0.5rem auto",
    "tileShadow": false,
    "tileOpacity": "1",
    "tickerSize": 3,
    "tickerText": "Подписывайтесь на наш Telegram-канал @ и будьте в курсе последних изменений в расписании!",
};

export function settingsReducer(state = initialState, action: SettingsActionsTypes): ISettingsState {
  switch (action.type) {
    case SettingsTypes.SETTINGS_SUCCESS:
      return {...action.payload};

    case SettingsTypes.CHANGE_SETTINGS_PROP:
      console.log(action.payload)
      return Object.assign({}, state, action.payload);

    default:
      return state;
  }
}