import { ActionCreator  } from 'redux';
import * as LastActionTypes from './types';
import * as Types from '../../Interfaces';

export const fireAction: ActionCreator<LastActionTypes.ILastActionFiredAction> =(lastAction: Types.LastAction, history: any) =>{
  return {
    type: LastActionTypes.LAST_ACTION_FIRED,
    payload: {lastAction: lastAction, history: history}
  }
};
 