export enum FolderType {
    pdf   = 'pdf',
    image = 'images',
    video = 'video',
    bg    = 'bg'
}

export type File = {
    fileName: string,
    selected: boolean
}

export type Image = {
    fileName: string,
    selected: boolean
}

export type BgImage = {
    fileName: string,
    selected: boolean
}

export type Group = {
    index?: number,
    direct?: boolean,
    title?: string,
    subtitle?: string,
    folder?: string,
    image?: string,
    duration?: number,
    link?: string,
    file?: string,
    order?: number
}

export type Union = {
    parent?: number,
    index?: number,
    title?: string,
    subtitle?: string,
    file?: string,
    link?: string,
    image?: string,
    duration?: number,
    order?: number
}

export type Company = {
    regDate?: number,
    tlgrmEnabled?: boolean,
    tlgrmToken?: string,
    tlgrmChannel?: string,
    _id?: string,
    base?: string
}

export type TlgrmData = {
    tlgrmEnabled?: boolean,
    tlgrmToken?: string,
    tlgrmChannel?: string
}

export type User = {
    _id: string,
    super: boolean,
    verified: boolean,
    email?: string,
    base: string,
    verifiedTimeStamp: number,
    timeStamp: number,
    lastLogin: number,
    isAuthenticated: boolean
}

export type Auth = {
    isFetching: boolean,
    isAuthenticated: boolean,
    errorMessage?: string,
    user?: User
}

export type Account = {
    changes: {
        isAboutToChange: boolean
    },
    base?: string, //non exists for super account
    verified: boolean,
    timeStamp: number,
    verifiedTimeStamp?: number,
    super: boolean,
    _id: string,
    email: string,
    lastLogin: number
}

export type Invite = {
    date: string,
    _id: string,
    inviteOwner: string,
    ownerEmail?: string,
    hash: string
}

export type LastAction = {
    actionType?: string,
    actionObject?: string,
    actionId?: number
}

export type DeleteFetchResult = {
    success: boolean;
    deleted?: Array<string>;
    error? : string;
}

export type UploadFetchResult = {
    success: boolean;
    fileName?: string;
    error? : string;
}

export type SaveDataFetchResult = {
    success: boolean;
    error?: string;
}

export type EmailConfirmationFetchResult = {
    success: boolean;
    message?: string;
}

export type NewFolderFetchResult = {
    success: boolean;
    message?: string;
    error? : string;
}

export type SettingsForm = {
    // bgImages: Array<FileType>
    mainScreenStrategy: number,
    groupsTimeout: number,
    documentsTimeout: number,
    showMainMenuHeader: boolean,
    showSubMenuHeader: boolean,
    showDocumentHeader: boolean,
    mainMenuHeaderImage: string,
    subMenuHeaderImage: string,
    documentHeaderImage: string,
    mainMenuBackgroundHeight: string,
    subMenuBackgroundHeight: string,
    documentBackgroundHeight: string,
    backgroundColor: string,
    backgroundImage: string,
    tileHeight: string,
    tileWidth: string,
    tileBackColor: string,
    tileTextColor: string,
    tileTextSize: string,
    tileBorderRadius: string,
    tileBorderStyle: string,
    tileBorderColor: string,
    tilePadding: string,
    tileMargin: string,
    tileShadow: boolean,
    tileOpacity?: string,
    tickerSize: number,
    tickerText: string
}

export type ActionType = 'show' | 'add' | 'select' | 'del';
export type ActionObject = 'tlgrmSettings' | 'driveOperate' | 'settings' | 'union' | 'group';

// export type bgImages = string,