import { log } from '../../api';
import * as UnionTypes from './types';
import { Union } from '../../Interfaces';

const initialState:  UnionTypes.IUnionsState = []

export function unionReducer(state = initialState, action:UnionTypes.UnionsActionsTypes): UnionTypes.IUnionsState {
  switch (action.type) {
    // case 'LOGOUT_SUCCESS':
    //   return initialState;
    case UnionTypes.UNION_LIST_SUCCESS:
      // make new object from state and payload. No mutations here
      let newStateObject = Object.assign({}, state, action.payload);
      log('unions loading done');
      // convert the shape of new state to array, so i can iterate it later in component.
      return Array.from(Object.keys(newStateObject), k => newStateObject[k]) 

    case UnionTypes.DELETE_UNION:
      const item = action.payload;
      const filtered = [...state];

      // groupUnions is the unions of one parent
      const groupUnions = [...state].filter(el => { return el.parent === item.parent })

      // this mutate state, but we want it
      groupUnions.map(el => {
        // для элементов, находящихся ниже удаляемого, уменьшаем порядок на единицу
        if (el.order > item.order) {
          el.order = el.order - 1;
        }
        return el;
      });

      return filtered.filter(el => {
          return el.index !== item.index
        })

    case UnionTypes.DELETE_ALL_GROUP_UNIONS:
      console.log('DELETE_ALL_GROUP_UNIONS: ', action)
      return [...state].filter(union => union.parent !== action.payload) 

    case UnionTypes.INSERT_UNION_BEFORE_SAVE:
      const newState = [
        ...state,
        action.payload
      ];

      return newState

    // событие происходит когда выбранная подгруппа редактируется
    case UnionTypes.CHANGE_SELECTED_UNION_PROP:
      // сначала сливаем группу и редактируемое свойство в единый объект
      let changedSelectedUnion: Union = {
        ...action.payload.selectedUnion,
        ...action.payload.prop
      }
      console.log(action.payload)
      // затем возвращаем новый стейт, содержащий данную группу
      return [].concat(state).map(item => {
          // здесь заменяем элемент отредактированным объектом
          return item.index === changedSelectedUnion.index ? changedSelectedUnion : item
        })

    // событие происходит когда выбранная подгруппа редактируется
    case UnionTypes.CHANGE_SELECTED_UNION_LINK_PROP:
      // сначала сливаем группу и редактируемое свойство в единый объект
      let changedSelectedUnionLinkProp: Union = {
        ...action.payload.selectedUnion,
        link: action.payload.link
      }
      // затем возвращаем новый стейт, содержащий данную группу
      return [].concat(state).map(item => {
          // здесь заменяем элемент отредактированным объектом
          return item.index === changedSelectedUnionLinkProp.index ? changedSelectedUnionLinkProp : item
        })

    // событие происходит когда выбранная подгруппа редактируется
    case UnionTypes.CHANGE_NEW_UNION_PROP:
      // сначала сливаем группу и редактируемое свойство в единый объект
      let changedNewUnion: Union = {...action.payload.newUnion, ...action.payload.prop}
      
      console.log(changedNewUnion)
      // затем возвращаем новый стейт, содержащий данную группу
      return [].concat(state).map(item => {
          // здесь заменяем элемент отредактированным объектом
          return item.index === changedNewUnion.index ? changedNewUnion : item
        })

    // событие происходит когда выбранная подгруппа редактируется
    case UnionTypes.CHANGE_NEW_UNION_LINK_PROP:
      // сначала сливаем группу и редактируемое свойство в единый объект
      let changedNewUnionLinkProp: Union = {
        ...action.payload.newUnion,
        link: action.payload.link
      }
      // затем возвращаем новый стейт, содержащий данную группу
      return [].concat(state).map(item => {
          // здесь заменяем элемент отредактированным объектом
          return item.index === changedNewUnionLinkProp.index ? changedNewUnionLinkProp : item
        })

    case UnionTypes.ORDER_UP_UNION:
      // применяем группировку только если элементов два и более
      if (state.length > 1) {
        const state_copy_up = [].concat(state);
        // находим текущий (выбранный) объект списка

        const selected = state_copy_up.filter(item => {
          return item.index === action.payload
        })[0];

        console.log(selected)

        const thisGroup = state_copy_up.filter(item => {
          return item.parent === selected.parent
        });

        const prev = thisGroup.filter(item => {
          return item.order === selected.order - 1
        })[0];

        // console.log(prev)

        // если не достигли начало списка
        if (prev !== undefined) {
          // меняем у предыдущего значение order
          const mem = prev.order;
          prev.order = selected.order;
          selected.order = mem;
          state_copy_up.sort((a, b) => a.order - b.order);
        }
        //return new (not mutable) array
        return [...state_copy_up];
      } else
        return state;

    case UnionTypes.ORDER_DN_UNION:
      // применяем группировку только если элементов два и более
      if (state.length > 1) {
        const state_copy_dn = [].concat(state);
        // находим текущий (выбранный) объект списка
        const sel = state_copy_dn.filter(item => {
          return item.index === action.payload
        })[0];

        const thisGroup = state_copy_dn.filter(item => {
          return item.parent === sel.parent
        });

        const next = thisGroup.filter(item => {
          return item.order === sel.order + 1
        })[0];

        // console.log(next)

        // если не достигли начало списка
        if (next !== undefined) {
          // меняем у предыдущего значение order 
          const memo = next.order;
          next.order = sel.order;
          sel.order = memo;
          state_copy_dn.sort((a, b) => a.order - b.order);
        }
        //return new (not mutable) array
        return [...state_copy_dn] ;
      } else return state;


    default:
      return state;

  }
}