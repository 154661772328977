import React from 'react';
import './AppBar.css'

const AppBar = ({className, children}) =>{
    return (
      <div className={className}>{children}</div>
    );
  }
  

  export default AppBar;