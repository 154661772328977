import React from 'react';
import './SideBar.css';

const SideBar=({children})=>{
    return(
      <div className="sideBar">
        {children}
      </div>
    );
  }

  export default SideBar;