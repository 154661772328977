import { ActionCreator, AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import * as UnionTypes from './types';

export const dropNewUnionAction: ActionCreator<UnionTypes.IDropNewUnionAction> = () => {
  return {
    type: UnionTypes.DROP_NEW_UNION
  }
}

type ThunkResult<R> = ThunkAction<R, UnionTypes.INewUnionState, undefined, AnyAction>;

export function addUnionActionA(index: number, parent: number, order: number): ThunkResult<Promise<UnionTypes.INewUnionState>> {
  return async (dispatch, getState) => {
    dispatch({
      type: UnionTypes.ADD_UNION,
      payload: {index, parent, order}
    });
    return Promise.resolve(getState())
  };
}
 
 