import { ActionCreator, AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import * as UnionTypes from './types';
import * as Types from '../../Interfaces';

export const getUnions: ActionCreator<UnionTypes.IGetUnionListAction> = (unions: Types.Union[]) => {
  return {
    type: UnionTypes.UNION_LIST_SUCCESS,
    payload: unions
  }
}

type ThunkResult<R> = ThunkAction<R, UnionTypes.IUnionsState, undefined, AnyAction>;

// Async Redux-Thunk action
export function orderUpUnionActionA(selectedIndex: number): ThunkResult<Promise<UnionTypes.IUnionsState>> {
  return async (dispatch, getState) => {
    dispatch({
      type: UnionTypes.ORDER_UP_UNION,
      payload: selectedIndex
    });
    return Promise.resolve(getState())
  };
}

export function orderDnUnionActionA(selectedIndex: number): ThunkResult<Promise<UnionTypes.IUnionsState>> {
  return async (dispatch, getState) => {
    dispatch({
      type: UnionTypes.ORDER_DN_UNION,
      payload: selectedIndex
    });
    return Promise.resolve(getState())
  };
}

// возможно нужно вернуть ():IUnionsState
export const deleteUnionAction: ActionCreator<UnionTypes.IDeleteUnionAction> = (union: Types.Union) => {
  return {
    type: UnionTypes.DELETE_UNION,
    payload: union
  }
};

export function changeNewUnionPropActionA(newUnion: Types.Union, prop: any): ThunkResult<Promise<UnionTypes.IUnionsState>> {
  return async (dispatch, getState) => {
    dispatch({
      type: UnionTypes.CHANGE_NEW_UNION_PROP,
      payload: {newUnion, prop}
    })
    return Promise.resolve(getState())
  };
}

export function changeSelectedUnionPropActionA(selectedUnion: Types.Union, prop: any): ThunkResult<Promise<UnionTypes.IUnionsState>> {
  return async (dispatch, getState) => {
    dispatch({
      type: UnionTypes.CHANGE_SELECTED_UNION_PROP,
      payload: {selectedUnion, prop}
    })
    return Promise.resolve(getState())
  };
}

export function changeNewUnionLinkPropActionA(newUnion: Types.Union, link: string): ThunkResult<Promise<UnionTypes.IUnionsState>> {
  return async (dispatch, getState) => {
    dispatch({
      type: UnionTypes.CHANGE_NEW_UNION_LINK_PROP,
      payload: { newUnion, link }
    })
    return Promise.resolve(getState())
  };
}

export function insertUnionBeforeSaveActionA(newUnion: Types.Union): ThunkResult<Promise<UnionTypes.IUnionsState>> {
  return async (dispatch, getState) => {
    dispatch({
      type: UnionTypes.INSERT_UNION_BEFORE_SAVE,
      payload: newUnion
    })
    return Promise.resolve(getState())
  };
}

export function changeSelectedUnionLinkPropActionA(selectedUnion: Types.Union, link: string): ThunkResult<Promise<UnionTypes.IUnionsState>> {
  return async (dispatch, getState) => {
    dispatch({
      type: UnionTypes.CHANGE_SELECTED_UNION_LINK_PROP,
      payload: { selectedUnion, link }
    })
    return Promise.resolve(getState())
  };
}

export const deleteAllGroupUnionsActionA = (parent: number): ThunkResult<Promise<UnionTypes.IUnionsState>> =>{
  return async (dispatch, getState) => {
    dispatch({
      type: 'DELETE_ALL_GROUP_UNIONS',
      payload: parent
    });
    return Promise.resolve(getState())
  };
}