import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {Dispatch} from 'redux';
import {withRouter} from 'react-router';
import { getAccounts } from '../store/accounts/actions';
import { userLogoutAction } from '../store/auth/actions';
import {fetchAccounts, sendDeleteAccountRequest, removeToken} from '../api';
import Wrapper from './Wrapper/Wrapper';
import {User, Auth, Account} from '../Interfaces';
import * as H from 'history';

interface IProps extends IStateProps, IDispatchProps {
  user: User;
  auth: Auth;
  history: H.History;
}

class ShowRestrictedAccounts extends Component<IProps> {

  componentDidMount =()=>{
    fetchAccounts().then(result => this.props.onGetAccountsList(result));
  }

  onExit = () => {
    removeToken();
    this.props.onUserLogout();
    this.props.history.push('/')
  }

  handleDelete =(_id: string)=>{
    // console.log(_id);
    sendDeleteAccountRequest(_id).then(response=>{
        if (response)
        {
            if (response.success){
                window.alert('Успешно')
            }else window.alert(response.message)
        }
    })
  }

  render() {
    return (
      <Fragment>
        { this.props.user &&
        <Wrapper stepBack={true} email={ this.props.user.email } onExit={ this.onExit }>
          {this.props.auth.isAuthenticated && this.props.user.super &&
          <div className="wrapper">
          <h2>Список аккаунтов</h2>
          <ul>
          <li> ---------------_id-------------- - e-mail пользователя ------ база------ - ФИО - Время регистрации - Время подтверждения - Последний вход - <span className="actionLink"  >Удалить</span></li>
              {this.props.accounts
              .map((item, i)=>{
              return (<li className={item.verified ? 'verified' : 'not-verified'} key={i}>{item._id} - {item.email} - {item.base || 'nobase'} - {item.timeStamp} - {item.verifiedTimeStamp|| ''} - {item.lastLogin} - <span className="actionLink" onClick={ ()=>this.handleDelete(item._id) }>Удалить</span></li>)
                })}
          </ul>
          </div>}
        </Wrapper>}
      </Fragment>
      );
  }
}

interface IStateProps {
  user: User;
  accounts: Account[];
  auth: Auth;
}

const mapStateToProps = (state: any):IStateProps => {
  return {
    user: state.auth.user,
    accounts: state.accounts,
    auth: state.auth
  }
};

interface IDispatchProps {
  onGetAccountsList: typeof getAccounts;
  onUserLogout: typeof userLogoutAction;
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return {
    onGetAccountsList: (items) => dispatch(getAccounts(items)),
    onUserLogout: () => dispatch(userLogoutAction()),
  }
};

export default  withRouter(connect(mapStateToProps, mapDispatchToProps)(ShowRestrictedAccounts));