import { ActionCreator } from 'redux';
import * as FolderTypes from './types';
import * as Types from '../../Interfaces';

export const getFolders: ActionCreator<FolderTypes.IGetFoldersAction> = (folders: Types.File[]) => {
  return {
    type: FolderTypes.FOLDERS_SUCCESS,
    payload: folders
  }
}
 
export const selectFolderAction: ActionCreator<FolderTypes.ISelectFolderAction> = (folder: Types.File) => {
  return {
    type: FolderTypes.SELECT_FOLDER,
    payload: folder
  }
}

export const deselectFolderAction: ActionCreator<FolderTypes.IDeselectFolderAction> = () => {
  return {
    type: FolderTypes.DESELECT_FOLDER
  }
}

export const deleteSelectedFolderAction: ActionCreator<FolderTypes.IDeleteSelectedFolderAction>= () => {
  return {
    type: FolderTypes.DELETE_SELECTED_FOLDER,
  }
};