import * as Types from '../../Interfaces';
 
export const LAST_ACTION_FIRED = 'LAST_ACTION_FIRED';

export interface ILastActionFiredAction {
    type: typeof LAST_ACTION_FIRED,
    payload: {lastAction: Types.LastAction, history?: any}
}

export type LastActionsTypes =
    | ILastActionFiredAction


export type ILastActionState = Types.LastAction
