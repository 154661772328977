import * as Types from '../../Interfaces';
export const SELECT_GROUP = 'SELECT_GROUP';
export const CHANGE_SELECTED_GROUP_PROP = 'CHANGE_SELECTED_GROUP_PROP';
export const CHANGE_SELECTED_GROUP_LINK_PROP = 'CHANGE_SELECTED_GROUP_LINK_PROP';
export const SELECT_GROUP_BY_INDEX = 'SELECT_GROUP_BY_INDEX';
export const ORDER_UP_GROUP = 'ORDER_UP_GROUP';
export const ORDER_DN_GROUP = 'ORDER_DN_GROUP';

export interface ISelectGroupAction {
    type: typeof SELECT_GROUP,
    payload:  Types.Group;
} 

export interface ISelectGroupByIndexAction {
    type: typeof SELECT_GROUP_BY_INDEX,
    payload:  {groups: Types.Group[], index: number}
} 

export interface IChangeSelectedGroupPropAction {
    type: typeof CHANGE_SELECTED_GROUP_PROP,
    payload:  { selectedGroup: Types.Group, prop: any };
} 

export interface IChangeSelectedGroupLinkPropAction {
    type: typeof CHANGE_SELECTED_GROUP_LINK_PROP,
    payload:  { selectedGroup: Types.Group, link: string };
} 

export interface IOrderUpGroupAction {
    type: typeof ORDER_UP_GROUP,
    payload: number
}

export interface IOrderDnGroupAction {
    type: typeof ORDER_DN_GROUP,
    payload: number
}

export type SelectedGroupActionsTypes =
    | ISelectGroupAction
    | ISelectGroupByIndexAction
    | IChangeSelectedGroupPropAction
    | IChangeSelectedGroupLinkPropAction

export type ISelectedGroupState = Types.Group

