
import React from 'react';
import SideMenuItem from './SideMenuItem';
import SideMenuFixedItem from './SideMenuFixedItem';
import './SideMenu.css';
import * as Types from '../../Interfaces';

interface IProps {
  expandable: boolean;
  mainList, subList, data?: any;
  title: string;
  actionObject?: Types.ActionObject;
  actionType?: Types.ActionType;
  handleSubItemClick(item: any): void;
  handleMainItemClick(item: any): void;
  mainItemSelectedIndex: number;
  subItemSelectedIndex: number;
}

const SideMenu:React.FC<IProps> = ({...props}) =>{
      return (
        <div  className="menu-wrapper" >
          <ul className="menu-list">
                <li className="menu-list__item" >
                {props.expandable?
                    <SideMenuItem  mainList={props.mainList} title={props.title} subList={props.subList} 
                      handleSubItemClick={props.handleSubItemClick} handleMainItemClick={props.handleMainItemClick} 
                      mainItemSelectedIndex={props.mainItemSelectedIndex} subItemSelectedIndex={props.subItemSelectedIndex}/>
                  : <SideMenuFixedItem  item={props.data} title={props.title} actionType={props.actionType} />
                }
                </li>
          </ul>
        </div>
      )
  }

  export default SideMenu;