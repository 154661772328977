import React from 'react';
import { connect } from 'react-redux';
import { fireAction } from '../../store/lastAction/actions';
import './SideMenu.css';
// import SideMenu from './SideMenu';
import * as Types from '../../Interfaces';
import {Dispatch} from 'redux';

interface IRowProps {
  children: string;
  className?: string;
  onClick(item: any): void;
}

const Row: React.FC<IRowProps> =({children, className, onClick})=>{
  return(
    <p className={className} onClick={ () => onClick }>{children}</p>
  )
}

interface IProps extends IStateProps, IDispatchProps{
  item: any;
  title: string;
  actionObject?: Types.ActionObject;
  actionType: Types.ActionType;
}

class SideMenuFixedItem extends React.Component<IProps> {
  constructor(props:IProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = (item) => {
     this.props.onActionFired({actionType: 'select', actionObject: this.props.actionObject, actionId: null})
  }

  render() {
    const {props: {title} } = this

    return (
      <div className={ `menu-item` }>
        <div className="menu-item__line" >
          <h3 className="menu-item__title">
          <Row onClick={ this.handleClick }>
                    { title }
                  </Row>
                  </h3>
        </div>
      </div>
    )
  }
}

interface IStateProps {
  groups: Array<Types.Group>
}

const mapStateToProps = (state: IStateProps) => {
  return {
    groups: state.groups,
  }
};

interface IDispatchProps {
  onActionFired: typeof fireAction;
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return {
    // onSelectGroup: group => dispatch(selectGroupAction(group)),
    // onOrderUpGroup: () => dispatch(orderUpGroupAction()),
    // onOrderDnGroup: () => dispatch(orderDnGroupAction()),
    // onDeleteGroup: (id)=> dispatch(deleteGroupAction(id)),
    onActionFired: (act)=>dispatch(fireAction(act))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenuFixedItem);