import React, { useState } from 'react';
import './PalleteDropDown.css'

interface IPalletteDropDown {
  children?: any;
  onClick?: () => void;
  name?: string;
  className: string;
  dataHint?: string;
  caption?: string;
  linkTo?: string;
  icon: React.ReactElement;
}

const PalleteDropDown = ({children, onClick, className, dataHint, icon}: IPalletteDropDown) => {
    const [isOpen, toggleOpen] = useState(false);
    return (
      <div className={ `${className}`} onClick={ ()=> toggleOpen(!isOpen) } data-hint={ dataHint }>
       {icon}<div className={`ddown-list ${isOpen? 'opened': ''}`}>{ children }</div> 
      </div>
    )
  }

  export default PalleteDropDown;