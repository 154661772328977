import {IFilesState, FilesActionsTypes} from './types';
import * as FilesTypes from './types';
import { File } from '../../Interfaces';

const initialState : IFilesState = []

export function filesReducer(state = initialState, action : FilesActionsTypes) : IFilesState {
  switch(action.type) {
    case FilesTypes.FILES_SUCCESS:
      return [].concat(action.payload)
      .map((item: File) => ({ fileName: item.toString(), selected: false }));

    case FilesTypes.SELECT_PDF_FILE:
      const files = state.map((file: File) => ({
        ...file,
        selected: file.selected
          ? (file.fileName === action.payload.fileName
            ? !file.selected
            : file.selected)
          : file.fileName === action.payload.fileName
      }));
      return Array.from(Object.keys(files), k => files[k])

    case FilesTypes.DELETE_SELECTED_PDFS:
      // вернем те файлы из стэйта, которые не выбраны
      const filesToDelete = state.filter((file: File) => {
        return file.selected === false
      });
      return Array.from(Object.keys(filesToDelete), k => filesToDelete[k]);

    default:
      return state;

  }
}