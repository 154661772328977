// import Action from 'redux';

import * as Types from '../../Interfaces';
 
export const COMPANY_SUCCESS = 'COMPANY_SUCCESS';
export const CHANGE_COMPANY_PROP = 'CHANGE_COMPANY_PROP';

export interface IGetCompanyAction {
    type: typeof COMPANY_SUCCESS,
    payload: Types.Company
}

export interface IChangeCompanyPropAction {
    type: typeof CHANGE_COMPANY_PROP,
    payload:  { prop: any };
}

export type CompanyActionsTypes =
    | IGetCompanyAction
    | IChangeCompanyPropAction

export type ICompanyState = Types.Company
