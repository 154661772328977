import React from 'react';
import menuLineIcon from '../../img/menuline.gif';
import ElementIcon from '@material-ui/icons/DescriptionOutlined';
import {GroupIcon, EditGroupIcon, EditIcon} from '../Elements/SvgIcons/SvgIconsSet';
import { getShortFileName2 } from '../../api';
import './SideMenu.css';
import * as Types from '../../Interfaces';


const Row = ({item, children, onClick, mainItemSelectedIndex}) => {
  return (
    <ul className="menu-item__paragraph" >
      <p className={ item.index === mainItemSelectedIndex? 'main-item selected': 'main-item'} onClick={ () => onClick(item) }>
      {item.index === mainItemSelectedIndex ? <EditGroupIcon />: <GroupIcon />} { item.title }
      </p>
      { children }
    </ul>
  )
}

const Sub = ({subList, parentIndex, onClick, subItemSelectedIndex}) => {
  return (subList
    .filter(subItem => {
      return subItem.parent === parentIndex
    })
    .map(child => {
      return (<li key={child.index} className={ child.index === subItemSelectedIndex? 'sub-item selected': 'sub-item'} onClick={ () => onClick(child) }>
               <img src={menuLineIcon} alt="-"></img>{child.index === subItemSelectedIndex? <EditIcon /> : <ElementIcon/>} { child.title.length > 37 ? getShortFileName2(child.title) : child.title} 
              </li>)
              // align="absmiddle"
    })
  )
}

interface IProps {
  mainList : Array<Types.Group>;
  subList: Array<any>;
  title: string;
  handleMainItemClick(item: any): void;
  handleSubItemClick(item: any):void;
  mainItemSelectedIndex: number;
  subItemSelectedIndex: number;
}

interface IState {
  opened: boolean, over: boolean;
}

class SideMenuItem extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      opened: false,
      over: false
    }
  }

  handleSwitch = () => {
    this.setState({
      opened: !this.state.opened
    })
  }

  render() {
    const {props: {mainList, title, subList, handleMainItemClick, handleSubItemClick, mainItemSelectedIndex, subItemSelectedIndex}} = this

    return (
      <div className={ `menu-item--opened` }>
        <div className="menu-item__line" onClick={ this.handleSwitch }>
          <h3 className="menu-item__title">
                          { title }
                        </h3>
        </div>
        <div className="menu-item__inner">
          <div className="menu-item__content">
            { mainList.map((item, i) => {
                return (
                  <Row key={ i } item={ item } onClick={ handleMainItemClick } mainItemSelectedIndex={mainItemSelectedIndex}>
                    {subList && <Sub subList={ subList } parentIndex={ item.index } onClick={ handleSubItemClick } subItemSelectedIndex={subItemSelectedIndex}/> }
                  </Row>)
              }) }
          </div>
        </div>
      </div>
    )
  }
}

export default SideMenuItem;