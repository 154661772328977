import * as UnionTypes from './types';
import { Union } from '../../Interfaces';

const initialState: UnionTypes.ISelectedUnionState = {}

export function selectedUnionReducer(state = initialState, action: UnionTypes.SelectedUnionActionsTypes): UnionTypes.ISelectedUnionState {
  switch (action.type) {
    // case 'LOGOUT_SUCCESS':
    //   return initialState;
    case UnionTypes.SELECT_UNION:
      if (action.payload === null)
        return {}
      else
        return {
          ...state,
          ...action.payload,
        }
    case UnionTypes.CHANGE_SELECTED_UNION_PROP:
      // сначала сливаем группу и редактируемое свойство в единый объект
      let changed: Union = {
        ...action.payload.selectedUnion,
        ...action.payload.prop
      }

      // затем возвращаем новый стейт, содержащий данную группу
      return changed;

    case UnionTypes.CHANGE_SELECTED_UNION_LINK_PROP:
      // сначала сливаем группу и редактируемое свойство в единый объект
      let changedSelectedUnionWithLinkProp: Union = {...action.payload.selectedUnion, link: action.payload.link}
      // затем возвращаем новый стейт, содержащий данную группу
      return changedSelectedUnionWithLinkProp;

    default:
      return state;

  }
}