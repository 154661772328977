import React from 'react';
import './RegisterForm.css'
import { Link } from 'react-router-dom';

const RegisterForm = ({onSubmitForm}) => {
   const onSubmit = (e) => {
        onSubmitForm(e);
    }
    return (
        <div className="formWrapper">
          <div className="login">
            <div className="heading">
              <h2>РЕГИСТРАЦИЯ</h2>
              <form onSubmit={ onSubmit }>
                <div className="input-group">
                  <span className="input-group-addon">Ваш email</span>
                  <input type="email" className="form-control"  name="email" required></input>
                </div>
                <div className="input-group">
                  <span className="input-group-addon">Пароль</span>
                  <input type="password" className="form-control" name="password" required></input>
                </div>
                <div className="input-group">
                  <span className="input-group-addon">Код доступа</span>
                  <input type="text" className="form-control" name="invite" required></input>
                </div>
                <div className="input-group">
                <button type="submit" className="float" value="submit-true"><div className="insider">Зарегистрироваться</div></button>
                <p className="userText">Регистрируясь в сервисе, Вы соглашаетесь с условиями <Link to="/ua">пользовательского соглашения</Link></p>
                </div>
              </form>
            </div>
          </div>
        </div>
    )
};

export default RegisterForm;