import { ActionCreator } from 'redux';
import * as BgImagesTypes from './types';
import * as Types from '../../Interfaces';

export const getBgImagesAction: ActionCreator<BgImagesTypes.IGetBgImagesAction> = (bgImages: Types.Image[]) => {
  console.log(bgImages)
  return {
    type: BgImagesTypes.BG_IMAGES_SUCCESS,
    payload: bgImages
  }
}
 
export const selectBgImageFileAction: ActionCreator<BgImagesTypes.ISelectBgImageFileAction> = (bgImage: Types.Image) => {
  return {
    type: BgImagesTypes.SELECT_BGIMAGE_FILE,
    payload: bgImage
  }
}

export const deleteSelectedBgImagesAction: ActionCreator<BgImagesTypes.IDeleteSelectedBgImagesAction>= () => {
  return {
    type: BgImagesTypes.DELETE_SELECTED_BGIMAGES,
  }
};