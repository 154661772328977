import * as Types from '../../Interfaces';

export const IMAGES_SUCCESS = 'IMAGES_SUCCESS';
export const SELECT_IMAGE_FILE = 'SELECT_IMAGE_FILE';
export const DELETE_SELECTED_IMAGES ='DELETE_SELECTED_IMAGES';

export interface IGetImagesAction {
    type: typeof IMAGES_SUCCESS,
    payload:  Types.Image[];
}

export interface ISelectImageFileAction {
    type: typeof SELECT_IMAGE_FILE,
    payload: Types.Image
}

export interface IDeleteSelectedImagesAction {
    type: typeof DELETE_SELECTED_IMAGES 
}

export type ImagesActionsTypes =
    | IGetImagesAction
    | ISelectImageFileAction
    | IDeleteSelectedImagesAction

export type IImagesState = Types.Image[]

