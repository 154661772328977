import React, { Component } from 'react';
import { Fade } from 'react-reveal';
import { sendConfirmationRequest } from '../api';
import { RouteComponentProps } from 'react-router-dom';

const SuccessConfirmation = () =>{
  return (
    <Fade>
      <div className="wrapper" style={{margin: "2em;"}}>
        <h2>Вы подтвердили свою электронную почту</h2>
        <p>Благодарим Вас за регистрацию в нашем сервисе.</p>
        <p>Теперь Вы можете перейти на <a href="/login">страницу входа в сервис</a> и используя свои учетные данные войти в систему</p>
      </div>
      </Fade>
  );
}


type TMatchParams = {confirmationCode: string };

interface IProps extends RouteComponentProps<TMatchParams>{
}

interface IState { 
  code?: string;
  success: boolean;
}

class ShowConfirmation extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {code:this.props.match.params.confirmationCode,
                  success: false};
                  console.log(this.props.match.params.confirmationCode)
  }

  componentDidMount =() =>{
    sendConfirmationRequest(this.state.code)
      .then(result =>{
      if (result)
      {
          if (result.success){
              this.setState({success: true})
              console.log(this.state)
          }else window.alert(result.message)
      }
  });
  }

  render() {
    return (
      <div className="wrapper">
        {this.state.success &&
          <SuccessConfirmation />
        }
      </div>
      );
  }
}
 
export default ShowConfirmation;