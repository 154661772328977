import React, {Fragment} from 'react';
import Header from '../Header/Header';
import {Fade} from 'react-reveal';
import * as H from 'history';

interface IProps {
  children?: any;
  leftSideComponent?: any;
  stepBack: boolean;
  email?: string;
  history?: H.History;
  onExit(): void;
}

const Wrapper: React.FC<IProps> = ({children,  leftSideComponent, stepBack, email, history, onExit}) =>{
    return(
        <Fragment>
        <div className="Top">
          <Header title={ 'ContentBoard'} stepBack={stepBack} email={ email } history={history} onExit={ onExit } />
        </div>
        <div className="Container">
            {leftSideComponent &&
            <div className="Left">
            {leftSideComponent}
            </div>}
          <div className="Middle">
            <Fade>
              <div className="main">
                <div className="vContainer">
                    {children}
                </div>
              </div>
              </Fade>
            </div>
        </div>
        </Fragment>
    );
}

  
export default Wrapper;