import React from 'react';
import './File.css';

import * as Types from '../../../Interfaces';

import {ImageFileIcon, PdfFileIcon, CheckElementIcon} from '../SvgIcons/SvgIconsSet';

interface IFileProps {
    fileType: Types.FolderType;
    fileName?: string;
    selected: boolean;
    onClick: ()=>void;
}

const File: React.FC<IFileProps> = ({fileType, fileName, selected, onClick}) =>{
    const renderIcon=(fileType: Types.FolderType) =>{
        switch (fileType){
            case Types.FolderType.pdf:
            return <PdfFileIcon className="pdf"/>
            case Types.FolderType.image:
            return <ImageFileIcon className="img"/>
            case Types.FolderType.video:
                return <ImageFileIcon className="img"/>
        }
    }

    return (
        <div className="fileWrapper" onClick={ onClick }>
           <div className={ `file ${selected? 'selected' :''}` }>
            <CheckElementIcon className="checkflag"/>{ renderIcon(fileType) }
            <span>{fileName}</span>
           </div> 
        </div> 
    )
}

export default File;