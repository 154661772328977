import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoginForm from './LoginForm/LoginForm';
import { Fade } from 'react-reveal';
import { sendAuthRequest, getCompanyInfoRequest, getUserInfo } from '../api';
import {dropUserAction, userSuccessActionA} from '../store/auth/actions';
import {getCompanySuccess, changeCompanyProp} from '../store/company/actions';
import * as Types from '../Interfaces';

interface ILoginProps extends IDispatchProps{
  user: Types.User;
  history: any;
 
}

class ShowLogin extends Component<ILoginProps> {
  constructor(props: ILoginProps) {
    super(props);
    // localStorage.setItem('token', null);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = (event) => {
    event.preventDefault();

    const login = event.target[0].value;
    const password = event.target[1].value;
    const creds = {
      email: login.trim(),
      password: password.trim()
    };
    sendAuthRequest(creds)
    .then((json)=>{
        // response ok, but it's not mean that user credential ok and ever user exists
        if (json && json.success) {
          console.log(json)
            // if creds is valid set token in local storage
            localStorage.setItem('token', json.token);
            // this.props.user &&
            getCompanyInfoRequest(json.base)
            .then((result: Types.Company) =>{
              // console.log(result)
              this.props.onGetCompanySuccess(result);
              this.props.onUserSuccess(getUserInfo())
              
              setTimeout(() => {
                this.props.history.push('/');
              }, 300);
            })
            
        // dispatch(successToken);
        } else{
            localStorage.removeItem('token');
            // dispatch(failToken);
            if (json)
              window.alert(json.message);
            else window.alert('Нет ответа от сервера');
        }
    })
  }

  componentDidMount =() =>{
    console.log('process.env.REACT_APP_API_URI: ', process.env.REACT_APP_API_URI);
    this.props.onDropUser();
  }

  render() {
    return (
        <Fade>
          <LoginForm onSubmitForm={ this.handleClick } />
        </Fade>
      );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
   user: state.auth.user
  }
};

interface IDispatchProps  {
  onGetCompanySuccess(result: Types.Company): void;
  onDropUser(): void;
  onUserSuccess(user: Types.User): void;
}

const mapDispatchToProps = dispatch => {
  return {
      onDropUser: () => dispatch(dropUserAction()),
      onGetCompanySuccess: (company: Types.Company) =>dispatch(getCompanySuccess(company)),
      onChangeCompanyProp: (field) =>dispatch(changeCompanyProp(field)),
      onUserSuccess: (user: Types.User) => dispatch(userSuccessActionA(user)),
  }
};
 
export default connect(mapStateToProps, mapDispatchToProps)(ShowLogin);