import { ActionCreator } from 'redux';
import * as ImagesTypes from './types';
import * as Types from '../../Interfaces';

export const getRelatedImages: ActionCreator<ImagesTypes.IGetImagesAction> = (images: Types.Image[]) => {
  console.log('getRelatedImages: ', images)
  return {
    type: ImagesTypes.IMAGES_SUCCESS,
    payload: images
  }
}
 
export const selectImageFileAction: ActionCreator<ImagesTypes.ISelectImageFileAction> = (image: Types.File) => {
  return {
    type: ImagesTypes.SELECT_IMAGE_FILE,
    payload: image
  }
}

export const deleteSelectedImagesAction: ActionCreator<ImagesTypes.IDeleteSelectedImagesAction>= () => {
  return {
    type: ImagesTypes.DELETE_SELECTED_IMAGES
  }
};