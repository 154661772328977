import { ActionCreator } from 'redux';
import * as UnionTypes from './types';
import * as Types from '../../Interfaces';

export const selectUnionAction: ActionCreator<UnionTypes.ISelectUnionAction> = (union: Types.Union) => {
  return {
    type: UnionTypes.SELECT_UNION,
    payload: union
  }
}

// type ThunkResult<R> = ThunkAction<R, UnionTypes.ISelectedUnionState, undefined, AnyAction>;

// Async Redux-Thunk action
// export function orderUpUnionActionA(selectedIndex: number): ThunkResult<Promise<UnionTypes.IUnionsState>> {
//   return async (dispatch, getState) => {
//     dispatch({
//       type: UnionTypes.ORDER_UP_UNION,
//       payload: selectedIndex
//     });
//     return Promise.resolve(getState())
//   };
// }
 
 