import { log } from '../../api';
import { IInvitesState, InvitesActionsTypes } from './types';
import * as InvitesTypes from './types';

const initialState: IInvitesState = [];

export function invitesReducer(state = initialState, action: InvitesActionsTypes): IInvitesState {
  switch (action.type) {
    case InvitesTypes.INVITES_SUCCESS:
      let newStateObject = Object.assign({}, state, action.payload);
      log('invites loading done');
      console.log(action.payload)
      return Array.from(Object.keys(newStateObject), k => newStateObject[k]) ;

    case InvitesTypes.DELETE_INVITE:
      const filtered = [...state];
      return filtered.filter(item => {
          return item._id !== action.payload._id
        })

    default:
      return state;

  }
}