import * as Types from '../../Interfaces';
 
export const ADD_GROUP = 'ADD_GROUP';
export const DROP_NEW_GROUP = 'DROP_NEW_GROUP';
export const CHANGE_NEW_GROUP_PROP = 'CHANGE_NEW_GROUP_PROP';
export const CHANGE_NEW_GROUP_LINK_PROP = 'CHANGE_NEW_GROUP_LINK_PROP';

export interface IAddGroupAction {
    type: typeof ADD_GROUP,
    payload: {index: number, order: number}
}

export interface IDropNewGroupAction {
    type: typeof DROP_NEW_GROUP,
}

export interface IChangeNewGroupPropAction {
    type: typeof CHANGE_NEW_GROUP_PROP,
    payload:  { newGroup: Types.Group, prop: any };
} 

export interface IChangeNewGroupLinkPropAction {
    type: typeof CHANGE_NEW_GROUP_LINK_PROP,
    payload:  { newGroup: Types.Group, link: string };
} 

export type NewGroupActionsTypes =
    | IAddGroupAction
    | IDropNewGroupAction
    | IChangeNewGroupPropAction
    | IChangeNewGroupLinkPropAction

export type INewGroupState = Types.Group
