import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchFiles, fetchImages } from "../api";
import { Fade } from "react-reveal";
import { TextField } from "material-ui";
import Button from "@material-ui/core/Button";
import {ImageSelect} from "./Elements/ImageSelect/ImageSelect";
import DocumentSelect from "./Elements/DocumentSelect/DocumentSelect";
import AppBar from "./Elements/AppBar/AppBar";

import imageIcon from "../img/picture.svg";
import pdfIcon from "../img/pdf.svg";
import {EditIcon, LinkIcon, HeadingIcon, SubHeadingIcon, PictureIcon, PDFIcon, } from './Elements/SvgIcons/SvgIconsSet';
import { Dispatch } from "redux";
import { User, Group, Union, File, Image } from "../Interfaces";
import {
	changeSelectedUnionPropActionA,
	getUnions,
  changeSelectedUnionLinkPropActionA
} from "../store/unions/actions";
import { getRelatedImages } from "../store/images/actions";
import { getGroups } from "../store/groups/actions";
import { getSettings } from "../store/settings/actions";
import { getFiles } from "../store/files/actions";

interface IProps extends IDispatchProps, IStateProps {
	onUpdateLink(folder, value: string): void;
	onDocumentUpload(e: any): void;
	onImageUpload(e: any): void;
}

class ShowSelectedUnion extends Component<IProps> {
	componentDidMount =() => {

		let folder: string;
		let base: string;;
		if (this.props) {
      folder = this.props.selectedGroup.folder;
			base = this.props.user.base;
			fetchFiles(folder || "nofolder", base).then(result =>
				this.props.onGetFilesNames(result)
			);
			fetchImages(folder || "nofolder", base).then(result =>
				this.props.onGetRelatedImages(result)
			);
		}
	};

	componentDidUpdate = function(prevProps) {
		if (this.props.selectedUnion.index !== prevProps.selectedUnion.index) {
			let base = this.props.user.base;
			fetchFiles(
				this.props.selectedGroup.folder || "nofolder",
				base
			).then(result => this.props.onGetFilesNames(result));
			fetchImages(
				this.props.selectedGroup.folder || "nofolder",
				base
			).then(result => this.props.onGetRelatedImages(result));
			// drop uploadStatus then nav on unions
		}
	};

	onChange = e => {
		let field = [];
		let name = e.target.name;
		let value = e.target.value;
		if (name === "order") value = Number(e.target.value);

		field[name] = value;
		this.props
			.onChangeSelectedUnionProp(this.props.selectedUnion, field)
			.then(() => {
				if (name === "file")
					this.props.onUpdateLink(this.props.selectedGroup.folder, value);
			});
	};

	styles = {
		title: {
			cursor: "pointer",
			textTransform: "uppercase"
		}
	};

	render() {
		const union = this.props.selectedUnion;

		return (
			<Fade>
				<div className="contentWrapper">
					<style>
						{`
                .hidden {
                    display: none;
                }
                `}
					</style>
					<AppBar className="ElementAppBar">
						<EditIcon />
						<span>
							Редактирование пункта '{this.props.selectedUnion.index}'
						</span>
					</AppBar>

					<div className="rowContainer hidden">
						<span className="caption">Идентификатор</span>
						<TextField
							fullWidth
							className="textField"
							type="number"
							disabled
							name="index"
							value={union ? union.index : ""}
						/>
					</div>
					<div className="rowContainer hidden">
						<span className="caption">Родитель</span>
						<TextField
							fullWidth
							className="textField"
							type="number"
							disabled
							name="parent"
							value={union ? union.parent : ""}
							onChange={this.onChange}
						/>
					</div>
					<div className="rowContainer">
						<span className="caption disabled">Порядковый номер</span>
						<TextField
							fullWidth
							disabled
							className="textField"
							type="number"
							name="order"
							value={union ? union.order : ""}
							onChange={this.onChange}
						/>
					</div>
					<div className="rowContainer">
						<span className="caption"><HeadingIcon/>Заголовок пункта меню</span>
						<TextField
							fullWidth
							className="textField"
							type="text"
							name="title"
							value={union ? union.title : ""}
							onChange={this.onChange}
						/>
					</div>
					<div className="rowContainer">
						<span className="caption"><SubHeadingIcon/>Подзаголовок пункта меню</span>
						<TextField
							fullWidth
							className="textField"
							type="text"
							name="subtitle"
							value={union ? union.subtitle : ""}
							onChange={this.onChange}
						/>
					</div>
					<div className="rowContainer">
						<span className="caption"><PictureIcon/>Изображение пункта меню</span>
						<div className="hor-set-holder">
							<ImageSelect
								images={this.props.images}
								value={union.image}
								name="image"
								onChangeSelectedImage={this.onChange}
							/>
							<Button
								style={{ margin: "10px" }}
								component="label"
								variant="outlined"
								color="primary"
							>
								<img
									src={imageIcon}
									className="imgInButton"
									alt=""
									width="24px"
								/>
								<span>Загрузить</span>
								<input
									id="selectedUnion"
									type="file"
									accept="image/*, application/images"
									style={{ display: "none" }}
									onChange={this.props.onImageUpload}
								/>
							</Button>
						</div>
					</div>
					<AppBar className="ElementAppBar">
						<span>
							Контент
						</span>
					</AppBar>
					<div className="content">
					
						<div className="rowContainer">
							<span className="caption"><PDFIcon />Целевой документ:</span>
							<div className="hor-set-holder">
								<DocumentSelect
									files={this.props.files}
									value={union.file}
									name="file"
									onChangeSelectedDocument={this.onChange}
								/>
								<Button
									style={{ margin: "10px" }}
									component="label"
									variant="outlined"
									color="primary"
								>
									<img
										src={pdfIcon}
										className="imgInButton"
										alt="pdf"
										width="24px"
									/>
									<span>Загрузить</span>
									<input
										id="selectedUnion"
										type="file"
										accept=".pdf, application/pdf"
										style={{ display: "none" }}
										onChange={this.props.onDocumentUpload}
									/>
								</Button>
							</div>
						</div>
						<div className="rowContainer">
							<span className="caption"><LinkIcon />
								Ссылка на целевой документ в облаке (для QR-кода)
							</span>
							<TextField
								fullWidth
								className="textField"
								type="text"
								name="link"
								value={union ? union.link : ""}
								onChange={this.onChange}
							/>
						</div>
					</div>
				</div>
			</Fade>
		);
	}
}
interface IStateProps {
	unions: Array<Union>;
	groups: Array<Group>;
	selectedUnion: Union;
	selectedGroup: Group;
	files: Array<File>;
	images: Array<Image>;
	user: User;
}

const mapStateToProps = (state: any): IStateProps => {
	return {
		unions: state.unions,
		groups: state.groups,
		selectedUnion: state.selectedUnion,
		selectedGroup: state.selectedGroup,
		files: state.files,
		images: state.images,
		user: state.auth.user
	};
};

interface IDispatchProps {
	onGetSettings: typeof getSettings;
	onGetUnionList: typeof getUnions;
	onGetGroupList: typeof getGroups;
	onChangeSelectedUnionProp: (selected, field) => Promise<void>;
	onGetFilesNames: typeof getFiles;
  onGetRelatedImages: typeof getRelatedImages;
  onChangeSelectedUnionLinkProp: (union, link) => Promise<void>;
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
	return {
		onGetSettings: items => dispatch(getSettings(items)),
		onGetUnionList: items => dispatch(getUnions(items)),
		onGetGroupList: items => dispatch(getGroups(items)),
		onChangeSelectedUnionProp: (selected, field) => dispatch<any>(changeSelectedUnionPropActionA(selected, field)),
		onGetFilesNames: items => dispatch(getFiles(items)),
		onGetRelatedImages: items => dispatch(getRelatedImages(items)),
		onChangeSelectedUnionLinkProp: (union, link)=> dispatch<any>(changeSelectedUnionLinkPropActionA(union, link))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowSelectedUnion);
