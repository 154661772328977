import { log } from '../../api';
import { IGroupsState, GroupsActionsTypes } from './types';
import * as GroupTypes from './types';
import { Group } from '../../Interfaces';

const initialState: IGroupsState = []

export function groupReducer(state = initialState, action: GroupsActionsTypes): IGroupsState {
  switch (action.type) {
    // case 'LOGOUT_SUCCESS':
    //   return initialState;
    case GroupTypes.GROUP_LIST_SUCCESS:
      // make new object from state and payload. No mutations here
      let newStateObject = Object.assign({}, state, action.payload);
      log('groups loading done');
      // convert the shape of new state to array, so i can iterate it later in component.1
      const woSort = Array.from(
        Object.keys(newStateObject),
        k => newStateObject[k]
      );
      // sort() will mutate original data, so we copy it with [].concat
      const withSort = [].concat(woSort);
      withSort.sort((a, b) => a.order - b.order);
      return withSort

    case GroupTypes.ORDER_UP_GROUP:
      // применяем группировку только если элементов два и более
      if (state.length > 1) {
        const state_copy_up = [].concat(state);
        // находим текущий (выбранный) объект списка
        const selected = state_copy_up.filter(item => {
          return item.index === action.payload;
        })[0];

        // console.log(selected);

        // находим предыдущий по отн. к выбранному, объект списка
        // || item.order < selected.order
        const prev = state_copy_up.filter(item => {
          return item.order === selected.order - 1;
        })[0];

        // console.log(prev);

        // если не достигли предыдущего элемента - либо начало списка, либо элемент не существует\удален
        if (prev !== undefined) {
          // меняем у предыдущего значение order
          const mem = prev.order;
          prev.order = selected.order;
          selected.order = mem;
          state_copy_up.sort((a, b) => a.order - b.order);
        }
        // возможно предыдущий элемент был когда-то удален
        else {
          if (selected.order > 1) {
            selected.order = selected.order - 1;
            state_copy_up.sort((a, b) => a.order - b.order);
          }
        }
        //return new (not mutable) array
        return [...state_copy_up] ;
      } else return state;

    case GroupTypes.ORDER_DN_GROUP:
      // применяем группировку только если элементов два и более
      if (state.length > 1) {
        const state_copy_dn = [].concat(state);
        // находим текущий (выбранный) объект списка
        const sel = state_copy_dn.filter(item => {
          return item.index === action.payload;
        })[0];

        // console.log(sel);

        // находим следующий по отн. к выбранному, объект списка
        // || item.order > selected.order
        const next = state_copy_dn.filter(item => {
          return item.order === sel.order + 1;
        })[0];

        // console.log(next);

        // если не достигли следующего элемента - либо конец списка, либо элемент не существует\удален
        if (next !== undefined) {
          // меняем у предыдущего значение order
          const memo = next.order;
          next.order = sel.order;
          sel.order = memo;
          state_copy_dn.sort((a, b) => a.order - b.order);
        }
        else {
          // console.log('else');
          if (sel.order < state_copy_dn.length) {
            sel.order = sel.order + 1;
            state_copy_dn.sort((a, b) => a.order - b.order);
          }
        }
        //return new (not mutable) array
        return [...state_copy_dn] 
      } else return state;

    case GroupTypes.CHANGE_SELECTED_GROUP_PROP:
      // сначала сливаем группу и редактируемое свойство в единый объект
      let changedSelectedGroup: Group = {
        ...action.payload.selectedGroup,
        ...action.payload.prop,
      };
      // console.log(changedSelectedGroup)
      // затем возвращаем новый стейт, содержащий данную группу
      return [].concat(state).map(item => {
          // здесь заменяем элемент отредактированным объектом
         return item.index === changedSelectedGroup.index ? changedSelectedGroup: item
        })

    case GroupTypes.CHANGE_SELECTED_GROUP_LINK_PROP:
      let changedSelectedGroupWithLinkProp: Group = {
       ...action.payload.selectedGroup, link: action.payload.link
      };
      // затем возвращаем новый стейт, содержащий данную группу
      return [].concat(state).map(item => {
        // здесь заменяем элемент отредактированным объектом
        return item.index === changedSelectedGroupWithLinkProp.index
          ? changedSelectedGroupWithLinkProp
          : item;
      });

    case GroupTypes.CHANGE_NEW_GROUP_PROP:
      // сначала сливаем группу и редактируемое свойство в единый объект
      let changedNewGroup: Group = {
        ...action.payload.newGroup,
        ...action.payload.prop,
      };
      // затем возвращаем новый стейт, содержащий данную группу
      return [].concat(state).map(item => {
          // здесь заменяем элемент отредактированным объектом
          return item.index === changedNewGroup.index ? changedNewGroup : item;
        })

    // событие происходит когда cвойтсво 'link' группы  редактируется
    case GroupTypes.CHANGE_NEW_GROUP_LINK_PROP:
      let changedNewGroupLinkProp: Group = {
        ...action.payload.newGroup,
        link: action.payload.link,
      };
      return [].concat(state).map(item => {
          return item.index === changedNewGroupLinkProp.index
            ? changedNewGroupLinkProp
            : item;
        })

    case GroupTypes.UPDATE_GROUPS_BEFORE_SAVE:
      //  сначала сливаем группу и редактируемое свойство в единый объект
      let changed: Group = action.payload;
      // затем возвращаем новый стейт, содержащий данную группу
      return [].concat(state).map(item => {
          // здесь заменяем элемент отредактированным объектом
          return item.index === changed.index ? changed : item;
        })

    case GroupTypes.INSERT_GROUP_BEFORE_SAVE:
      return [...state, action.payload] ;

    case GroupTypes.DELETE_GROUP:
      let filtered: Group[] = [].concat(state);
      const group: Group = action.payload;

      // мутируем стейт что бы потом вернуть 
      filtered = filtered.map(el => {
        // для элементов, находящихся ниже удаляемого, уменьшаем порядок на единицу
        if (el.order > group.order) {
          el.order = el.order - 1;
        }
        return el;
      })
      .filter( (item) => {
          return item.index !== group.index
        });
      return filtered

    default:
      return state;
  }
}