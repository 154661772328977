
import React from 'react';
import { NativeSelect } from '@material-ui/core';
import {File} from '../../../Interfaces';

interface IDocumentSelectProps{
  files: File[];
  value: string;
  name: string;
  onChangeSelectedDocument(event: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode): void;
}

const DocumentSelect: React.FC<IDocumentSelectProps> = (props) =>{
    return(
        <NativeSelect fullWidth style={ { minWidth: '150px', alignSelf: 'stretch' } } value={ props.value } name={props.name} onChange={ props.onChangeSelectedDocument }>
        <option value="" /> 
          { props.files &&                      
            props.files.map((item: File, i:number) => {
                return <option value={ item.fileName } key={ i } data-name={ item.fileName }>
                       { item.fileName.toString() }
                       </option>
            }) }
        </NativeSelect>
    );
  }

  export default DocumentSelect;