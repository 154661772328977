import { LastActionsTypes, ILastActionState, LAST_ACTION_FIRED} from './types';
import {checkToken} from '../../api';

const initialState: ILastActionState = {}

export function lastActionReducer(state = initialState, action: LastActionsTypes): ILastActionState {
  switch (action.type) {

    case LAST_ACTION_FIRED:
      if (checkToken(localStorage.getItem('token')))
      return  {
          ...state,
            actionType: action.payload.lastAction.actionType,
            actionObject: action.payload.lastAction.actionObject,
            actionId: action.payload.lastAction.actionId
        };
    else action.payload.history.push('/login');
    break;

    default:
      return state;

  }
}