import React from 'react';
import {Link} from 'react-router-dom';
import './PalleteButton.css'

interface IPalleteButtonProps {
  children?: any;
  onClick: (item: any) =>void;
  name?: string;
  className: string;
  dataHint?: string;
  caption?: string;
  linkTo?: string;
 }

const PalleteButton = ({children, name, onClick, className, dataHint, caption, linkTo}: IPalleteButtonProps) => {
    return (
      {linkTo} ? (<Link to={ linkTo } onClick={ onClick }><div data-name={name} className={ className } data-hint={  dataHint!=='undefined'? dataHint : null }>
      { children } <span>{caption}</span>
    </div></Link>)
    :
      (<div data-name={name} className={ className } onClick={ onClick } data-hint={ dataHint!=='undefined'? dataHint : null }>
        { children } <span>{caption}</span>
      </div>)
    )
  }

  export default PalleteButton;