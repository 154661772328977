import * as AuthTypes from './types';

const initialState: AuthTypes.IAuthState = {
  isFetching: false,
  isAuthenticated: false
}

export function authReducer(state = initialState, action: AuthTypes.AuthActionsTypes): AuthTypes.IAuthState {
  switch (action.type) {

    case AuthTypes.REQUEST_LOGIN:
      return Object.assign({}, state, {
        isFetching: action.payload.isFetching,
        isAuthenticated: action.payload.isAuthenticated,
      })
    case AuthTypes.USER_SUCCESS:
      return Object.assign({}, state, {
        isFetching: action.payload.isFetching,
        isAuthenticated: action.payload.isAuthenticated,
        user: action.payload.user
      })
    case AuthTypes.LOGIN_ERROR:
      return Object.assign({}, state, {
        isFetching: action.payload.isFetching,
        isAuthenticated: action.payload.isAuthenticated,
        errorMessage: action.payload.errorMessage
      })
    case AuthTypes.LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: action.payload.isFetching,
        isAuthenticated: action.payload.isAuthenticated,
      })
    case AuthTypes.CHECK_TOKEN:
      return Object.assign({}, state, {
        isFetching: action.payload.isFetching,
        isAuthenticated: action.payload.isAuthenticated,
      })
    case AuthTypes.DROP_USER:
      return Object.assign({}, state, {isFetching:false,
        isAuthenticated: false, user: null})
    default:
      return state;

  }
}