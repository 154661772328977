import React, { Component, Fragment } from 'react';
import InviteSetter from './InviteSetter/InviteSetter';
import { connect } from 'react-redux';
import {withRouter} from 'react-router';
import { getInvites } from '../store/invites/actions';
import { userLogoutAction } from '../store/auth/actions';
import {fetchInvites, removeToken, sendInviteRequest, sendDeleteInviteRequest} from '../api';
import {Link} from 'react-router-dom';
import Wrapper from './Wrapper/Wrapper';
import { Invite, User, Auth } from '../Interfaces';
import {Dispatch} from 'redux';
import * as H from 'history';

interface IProps extends IStateProps, IDispatchProps {
  user: User;
  auth: Auth;
  history: H.History;
}

class ShowRestricted extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  onExit = ()=>{
    removeToken();
    this.props.onUserLogout();
    this.props.history.push('/')
  }

  componentDidMount =()=>{
    fetchInvites().then(result => this.props.onGetInviteList(result));
  }

  handleClick = (event) => {
    event.preventDefault();
    const inviteOwner = event.target[0].value.trim();
    sendInviteRequest(inviteOwner)
      .then(response =>{
        // proceed only if response was received
        if (response){
        console.log(response);
        // response ok, but it's not mean that user credential ok and ever user exists
        if (response.success) {
          fetchInvites().then(result => this.props.onGetInviteList(result));
            // if creds is valid set token in local storage
            window.alert(response.message + ' ' + response);
        // dispatch(id);
        } else
            window.alert(response.message + ' ' + response);
            // dispatch(err);
      }
    }).catch(error=>{
      window.alert(error);
    })
   
  }

  handleDelete =(_id: string)=>{
    sendDeleteInviteRequest(_id).then(response=>{
        if (response)
        {
            if (response.success){
                fetchInvites().then(result => this.props.onGetInviteList(result));
                window.alert('Успешно');
            }else window.alert(response.message)
        }
    })
  }

  render() {
    return (
      <Fragment >
      {this.props.user &&
        <Wrapper stepBack={true} email={ this.props.user.email } onExit={ this.onExit }>
          {this.props.auth.isAuthenticated && this.props.user.super &&
          <div className="wrapper">
          
           <h2>Управление аккаунтами</h2>
           <Link to="/accountDetails/restricted/accounts"><span>Аккаунты</span></Link>
         
          <h2>Список свободных инвайтов</h2>
          <ul>
              {this.props.invites
              .map((item, i)=>{
              return (<li key={i}>{item._id} - {item.hash} - {item.inviteOwner} - {item.ownerEmail || ''} <span className="actionLink" onClick={ ()=>this.handleDelete(item._id) }>Удалить</span></li>)
                })}
          </ul>
          <InviteSetter onSubmitForm={ this.handleClick } />
            
         
          </div>
        }
        </Wrapper>}
        </Fragment>
      );
  }
}

interface IStateProps {
  user: User;
  invites: Invite[];
  auth: Auth;
}

const mapStateToProps = (state: any): IStateProps => {
  return {
    user: state.auth.user,
    invites: state.invites,
    auth: state.auth
  }
};

interface IDispatchProps {
  onGetInviteList: typeof getInvites;
  onUserLogout: typeof userLogoutAction;
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return {
    onGetInviteList: (items) => dispatch(getInvites(items)),
    onUserLogout: () => dispatch(userLogoutAction())
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShowRestricted));