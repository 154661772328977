
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getGroups, changeSelectedGroupPropActionA, updateGroupsBeforeSaveActionA } from '../store/groups/actions';
import { getUnions, deleteUnionAction } from '../store/unions/actions';
import { getSettings } from '../store/settings/actions';
import { getRelatedImages } from '../store/images/actions';
import { getFiles } from '../store/files/actions';
import { selectGroupAction } from '../store/selectedGroup/actions';

import { fetchUnions, fetchGroups, fetchFiles, fetchImages } from '../api';
import { Fade } from 'react-reveal';
import { TextField } from 'material-ui';
import Switcher from './Elements/Switcher/Switcher';
import Button from '@material-ui/core/Button';
import {ImageSelect} from './Elements/ImageSelect/ImageSelect';
import DocumentSelect from './Elements/DocumentSelect/DocumentSelect';
import AppBar from './Elements/AppBar/AppBar';
import { Redirect } from 'react-router';
import {EditGroupIcon, LinkIcon, HeadingIcon, SubHeadingIcon, PictureIcon, PDFIcon, FolderIcon} from './Elements/SvgIcons/SvgIconsSet';
import imageIcon from '../img/picture.svg';
import pdfIcon from '../img/pdf.svg';
import newFolder from '../img/create_new_folder-24px.svg';

import {User, Group, Union} from '../Interfaces';
import {Dispatch} from 'redux';

interface IProps extends IStateProps, IDispatchProps {
  onDocumentUpload(e: any): void;
  onImageUpload(e: any): void;
  onUpdateLink(folder, value: string): void;
  onNewFolder(folder: string): void;
  // user: User;
  // auth: Auth;
  // history: H.History;
}

class ShowSelectedGroup extends Component<IProps> {

    constructor(props:IProps) {
        super(props);
        this.onChange = this.onChange.bind(this);

        this.state = {
            currentDocument: null,
        }
    }

    componentDidMount = function() {
        if (this.props.groups.length === 0) {
            fetchGroups(this.props.user.base).then(result => this.props.onGetGroupList(result))
        }
        ;

        if (this.props.unions.length === 0) {
            fetchUnions(this.props.user.base).then(result => this.props.onGetUnionList(result))
        }
        ;

        fetchFiles(this.props.selectedGroup.folder || 'nofolder', this.props.user.base).then(result => this.props.onGetFilesNames(result));
        fetchImages(this.props.selectedGroup.folder || 'nofolder', this.props.user.base).then(result => this.props.onGetRelatedImages(result))
    };

    componentDidUpdate = function (prevProps) {
      setTimeout(this.fetchUpdates(prevProps), 3500);
    }

    fetchUpdates =(prevProps)=>{
      if (this.props.selectedGroup.index !== prevProps.selectedGroup.index || this.props.selectedGroup.folder !== prevProps.selectedGroup.folder){
        fetchFiles(this.props.selectedGroup.folder || 'nofolder', this.props.user.base).then(result => this.props.onGetFilesNames(result));
        fetchImages(this.props.selectedGroup.folder || 'nofolder', this.props.user.base).then(result => this.props.onGetRelatedImages(result))
      }
    }

    onCheckboxChange = (e) => {
        let field = [];
        field[e.target.name] = e.target.checked;
        this.props.onChangeSelectedGroupProp(this.props.selectedGroup, field);
    }

    renderRedirect = (group: Group) => {
        if (!group || group === undefined) {
            return <Redirect to='/' />
        }
    }

    onChange = (e) => {
      let field = [];
      let name= e.target.name;
      let value = e.target.value;
        if(name === 'folder'){
          value = value.replace(/ /g,"_");
        }
        if (name === 'order')
          value =  Number(value)
        
        field[name] = value;
        this.props.onChangeSelectedGroupProp(this.props.selectedGroup, field)
        .then(() =>{
          if (name === 'file')
            this.props.onUpdateLink(this.props.selectedGroup.folder, value)
        })
    }

    handleNewFolder =() =>{
      this.props.onNewFolder(this.props.selectedGroup.folder)
    }

    render() {
        const group = this.props.selectedGroup;
        return (
            <Fade>
              <div className="contentWrapper">
                <style>
                  { `
              
                  .invisible{
                    display: ${ group && group.direct ? 'flex' : 'none' }
                  }
                
                ` }
                </style>
                <AppBar className="GroupAppBar" >
                   <EditGroupIcon/><span>Редактирование группы '{ this.props.selectedGroup.index }'</span>
                </AppBar>
                <div className="rowContainer">
                  <span className="caption disabled">Порядковый номер</span>
                   <TextField fullWidth disabled className="textField" name="order" value={ group ? group.order : '' }  />
                </div>
                <div className="rowContainer">
                  { group &&
                    <div className="colContainer">
                      <Switcher checked={ group.direct } name="direct" onCheckboxChange={ this.onCheckboxChange } />
                      <div>Хочу что бы эта группа не показывала подгруппы (ссылалась на документ)</div>
                    </div> }
                </div> 
                <div className="rowContainer">
                  <span className="caption"><HeadingIcon />Заголовок группы</span>
                  <TextField fullWidth className="textField" type="text" name="title" value={ group.title } onChange={ this.onChange } />
                </div>
                <div className="rowContainer">
                  <span className="caption"><SubHeadingIcon />Подзаголовок группы</span>
                  <TextField fullWidth className="textField" type="text" name="subtitle" value={ group.subtitle } onChange={ this.onChange } />
                </div>
                <div className="rowContainer">
                  <span className="caption"><FolderIcon />Название каталога для файлов группы</span>
                  <div className="hor-set-holder">
                    <TextField fullWidth className="textField" type="text" name="folder" value={ group.folder } onChange={this.onChange} />
                    <Button disabled={ group.folder === ''} style={ { margin: '10px' } } variant="outlined" color="primary"  onClick={ this.handleNewFolder } component="label">
                          <img src={ newFolder } className="imgInButton" alt="" width="24px" />
                          <span>Создать</span>
                        </Button>
                  </div>
                </div>
                <div className="rowContainer">
                  <span className="caption"><PictureIcon />Изображение группы</span>
                  <div className="hor-set-holder">
                    <ImageSelect images={this.props.images} value={group.image} name="image" onChangeSelectedImage={this.onChange}/>
                    <Button style={ { margin: '10px' } } component="label" variant="outlined" color="primary" >
                      <img src={ imageIcon } className="imgInButton" alt="" width="24px" />
                      <span>Загрузить</span>
                      <input id="selectedGroup" type="file" accept="image/*, application/images" style={ { display: "none" } } onChange={ this.props.onImageUpload } />
                    </Button>
                  </div>
                </div>

                <AppBar className={`ElementAppBar ${group.direct ? '' : 'invisible'}`}>
                  <span>
                    Контент
                  </span>
                </AppBar>
					    <div className="content">
                <div className={`rowContainer ${group.direct ? '' : 'invisible'}`}>
                  <span className="caption"><PDFIcon />Целевой документ:</span>
                  <div className="hor-set-holder">
                    <DocumentSelect files={this.props.files} value={group.file} name="file" onChangeSelectedDocument={this.onChange}/>
                    <Button style={ { margin: '10px' } } component="label" variant="outlined" color="primary" >
                      <img src={ pdfIcon } className="imgInButton" alt="pdf" width="24px" />
                      <span>Загрузить</span>
                      <input id="selectedGroup" type="file" accept=".pdf, application/pdf" style={ { display: "none" } }  onChange={ this.props.onDocumentUpload } />
                    </Button>
                  </div>
                </div>
                <div className={`rowContainer ${group.direct ? '' : 'invisible'}`}>
                  <span className="caption"><LinkIcon />Ссылка на целевой документ в облаке (для QR-кода)</span>
                   <TextField fullWidth className="textField" type="text" name="link" value={ group.link? group.link : '' } onChange={ this.onChange } /> 
                </div>
          
              </div>
              </div>
            </Fade>
            );
    }
}

interface IStateProps {
        unions: Array<Union>;
        groups: Array<Group>;
        selectedGroup: Group;
        files: Array<any>;
        images: Array<any>;
        user: User;
}

const mapStateToProps = (state:any):IStateProps=> {
    return {
        unions: state.unions,
        groups: state.groups,
        selectedGroup: state.selectedGroup,
        files: state.files,
        images: state.images,
        user: state.auth.user
    }
};

interface IDispatchProps {
  onGetSettings: typeof getSettings;
  onGetUnionList: typeof getUnions;
  onGetGroupList: typeof getGroups;
  onSelectGroup: typeof selectGroupAction;
  onChangeSelectedGroupProp: (selected: Group, field: any) =>  Promise<void>;
  onGetFilesNames: typeof getFiles;
  onGetRelatedImages: typeof getRelatedImages;
  onDeleteUnion: typeof deleteUnionAction;
  onUpdateGroupsBeforeSave: (group: Group) => Promise<void>;
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
    return {
        onGetSettings: (items) => dispatch(getSettings(items)),
        onGetUnionList: items => dispatch(getUnions(items)),
        onGetGroupList: items => dispatch(getGroups(items)),
        onSelectGroup: group => dispatch(selectGroupAction(group)),
        onChangeSelectedGroupProp: (selected, field) => dispatch<any>(changeSelectedGroupPropActionA(selected, field)),
        onGetFilesNames: (items: string[]) => dispatch(getFiles(items)),
        onGetRelatedImages: items => dispatch(getRelatedImages(items)),
        onDeleteUnion: (id) => dispatch(deleteUnionAction(id)),
        onUpdateGroupsBeforeSave: (group) => dispatch<any>(updateGroupsBeforeSaveActionA(group))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowSelectedGroup);