import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { groupReducer } from './groups/reducers';
import { unionReducer } from './unions/reducers';
import { newUnionReducer } from './newUnion/reducers';
import { newGroupReducer } from './newGroup/reducers';
import { selectedUnionReducer } from './selectedUnion/reducers';
import { selectedGroupReducer } from './selectedGroup/reducers';
import { accountsReducer } from './accounts/reducers';
import { authReducer } from './auth/reducers';
import { bgImagesReducer } from './bgImages/reducers';
import { companyReducer } from './company/reducers';
import { filesReducer } from './files/reducers';
import { imagesReducer } from './images/reducers';
import { foldersReducer } from './folders/reducers';
import { settingsReducer } from './settings/reducers';
import { invitesReducer } from './invites/reducers';
import { lastActionReducer } from './lastAction/reducers';

import { IGroupsState } from './groups/types';
import { IUnionsState } from './unions/types';
import { INewUnionState } from './newUnion/types';
import { INewGroupState } from './newGroup/types';
import { ISelectedUnionState } from './selectedUnion/types';
import { ISelectedGroupState } from './selectedGroup/types';
import { IAccountsState } from './accounts/types';
import { IAuthState } from './auth/types';
import { IBgImagesState } from './bgImages/types';
import { ICompanyState } from './company/types';
import { IFilesState } from './files/types';
import { IImagesState } from './images/types';
import { IFoldersState } from './folders/types';
import { ISettingsState } from './settings/types';
import { IInvitesState } from './invites/types';
import { ILastActionState } from './lastAction/types';

export interface IReduxAppicationState {
    readonly groups: IGroupsState;
    readonly unions: IUnionsState;
    readonly newUnion: INewUnionState;
    readonly newGroup: INewGroupState;
    readonly selectedUnion: ISelectedUnionState;
    readonly selectedGroup: ISelectedGroupState;
    readonly accountsReducer: IAccountsState;
    readonly authReducer: IAuthState;
    readonly bgImagesReducer: IBgImagesState;
    readonly companyReducer: ICompanyState;
    readonly filesReducer: IFilesState;
    readonly imagesReducer: IImagesState;
    readonly foldersReducer: IFoldersState;
    readonly settingsReducer: ISettingsState;
    readonly invitedReducer: IInvitesState;
    readonly lastActionReducer: ILastActionState;
  }

const rootReducer = combineReducers({
  groups: groupReducer,
  unions: unionReducer,
  newUnion: newUnionReducer,
  newGroup: newGroupReducer,
  selectedUnion: selectedUnionReducer,
  selectedGroup: selectedGroupReducer,
  accounts: accountsReducer,
  auth: authReducer,
  bgImages: bgImagesReducer,
  company: companyReducer,
  files: filesReducer,
  images: imagesReducer,
  folders: foldersReducer,
  settings: settingsReducer,
  invites: invitesReducer,
  lastAction: lastActionReducer
})

export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore() {
  const middlewares = [thunkMiddleware];
  const middleWareEnhancer = applyMiddleware(...middlewares);

  const store = createStore(
    rootReducer,
    composeWithDevTools(middleWareEnhancer)
  );

  return store;
}
