import * as Types from '../../Interfaces';

export const BG_IMAGES_SUCCESS = 'BG_IMAGES_SUCCESS';
export const SELECT_BGIMAGE_FILE = 'SELECT_BGIMAGE_FILE';
export const DELETE_SELECTED_BGIMAGES = 'DELETE_SELECTED_BGIMAGES';

export interface IGetBgImagesAction {
    type: typeof BG_IMAGES_SUCCESS,
    payload: Array<Types.Image>
}

export interface ISelectBgImageFileAction {
    type: typeof SELECT_BGIMAGE_FILE,
    payload: Types.Image
}

export interface IDeleteSelectedBgImagesAction {
    type: typeof DELETE_SELECTED_BGIMAGES 
}

export type BgImagesActionsTypes =
    | IGetBgImagesAction
    | ISelectBgImageFileAction
    | IDeleteSelectedBgImagesAction

export type IBgImagesState = Array<Types.Image>;

