
import { ActionCreator, AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import * as GroupTypes from './types';
import * as Types from '../../Interfaces';

export const getGroups: ActionCreator<GroupTypes.IGetGroupListAction> = (groups: Types.Group[]) => {
  return {
    type: GroupTypes.GROUP_LIST_SUCCESS,
    payload: groups
  }
}

type ThunkResult<R> = ThunkAction<R, GroupTypes.IGroupsState, undefined, AnyAction>;

// Async Redux-Thunk action
export function orderUpGroupActionA(selectedIndex: number): ThunkResult<Promise<GroupTypes.IGroupsState>> {
  return async (dispatch, getState) => {
    dispatch({
      type: GroupTypes.ORDER_UP_GROUP,
      payload: selectedIndex
    });
    return Promise.resolve(getState())
  };
}

export function orderDnGroupActionA(selectedIndex: number): ThunkResult<Promise<GroupTypes.IGroupsState>> {
  return async (dispatch, getState) => {
    dispatch({
      type: GroupTypes.ORDER_DN_GROUP,
      payload: selectedIndex
    });
    return Promise.resolve(getState())
  };
}

// возможно нужно вернуть ():IGroupsState
export const deleteGroupAction: ActionCreator<GroupTypes.IDeleteGroupAction> = (group: Types.Group) => {
  return {
    type: GroupTypes.DELETE_GROUP,
    payload:  group
  }
};

export function updateGroupsBeforeSaveActionA(selected: Types.Group): ThunkResult<Promise<GroupTypes.IGroupsState>> {
  return async (dispatch, getState) => {
    dispatch({
      type: GroupTypes.UPDATE_GROUPS_BEFORE_SAVE,
      payload: selected
    })
    return Promise.resolve(getState())
  };
}

export function insertGroupBeforeSaveActionA(newGroup: Types.Group): ThunkResult<Promise<GroupTypes.IGroupsState>> {
  return async (dispatch, getState) => {
    dispatch({
      type: GroupTypes.INSERT_GROUP_BEFORE_SAVE,
      payload: newGroup
    })
    return Promise.resolve(getState())
  };
}

export function changeNewGroupPropActionA(newGroup: Types.Group, prop: any): ThunkResult<Promise<GroupTypes.IGroupsState>> {
  return async (dispatch, getState) => {
    dispatch({
      type: GroupTypes.CHANGE_NEW_GROUP_PROP,
      payload: {newGroup, prop}
    })
    return Promise.resolve(getState())
  };
}

export function changeSelectedGroupPropActionA(selectedGroup: Types.Group, prop: any): ThunkResult<Promise<GroupTypes.IGroupsState>> {
  return async (dispatch, getState) => {
    dispatch({
      type: GroupTypes.CHANGE_SELECTED_GROUP_PROP,
      payload: {selectedGroup, prop}
    })
    return Promise.resolve(getState())
  };
}

export function changeNewGroupLinkPropActionA(newGroup: Types.Group, link: string): ThunkResult<Promise<GroupTypes.IGroupsState>> {
  return async (dispatch, getState) => {
    dispatch({
      type: GroupTypes.CHANGE_NEW_GROUP_LINK_PROP,
      payload: {newGroup, link}
    })
    return Promise.resolve(getState())
  };
}

export function changeSelectedGroupLinkPropActionA(selectedGroup: Types.Group, link: string): ThunkResult<Promise<GroupTypes.IGroupsState>> {
  return async (dispatch, getState) => {
    dispatch({
      type: GroupTypes.CHANGE_SELECTED_GROUP_LINK_PROP,
      payload: {selectedGroup, link}
    })
    return Promise.resolve(getState())
  };
}