import {Link} from 'react-router-dom';
import React from 'react';
import './Header.css';
import {LogOutIcon, StepBackIcon, TouchIcon} from '../Elements/SvgIcons/SvgIconsSet';
import * as H from 'history';
 
interface IHeaderProps {
  title: string;
  email?: string;
  stepBack: boolean;
  history?: H.History;
  onExit?(): void;
  Icon? :any;
 }

const Header: React.FC<IHeaderProps> =(props)=>{
  return(
    <div className="header">
      <div className="left">
      <Link to={ '/' } >
        {props.Icon? <props.Icon />: <TouchIcon />}<span className="appTitle">{ props.title }</span>
        </Link>
      </div>
        
        <div className="right">
          <Link to={ '/accountDetails' }>
            { props.email } 
          </Link>

          {props.stepBack &&
          <Link to={ '/' } >
            <StepBackIcon /><span>Назад</span>
          </Link>}

          <Link to="#" onClick={ props.onExit }><LogOutIcon /> <span>Выход</span></Link>
        </div>
     </div>
  );
}
export default Header;