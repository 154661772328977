// import Action from 'redux';

import * as Types from '../../Interfaces';
 
export const ADD_UNION = 'ADD_UNION';
export const DROP_NEW_UNION = 'DROP_NEW_UNION';
export const CHANGE_NEW_UNION_PROP = 'CHANGE_NEW_UNION_PROP';
export const CHANGE_NEW_UNION_LINK_PROP = 'CHANGE_NEW_UNION_LINK_PROP';

export interface IAddUnionAction {
    type: typeof ADD_UNION,
    payload: {index: number, parent: number, order: number}
}

export interface IDropNewUnionAction {
    type: typeof DROP_NEW_UNION,
}

export interface IChangeNewUnionPropAction {
    type: typeof CHANGE_NEW_UNION_PROP,
    payload:  { newUnion: Types.Union, prop: any };
} 

export interface IChangeNewUnionLinkPropAction {
    type: typeof CHANGE_NEW_UNION_LINK_PROP,
    payload: { newUnion: Types.Union, link: string }
} 

export type NewUnionActionsTypes =
    | IAddUnionAction
    | IDropNewUnionAction
    | IChangeNewUnionPropAction
    | IChangeNewUnionLinkPropAction

export type INewUnionState = Types.Union
