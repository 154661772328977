import * as CompanyTypes from './types';

const initialState: CompanyTypes.ICompanyState = {}

export function companyReducer(state = initialState, action: CompanyTypes.CompanyActionsTypes): CompanyTypes.ICompanyState {
  switch (action.type) {

    case CompanyTypes.COMPANY_SUCCESS:
    return {...action.payload };
      
    case CompanyTypes.CHANGE_COMPANY_PROP:
      return Object.assign({}, state, action.payload);

    // case CompanyTypes.SELECT_COMPANY:
    //   const selected = state.map(item => ({
    //     ...item,
    //     isSelected: item.index === action.payload.index ? true : false
    //   }))
    //   return Array.from(Object.keys(selected), k => selected[k]);

    default:
      return state;

  }
}