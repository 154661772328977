import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as groupsAct from '../store/groups/actions';
import * as newGroupAct from '../store/newGroup/actions';
import * as selectedGroupAct from '../store/selectedGroup/actions';
import * as unionsAct from '../store/unions/actions';
import { getRelatedImages } from '../store/images/actions';
import { getFiles } from '../store/files/actions';
import { fetchFiles, fetchImages } from '../api';
import { Fade } from 'react-reveal';
import { TextField } from 'material-ui';
import Switcher from './Elements/Switcher/Switcher';
import Button from '@material-ui/core/Button';
import UploadStatus from './Elements/UploadStatus/UploadStatus';
import {ImageSelect} from './Elements/ImageSelect/ImageSelect';
import DocumentSelect from './Elements/DocumentSelect/DocumentSelect';
import AppBar from './Elements/AppBar/AppBar';
import imageIcon from '../img/picture.svg';
import pdfIcon from '../img/pdf.svg';
import newFolder from '../img/create_new_folder-24px.svg';
import {AddGroupIcon, HeadingIcon, SubHeadingIcon, PictureIcon, PDFIcon, FolderIcon} from './Elements/SvgIcons/SvgIconsSet';
import {File, Image, Group} from '../Interfaces';

interface IAddGroupProps {
  title?: string;
  className?: string;
  newGroup?: any;
  files: Array<File>;
  images: Array<Image>;
  uploadStatus: string,
  uploadStatusCode: string,
  file?: any;
  onChangeNewGroupProp: (selected, field: any) => Promise<void>;
  onDocumentUpload: ()=>void;
  onImageUpload: ()=>void;
  onNewFolder: (newFolder: string) => void;
  onChange(event: Event): void;
  onChangeSelectedDocument(event: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode): void;
  onChangeSelectedImage(event: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode): void;
  onUpdateLink(folder, value: string): void;
}

class AddGroup extends Component<IAddGroupProps> {

  componentDidMount = function() {
    fetchFiles(this.props.newGroup.folder || 'nofolder', this.props.user.base).then(result => this.props.onGetFilesNames(result));
    fetchImages(this.props.newGroup.folder || 'nofolder', this.props.user.base).then(result => this.props.onGetRelatedImages(result))
};

componentDidUpdate = function (prevProps) {
    if (this.props.newGroup.folder !== prevProps.newGroup.folder){
        fetchFiles(this.props.newGroup.folder || 'nofolder', this.props.user.base).then(result => this.props.onGetFilesNames(result));
        fetchImages(this.props.newGroup.folder || 'nofolder', this.props.user.base).then(result => this.props.onGetRelatedImages(result))
    }
}

commonChangeNewGroupProp = (name: string, value: string) =>{
  let field = [];
  if(name === 'folder'){
    field[name] = value.replace(/ /g,"_");
  }
  else
    field[name] = value;
  this.props.onChangeNewGroupProp(this.props.newGroup, field)
  .then(() =>{
    if (name === 'file')
      this.props.onUpdateLink(this.props.newGroup.folder, value)
  })
}

  onChange = (event: Event) => {
    const target = event.currentTarget as HTMLInputElement;
    this.commonChangeNewGroupProp(target.name, target.value);
  }

  changeSelectedDocumentHandler =(event: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode) =>{
    const target = event.currentTarget as HTMLSelectElement;
    this.commonChangeNewGroupProp(target.name, target.value);
  }

  changeSelectedImageHandler =(event: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode) =>{
    const target = event.currentTarget as HTMLSelectElement;
    this.commonChangeNewGroupProp(target.name, target.value);
  }

  onCheckboxChange = (e) => {
    let field = [];
    field[e.target.name] = e.target.checked;
    this.props.onChangeNewGroupProp(this.props.newGroup, field);
  }

  handleNewFolder =() =>{
    this.props.onNewFolder(this.props.newGroup.folder)
  }

  styles = {
    title: {
        textTransform: 'uppercase'
    },
    color: {
      color: '#ccc'
    }
};

  render() {
    const group: Group = this.props.newGroup;
    return (
          <Fade> 
            <div className="contentWrapper">
                <AppBar className="GroupAppBar" >
                  <AddGroupIcon/><span>Новая группа '{ this.props.newGroup.index }'</span>
                </AppBar>
                <div className="rowContainer">
            <span className="caption disabled">Порядковый номер</span>
              <TextField fullWidth disabled className="textField" name="order" value={ group ? group.order : '' } onChange={ this.onChange } />
            </div>
            <div className="rowContainer">
                  { group &&
                    <div className="colContainer">
                      <Switcher checked={ group.direct } name="direct" onCheckboxChange={ this.onCheckboxChange } />
                      <div>Хочу что бы эта группа не показывала подгруппы (ссылалась на документ)</div>
                    </div> }
                </div> 

            <div className="rowContainer">
              <span className="caption"><HeadingIcon />Заголовок группы</span>
              <TextField fullWidth className="textField" type="text" name="title"   value={ group ? group.title : '' } onChange={ this.onChange } />
            </div>
            <div className="rowContainer">
            <span className="caption"><SubHeadingIcon />Подзаголовок группы</span>
              <TextField fullWidth className="textField" type="text"  name="subtitle"  value={ group ? group.subtitle : '' } onChange={ this.onChange } />
            </div>
            
            <div className="rowContainer">
                  <span className="caption"><FolderIcon />Название каталога для файлов группы</span>
                  <div className="hor-set-holder">
                    <TextField fullWidth className="textField" type="text" name="folder" value={ group.folder } onChange={ this.onChange } />
                    <Button disabled={ group.folder === ''} style={ { margin: '10px' } } variant="outlined" color="primary" onClick={this.handleNewFolder}  component="label">
                        <img src={ newFolder } className="imgInButton" alt="" width="24px" />
                        <span>Создать</span>
                        </Button>
                  </div>
                </div>

                <div className="rowContainer">
                  <span className="caption"><PictureIcon />Изображение группы</span>
                  <div className="hor-set-holder">
                    <ImageSelect images={this.props.images} value={group.image} name="image" onChangeSelectedImage={this.changeSelectedImageHandler}/>
                    <Button style={ { margin: '10px' } } component="label" variant="outlined" color="primary" >
                      <img src={ imageIcon } className="imgInButton" alt="" width="24px" />
                      <span>Загрузить</span>
                      <input id="newGroup" type="file" accept="image/*, application/images" style={ { display: "none" } } onChange={ this.props.onImageUpload } />
                    </Button>
                  </div>
                  {/* <UploadStatus file={ this.props.file } statusText={ this.props.uploadStatus } statusCode={ this.props.uploadStatusCode } /> */}
                </div>
                <AppBar className={`ElementAppBar ${group.direct ? '' : 'invisible'}`}>
                  <span>
                    Контент
                  </span>
                </AppBar>
					    <div className="content">
                <div className={`rowContainer ${group.direct ? '' : 'invisible'}`}>
                  <span className="caption"><PDFIcon />Целевой документ:</span>
                  <div className="hor-set-holder">
                    <DocumentSelect files={this.props.files} value={group.file} name="file" onChangeSelectedDocument={this.changeSelectedDocumentHandler}/>
                    <Button style={ { margin: '10px' } } component="label" variant="outlined" color="primary" >
                      <img src={ pdfIcon } className="imgInButton" alt="pdf" width="24px" />
                      <span>Загрузить</span>
                      <input id="newGroup" type="file" accept=".pdf, application/pdf" style={ { display: "none" } } onChange={ this.props.onDocumentUpload } />
                    </Button>
                  </div>
                </div>
                <div className={`rowContainer ${group.direct ? '' : 'invisible'}`}>
                  <span className="caption">Ссылка на целевой документ в облаке (для QR-кода)</span>
                   <TextField fullWidth className="textField" type="text" name="link" value={ group.link? group.link : '' } onChange={ this.onChange } /> 
                </div>
              </div>
           </div>
          </Fade>
      );
  }
}
;

const mapStateToProps = (state, ownProps) => {
  return {
    unions: state.unions,
    groups: state.groups,
    newGroup: state.newGroup,
    files: state.files,
    images: state.images,
    user: state.auth.user
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onGetUnionList: items => dispatch(unionsAct.getUnions(items)),
    onGetGroupList: items => dispatch(groupsAct.getGroups(items)),
    onGetFilesNames: items => dispatch(getFiles(items)),
    onGetRelatedImages: items => dispatch(getRelatedImages(items)),
    onDeleteUnion: (id)=> dispatch(unionsAct.deleteUnionAction(id)),
    onAddGroup: (id, order)=>dispatch(newGroupAct.addGroupActionA(id, order)),
    onChangeNewGroupProp: (selected, field) => dispatch(groupsAct.changeNewGroupPropActionA(selected, field)),
    onInsertGroupBeforeSave: newGroup=>dispatch(groupsAct.insertGroupBeforeSaveActionA(newGroup)),
    onSelectGroup: group => dispatch(selectedGroupAct.selectGroupAction(group)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddGroup);