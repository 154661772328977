import React, { Fragment } from 'react';
import Switcher from '../Elements/Switcher/Switcher';
import { TextField } from 'material-ui';
import * as Types from '../../Interfaces';

interface IProps extends Types.Company{
  // tlgrmData: Types.TlgrmData;
  companyData: Types.Company;
  onCheckboxChange(e: Event): void;
  onChange(e: Event): void;
}

const TlgrmSettingsForm:React.FC<IProps> = (props) => {
  return( 
    <Fragment>
      <div className="contentWrapper">
      <div className="set-holder">
      <div className="rowContainer">
          <p className="rowhead">Telegram</p>
          <p>Данная функция позволяет всем подписчикам Telegram-канала <i>{ props.companyData.tlgrmChannel }</i> получать уведомление и ссылку на вновь загруженный файл.<br />
          <span> Таким, образом Вы можете автоматически информировать свою аудиторию об изменившихся документах.</span></p> 
          <p>Не передавайте token Telegram-бота третьим лицам. Тот кому известен token может отправлять сообщения в ваш канал от вашего имени.</p> 
          <div className="colContainer">
            <Switcher name="tlgrmEnabled" checked={ props.companyData.tlgrmEnabled } onCheckboxChange={ props.onCheckboxChange } />
            <div>Активировать Telegram</div>
            <br/>
        </div>
        
         </div>
      </div>
      <div className="set-holder">
        <div className="caption">Токен Telegram-бота <span className="caption">[например, 1234567890:DDEF_O5jUyjRMpkFfekQaBJ3ttbQ7k9UOyc]</span></div>
        <div><TextField fullWidth className="textField" type="text" name="tlgrmToken"  value={ props.companyData.tlgrmToken } onChange={props.onChange } /></div>
        </div>
      <div className="set-holder">
          <div className="caption">Имя канала <span className="caption">[@your_channel]</span></div>
          <div> <TextField fullWidth className="textField" type="text" name="tlgrmChannel"  value={ props.companyData.tlgrmChannel } onChange={ props.onChange } /></div>
        </div>
      </div>
     </Fragment>
      );
}
export default TlgrmSettingsForm