import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Admin from './Admin';
import registerServiceWorker from './registerServiceWorker.js';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Redirect, RouteProps} from 'react-router-dom';
import ShowLogin from './components/ShowLoginComponent';
import ShowConfirmation from './components/ShowConfirmationComponent';
import { checkToken, getUserInfo } from './api';
import ShowRestricted from './components/ShowRestrictedComponent';
import ShowRestrictedAccounts from './components/ShowRestrictedAccounts';
import AccountDetails from './components/AccountDetailsComponent';
import ShowRegister from './components/ShowRegisterComponent';
import ShowUserAgreement from './components/ShowUserAgreement';

import {MuiThemeProvider, getMuiTheme} from 'material-ui/styles';
import ShowDriveComponent from './components/ShowDriveComponent';
import configureStore from "./store";

  const store = configureStore();
// store.subscribe(() => {
//      console.log(store.getState());
// });


// This replaces the textColor value on the palette
// and then update the keys for each component that depends on it.
// More on Colors: http://www.material-ui.com/#/customization/colors
const muiTheme = getMuiTheme({
  palette: {
    textColor: '#000',
    primary: '#00a2e2',
    secondary: '#EC2064',
  },
  appBar: {
    height: 40,
  },
  status: {
    danger: 'orange',
  },
});

interface MatchParams {
  confirmationCode: string;
  store: any;
}

// interface IMatchProps extends RouteComponentProps<MatchParams> {
//   match: any;
//   history: H.History;
//   location: H.Location;
// }

interface PrivateRouteProps extends Omit<RouteProps, "component">  {
  component: React.ElementType;
  props?: any;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({component: Component, ...rest}) => (
  <Route {...rest} render={ props => (
    checkToken(localStorage.getItem('token')) ? (
                            <Component {...props} render={ props=> getUserInfo() } />
                            ) : (
                            <Redirect to={ { pathname: '/login', state: { from: props.location } } } />
                            )
                        ) } />
);

const Root: React.FC=() =>(
        <Provider store={ store }>
          {/* history={ browserHistory } */}
          <Router >
          <MuiThemeProvider muiTheme={muiTheme}>
            <div className="Admin" id="Admin">
            {/* <CssBaseline /> */}
              <Route path='/login' component={ ShowLogin } />
              <Route path='/register' component={ ShowRegister } />
              <Route path='/confirm/:confirmationCode'  component={ ShowConfirmation } />
              <Route path='/ua' component={ ShowUserAgreement } />
              <PrivateRoute exact path='/' component={ Admin } />
              <PrivateRoute path='/drive' component={ ShowDriveComponent } />
              <PrivateRoute path='/accountDetails' exact component={ AccountDetails } />
              <PrivateRoute path='/accountDetails/restricted' exact component={ ShowRestricted } />
              <PrivateRoute path='/accountDetails/restricted/accounts' exact component={ ShowRestrictedAccounts } />
            </div>
          </MuiThemeProvider>
          </Router>
        </Provider>
);

ReactDOM.render(
    <Root />,
    document.getElementById('root'));
registerServiceWorker();
