import { ActionCreator } from 'redux';
import * as GroupTypes from './types';
import * as Types from '../../Interfaces';

export const selectGroupAction: ActionCreator<GroupTypes.ISelectGroupAction> = (group: Types.Group) => {
  return {
    type: GroupTypes.SELECT_GROUP,
    payload: group
  }
}

export const selectGroupThenSelectChild: ActionCreator<GroupTypes.ISelectGroupByIndexAction> = (groups: Types.Group[], index:number) => {
  return {
    type: GroupTypes.SELECT_GROUP_BY_INDEX,
    payload: {
      groups, index
    }
  } 
};

// type ThunkResult<R> = ThunkAction<R, GroupTypes.ISelectedGroupState, undefined, AnyAction>;

// // Async Redux-Thunk action
// export function orderUpGroupActionA(selectedIndex: number): ThunkResult<Promise<GroupTypes.IGroupsState>> {
//   return async (dispatch, getState) => {
//     dispatch({
//       type: GroupTypes.ORDER_UP_GROUP,
//       payload: selectedIndex
//     });
//     return Promise.resolve(getState())
//   };
// }
 
 