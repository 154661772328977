import React from 'react';
import './CommandPallete.css'

const CommandPallete = ({children}) => {
    return (
      <div className="commandPallete">
        { children }
      </div>
    )
  }

  export default CommandPallete;