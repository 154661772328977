import * as Types from '../../Interfaces';

export const FILES_SUCCESS = 'FILES_SUCCESS';
export const SELECT_PDF_FILE = 'SELECT_PDF_FILE';
export const DELETE_SELECTED_PDFS = 'DELETE_SELECTED_PDFS';

export interface IGetFilesAction {
    type: typeof FILES_SUCCESS,
    payload: Types.File[];
}

export interface ISelectPDFFileAction {
    type: typeof SELECT_PDF_FILE,
    payload: Types.File
}

export interface IDeleteSelectedPDFsAction {
    type: typeof DELETE_SELECTED_PDFS 
}

export type FilesActionsTypes =
    | IGetFilesAction
    | ISelectPDFFileAction
    | IDeleteSelectedPDFsAction

export type IFilesState = Types.File[];