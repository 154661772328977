import React, { Fragment } from 'react';

const InviteSetter = ({onSubmitForm}) => {
    const onSubmit = (e) => {
        onSubmitForm(e);
    }
    return (
        <Fragment>
              <h2>Создание инвайта</h2>
              <form onSubmit={ onSubmit }>
                <div className="rowContainer">
                    <input type="text" className="textField" name="inviteOwner"  />
                        <button type="submit" className="float" value="submit-true"><div className="insider">Создать инвайт</div></button>
                </div>
              </form>
            </Fragment>
          
    )
};

export default InviteSetter;