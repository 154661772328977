import React, { Component, Fragment } from "react";
import { History } from "history";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { userLogoutAction } from "../store/auth/actions";
import { removeToken } from "../api";
import Wrapper from "./Wrapper/Wrapper";
import * as Types from "../Interfaces";

interface IProps {
	onUserLogout: () => void;
	history: History;
	user: Types.User;
	onExit(): void;
}

class AccountDetails extends Component<IProps> {
	onExit = () => {
		removeToken();
		this.props.onUserLogout();
		this.props.history.push("/");
	};

	render() {
		return (
			<Fragment>
				{this.props.user && (
					<Wrapper stepBack={true} onExit={this.onExit}>
						<p>В разработке</p>

						{this.props.user.super && (
							<Link className="blueLink" to="/accountDetails/restricted">
								<span>Раздел администратора</span>
							</Link>
						)}
					</Wrapper>
				)}
			</Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.auth.user
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onUserLogout: () => dispatch(userLogoutAction())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails);
