import React from "react";
import { Fade } from "react-reveal";
import File from "../Elements/File/File";
import DrivePath from "../Elements/DrivePath/DrivePath";
import Folder from "../Elements/Folder/Folder";
import "./DriveOperate.css";
import DelIcon from "@material-ui/icons/HighlightOffOutlined";
import PalleteButton from "../Elements/PalleteButton/PalleteButton";
import * as Types from "../../Interfaces";

interface IDriveOperateProps {
	files: Types.File[];
	images: Types.Image[];
	folderType: Types.FolderType;
	folders: Array<Types.File>;
	selectedFolder: Types.File;
	onSelect: (file: Types.File) => void;
	onSelectFolder: (folder: Types.File) =>void;
	onDelete: (folderType: any) => void;
	folderTypeChange(e: React.ChangeEvent<HTMLSelectElement>): void;
}

class DriveOperate extends React.Component<IDriveOperateProps> {

	render() {
		const {
			files,
			images,
			folders,
			folderType,
			onSelect,
			onSelectFolder,
			onDelete
		} = this.props;
		return (
			<Fade>
				<div className="main-container">
				<div className="rowContainer">В настоящий момент доступно только удаление загруженных ранее файлов.</div>
					<div className="main-header">
						<PalleteButton
							onClick={onDelete}
							className="palleteButton"
							dataHint={null}
						>
							<DelIcon />
						</PalleteButton>
					</div>


					<div className="rowContainer">
					<DrivePath folderType={this.props.folderType} onChangeFolderType={this.props.folderTypeChange} selectedFolder={this.props.selectedFolder} />
						<div className="drive">
							<div className="folderContainer">
								{folders &&
									folders.map((folder, i) => {
										return <Folder key={i} 
											folderName={folder.fileName} 
											selected={folder.selected}
											onClick={() => onSelectFolder(folder)}
											/>;
									})}
								<br />
							</div>
							{folderType === Types.FolderType.pdf && files && (
								<div className="fileContainer">
									{files.map((file, i) => {
										return (
											<File
												key={i}
												fileType={folderType}
												fileName={file.fileName}
												selected={file.selected}
												onClick={() => onSelect(file)}
											/>
										);
									})}{" "}
								</div>
							)}

							{folderType === Types.FolderType.image && images && (
								<div className="fileContainer">
									{images.map((image, i) => {
										return (
											<File
												key={i}
												fileType={folderType}
												fileName={image.fileName}
												selected={image.selected}
												onClick={()=>onSelect(image)}
											/>
										);
									})}{" "}
								</div>
							)}
						</div>
					</div>
				</div>
			</Fade>
		);
	}
}

export default DriveOperate;
