import { Auth } from '../../Interfaces';

export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const USER_SUCCESS = 'USER_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const CHECK_TOKEN = 'CHECK_TOKEN';
export const DROP_USER = 'DROP_USER';

export interface IRequestLoginAction {
    type: typeof REQUEST_LOGIN,
    payload: Auth
}

export interface IUserSuccessActionA {
    type: typeof USER_SUCCESS,
    payload: Auth
}

export interface ILoginErrorAction {
    type: typeof LOGIN_ERROR,
    payload: Auth
}

export interface ILogoutSuccessAction {
    type: typeof LOGOUT_SUCCESS,
    payload: Auth
}

export interface ICheckTokenAction {
    type: typeof CHECK_TOKEN,
    payload: Auth
}

export interface IDropUserAction {
    type: typeof DROP_USER,
    payload: Auth
}

export type AuthActionsTypes =
    | IRequestLoginAction
    | IUserSuccessActionA
    | ILoginErrorAction
    | ILogoutSuccessAction
    | ICheckTokenAction
    | IDropUserAction

export type IAuthState  = Auth;
