import { ActionCreator, AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import * as AuthTypes from './types';
import * as Types from '../../Interfaces';

type ThunkResult<R> = ThunkAction<R, AuthTypes.IAuthState, undefined, AnyAction>;

export function userSuccessActionA(user: Types.User): ThunkResult<Promise<AuthTypes.IAuthState>> {
  return async (dispatch, getState) => {
    dispatch({
      type: AuthTypes.USER_SUCCESS,
      payload: { isFetching: false, isAuthenticated: true, user: user }
    });
    return Promise.resolve(getState())
  };
}

export const userLogoutAction: ActionCreator<AuthTypes.ILogoutSuccessAction> = () => {
  return {
    type: AuthTypes.LOGOUT_SUCCESS,
    payload: {
      isFetching: false,
      isAuthenticated: false, user: null
    }
  }
}

export const requestLoginAction: ActionCreator<AuthTypes.IRequestLoginAction> = () => {
  return {
    type: AuthTypes.REQUEST_LOGIN,
    payload: {
      isFetching: false,
      isAuthenticated: false, user: null
    }
  }
}

export const loginError: ActionCreator<AuthTypes.ILoginErrorAction> = (errorMessage: string) => {
  return {
    type: AuthTypes.LOGIN_ERROR,
    payload: {
      isFetching: false, isAuthenticated: false, user: null, errorMessage: errorMessage
    }
  }
}

export const dropUserAction: ActionCreator<AuthTypes.IDropUserAction> = () => {
  return {
    type: AuthTypes.DROP_USER,
    payload: {
      isFetching: false, isAuthenticated: false, user: null
    }
  }
}

// export const checkTokenAction: ActionCreator<AuthTypes.ICheckTokenAction> = (token: string) => {
//   return {
//     type: AuthTypes.CHECK_TOKEN,
//     payload: {
//       token: token
//     }
//   }
// }
