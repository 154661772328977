import * as GroupTypes from './types';
import {Group} from '../../Interfaces';

const initialState : GroupTypes.ISelectedGroupState = {
  // index: 0, folder: ''
}

export function selectedGroupReducer(state = initialState, action : GroupTypes.SelectedGroupActionsTypes) : GroupTypes.ISelectedGroupState {
  switch(action.type) {
      // case 'LOGOUT_SUCCESS':   return initialState;
    case GroupTypes.SELECT_GROUP:
      if (action.payload === null) 
        return {index: 0, folder: ''}
      else 
        return {
          ...state,
          ...action.payload
        }
      
    case GroupTypes.SELECT_GROUP_BY_INDEX:
      const selected = action
        .payload
        .groups
        .filter(item => {
          return item.index === action.payload.index;
        })[0];
      return selected;

    case GroupTypes.CHANGE_SELECTED_GROUP_PROP:
      // сначала сливаем группу и редактируемое свойство в единый объект
      let changed:
      Group = {
        ...action.payload.selectedGroup,
        ...action.payload.prop
      }

      // затем возвращаем новый стейт, содержащий данную группу
      return changed;

    case GroupTypes.CHANGE_SELECTED_GROUP_LINK_PROP:
      // сначала сливаем группу и редактируемое свойство в единый объект
      let changedGroupWithNewLinkProp:
      Group = {
        ...action.payload.selectedGroup,
        link: action.payload.link
      };
      // затем возвращаем новый стейт, содержащий данную группу
      return changedGroupWithNewLinkProp;

    default:
      return state;

  }
}