// import Action from 'redux';

import * as Types from '../../Interfaces';
 
export const ACCOUNTS_LIST_SUCCESS = 'ACCOUNTS_LIST_SUCCESS';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
 

export interface IGetAccountsAction {
    type: typeof ACCOUNTS_LIST_SUCCESS,
    payload: Array<Types.Account>
}

export interface IDeleteAccountAction {
    type: typeof DELETE_ACCOUNT,
    payload: string
}
 
export type AccountsActionsTypes =
    | IGetAccountsAction
    | IDeleteAccountAction

    export type IAccountsState = Array<Types.Account>
