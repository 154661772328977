import { ActionCreator, AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import * as AccountsTypes from './types';
import * as Types from '../../Interfaces';

export const getAccounts: ActionCreator<AccountsTypes.IGetAccountsAction> = (accounts: Types.Account[]) => {
  return {
    type: AccountsTypes.ACCOUNTS_LIST_SUCCESS,
    payload: accounts
  }
}

type ThunkResult<R> = ThunkAction<R, AccountsTypes.IAccountsState, undefined, AnyAction>;

export function deleteAccountActionA(_id: string): ThunkResult<Promise<AccountsTypes.IAccountsState>> {
  return async (dispatch, getState) => {
    dispatch({
      type: AccountsTypes.DELETE_ACCOUNT,
      payload: _id
    });
    return Promise.resolve(getState())
  };
}
 
 