import React, { useMemo } from 'react';

import './UploadStatus.css';
import { Fade } from 'react-reveal';
import { getShortFileName } from '../../../api';

interface IProps {
  file: any,
  statusText: string,
  statusCode: string,
  resetUploadStatus?: () => void;
 }

const UploadStatus: React.FC<IProps> = ({file, statusText, statusCode, resetUploadStatus}): any => {

  const theFile = useMemo(()=>(
    file
  ), [file])

  return ( 
    theFile ?
    <Fade top >
  <div className={`uploadStatus ${statusCode}`} onClick={resetUploadStatus}>
  <div className="fileInfo" >
    <div className="fileDetail">
      <span>Имя файла: {theFile.name.length >30 ? getShortFileName(theFile.name.replace(/ /g,"_")) : theFile.name.replace(/ /g,"_")}</span>
    </div>

    <div className="fileDetail">
      <span>Размер: {(theFile.size / 1000000).toFixed(2) + 'Mb'}</span>
    </div>

    <div className="fileDetail">
      <span>Изменен: {(theFile.lastModifiedDate).toLocaleString("ru")}</span>
    </div>

    <div className="fileDetail">
      <span>Статус: {statusText}</span>
    </div>

{statusCode !=='fail' &&
    statusCode === 'success'?
    (<div className="fileDetail">
      <br />
      <span>Не забудьте сохранить изменения!</span>
    </div>)
    : <div className="fileDetail">
    <br />
    <span>Загрузка ...</span>
  </div>

}
  </div>
  </div>
  </Fade>
  : ''
  );
}

export default UploadStatus;